import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Error, Success, Warning, Info, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token} from '../component/include'
import {Type} from 'react-bootstrap-table2-editor';
import { BasicTable } from '../component/dataTable'
import Select from 'react-select'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
const UsertypePermission =()=> {
    const [errors, setErrors] = useState({});
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isEditVisible, setIsEditVisible] = useState(false);
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [usertype, setUsertype] = useState({
        usertype:'',
        status:true
    })

    const [editUsertype, setEditUsertype] = useState([])
    const [allUsertype, setAllUsertype]= useState([])

    const handleChange = event =>{
		//const {name, value} = event.target
		 const target = event.target;
		const name = target.name;
		const value = target.value; 
        setUsertype({...usertype, [name] : value });
       setErrors({...errors, [name]:''});
    }

   
    const handleChangeE = (e)=>{
        const act =JSON.parse(JSON.stringify(editUsertype))   
            const value =e.target.value;
		   act[0][e.target.name] = value;
           setEditUsertype(act);
    }


    const handleCheckbox =()=>{
        const value = usertype.status ==='true'? 'false':'true';
         setUsertype({...usertype, status : value});
        }

        const handleCheckboxE =(e)=>{
            const act =JSON.parse(JSON.stringify(editUsertype))  
            const value = act[0].status ==='true'? 'false':'true';
		   act[0][e.target.name] =value;
           setEditUsertype(act);
            }

        const fetchById =(ID)=>{

            let url = ServerUrl +'/fetchByColumn_controller/tbl_usertype/ID/'+ID+'/'+Token;
            setNotice({...notice, 
                isLoading: true
            });
            axios.get(url)
            .then(result => {   
                
                setEditUsertype(result.data)  
                setNotice({...notice, 
                    isLoading: false
                });
               setIsEditVisible(true);
               
            })
            .catch((error)=>{
                setNotice({...notice, 
                    isLoading: false,
                    isError : true,
                    msgResponse: error.message
                });  
            })
        }
    const handleDelete = (ID, table)=>{  
        setNotice({...notice, 
           isLoading: true,
           isSuccess : false}); 
           let close =   document.getElementById('btnWarningDialog-'+ID);
            
              close.click();
           const fd = new FormData();
           
           fd.append("ID", ID)
    
           const config = {
               headers: { 'content-type': 'multipart/form-data'}
           }
            let url = ServerUrl+'/function/delete_controller/'+table+'/'+Token;
           axios.post(url, fd, config)
           .then(response =>{
            setNotice({...notice, 
                isLoading: false,
                isSuccess : true,
                msgResponse: response.data.message
            })
           })
           .catch((error)=>{
               setNotice({...notice, 
                   isLoading: false,
                   isError : true,
                   msgResponse: error.message
               })
           })      
    }
    const handleAccountAction =  useCallback((cell, row)=>{
        
        return <React.Fragment>    
        <a href="#!" data-toggle="modal"   data-target={`#warningdialog-${cell}`}     title="Delete"><i className="icofont icofont-delete-alt"></i></a>&nbsp;/&nbsp;

        <a href="#!" onClick={()=>fetchById(cell)}    title="Edit"><i className="icofont icofont-edit-alt"></i></a>  


        <WarningModal title="This is very dangerous, you shouldn't do it! are you really really sure.?" handleDelete={()=>handleDelete(cell, 'tbl_usertype')} mID={cell} /> 
        </React.Fragment> 
    },[])

    const handleStatus =(cell, row)=>{
const badge = row.status==='true'? <span className="pcoded-badge label label-success">ACTIVE</span>: <span className="pcoded-badge label label-danger">INACTIVE</span>
return badge
    }

    const tableHeader = [
        {dataField: 'ID', text: 'Action', formatter: handleAccountAction, editable: false},
        {dataField: 'usertype', text: 'Usertype', validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Usertype is required'
              };
            }
            return true;
          } },
          {dataField: 'status', text: 'Status', formatter: handleStatus, editable:false}, 
     ];


         const fetchAllUsertype =()=>{
            setNotice({...notice, 
                isDataFetching: true
            });
            let Url = ServerUrl+'/fetch_controller/tbl_usertype/'+Token;
            axios.get(Url)
            .then(result => {    
                setAllUsertype(result.data) 
                setNotice({...notice, 
                    isDataFetching: false
                });
            })
            .catch((error)=>{
                setNotice({...notice, 
                    isLoading: false,
                    isError : true,
                    isDataFetching: false,
                    msgResponse: error.message
                });  
            }) 
        }

  
     const handleSubmit = event =>{
        event.preventDefault();
         
    setErrors(ValidateInput(usertype));
        setIsSubmitting(true);
    } 

    const handleUpdate = event =>{
        event.preventDefault();
    setErrors(ValidateInputE(editUsertype[0]));
        setIsUpdating(true);
    } 
    
const ValidateInput =(values)=>{
	let errors = {};   
    
    if(!values.usertype){
        errors.usertype ='Please enter user type';
    }
    return errors;
	}
	const ValidateInputE =(values)=>{
        let errors = {};   
        if(!values.usertype){
            errors.usertype ='Please enter user type';
        }
        return errors;
        }
function submit(){  
      
       const fd = new FormData(); 
       let formData = usertype; 
      for(let k in formData){
          fd.append(k,formData[k]);
      }; 
    setNotice({...notice, 
	  isLoading: true,
	  isSuccess : false}) 

	  const config = {
		  headers: { 'content-type': 'multipart/form-data'}
	  }
       let url = ServerUrl+'/function/save_controller/tbl_usertype/'+Token;
	  axios.post(url, fd, config)
	  .then(response =>{

        if (response.data.type ==='info'){
            setNotice({...notice, 
                isLoading: false,
                isInfo : true,
                msgResponse: response.data.message
            })
        }else if (response.data.type ==='success'){
        setNotice({...notice, 
            isLoading: false,
            isSuccess : true,
            msgResponse: response.data.message
        })
        } else{
        setNotice({...notice, 
            isLoading: false,
            isError : true,
            msgResponse: response.data.message
        })
    }      
			  
	  })
	  .catch((error)=>{
		  setNotice({...notice, 
			  isLoading: false,
			  isError : true,
			  msgResponse: error.message
		  })  
      })   
      setIsSubmitting(false); 
      setUsertype({ 
      usertype:'',
      status:true}); 
   
}

function submitUpdate(){  
      
    const fd = new FormData(); 
    let formData = editUsertype[0]; 

    for(let k in formData){
       fd.append(k,formData[k]);
   }; 
 setNotice({...notice, 
   isLoading: true,
   isSuccess : false}) 

   const config = {
       headers: { 'content-type': 'multipart/form-data'}
   }
    let url = ServerUrl+'/function/update_controller/tbl_usertype/'+Token;
   axios.post(url, fd, config)
   .then(response =>{

     if (response.data.type ==='info'){
         setNotice({...notice, 
             isLoading: false,
             isInfo : true,
             msgResponse: response.data.message
         })
     }else if (response.data.type ==='success'){
     setNotice({...notice, 
         isLoading: false,
         isSuccess : true,
         msgResponse: response.data.message
     })
     } else{
     setNotice({...notice, 
         isLoading: false,
         isError : true,
         msgResponse: response.data.message
     })
 }      
           
   })
   .catch((error)=>{
       setNotice({...notice, 
           isLoading: false,
           isError : true,
           msgResponse: error.message
       })  
   })   
   setIsUpdating(false); 
   setIsEditVisible(false);  

}

useEffect(()=>{
    setIsAuthenticated(verifyRouter(location.pathname))
    fetchAllUsertype();
},[]);

useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])
    
    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isUpdating){
        submitUpdate()
        }
        },[errors])
        
        
        return (    <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Users">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-home"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Users</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Usertype & Permission</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
							
                {isEditVisible? <div className="col-sm-5">
                    {editUsertype&&editUsertype.map((acct, idx)=>
	<div key={idx} className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Update Usertype</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
       
        <div  className="card-block">
		<fieldset><div className="row">
							<section className="col-md-12">
                           
										<div className="form-group">
						<label >Usertype<span style={{color:'red'}}>*</span></label>
				<input type="text" name="usertype" value={acct.usertype} onChange={handleChangeE} className={errors.usertype  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.usertype && errors.usertype}</span>	
			</div>
										</section>
										</div>
                                        <div className="row">
										<section className="col-md-12">
	<hr/><label>Status</label>
                                  
<div className="border-checkbox-section">
					
					<div className="border-checkbox-group border-checkbox-group-primary">
			<input className="border-checkbox" type="checkbox" onChange={handleCheckboxE} name="status" value={acct.status} checked={acct.status==='true'? true:false} id="status1" />
						<label className="border-checkbox-label" htmlFor="status1">Active</label>
					</div>
					
					</div> 

                    </section>
							</div>	</fieldset>

	<footer className="pull-right">
	
	<button type="button" onClick={()=>setIsEditVisible(false)} className="btn btn-inverse "><i className="fa fa-refresh"></i> Back</button>&nbsp;&nbsp;
	<button type="button" id="submit" onClick={handleUpdate} className="btn btn-primary ">Update</button>
	
								</footer>	

							</div>
							
                            </div>
                            )}
							</div>:
                               
                               <div className="col-sm-5">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Add Usertype</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<fieldset><div className="row">
									
										<section className="col-md-12">
                                       
										<div className="form-group">
						<label >User Type<span style={{color:'red'}}>*</span></label>
				<input type="text" name="usertype" value={usertype.usertype} onChange={handleChange} className={errors.usertype  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.usertype && errors.usertype}</span>	
			</div>
										</section>
										</div>
                                        <div className="row">
										<section className="col-md-12">
	<hr/><label>Status</label>
                                        
     
<div className="border-checkbox-section">
					
			<div className="border-checkbox-group border-checkbox-group-primary">
				<input className="border-checkbox" type="checkbox" onChange={handleCheckbox} name="status" value={usertype.status==='true'? true:false} checked={usertype.status==='true'? true:false} id="status" />
						<label className="border-checkbox-label" htmlFor="status">Active</label>
					</div>
					
					</div> 

                    </section>
							</div>	</fieldset>

	<footer className="pull-right">
	
	<button type="button" className="btn btn-inverse "><i className="fa fa-refresh"></i> Back</button>&nbsp;&nbsp;
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-primary ">Save</button>
	
								</footer>				
							</div>
							</div>
							
                            

	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Listing All Accounts</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="col-md-12">
		  <div className="card z-depth-0">
            <div className="card-block">
        <div className="table-responsive">
        {notice.isDataFetching ? <TableLoader />:
   <BasicTable data={allUsertype} fetchdata={fetchAllUsertype} table={'tbl_usertype'} columns={tableHeader}  />} 
</div>
	</div>
    </div></div></div>


                            </div>}
		
	

	<div className="col-sm-7">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> User Type Permission</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12">
        <div className="card z-depth-0">
            <div className="card-block">
        <div className="table-responsive">
        <p>User permissions here</p> 
</div>
	</div>
    </div></div></div></div>
</div>

</div>
	





   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(UsertypePermission) 