import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import {  Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import { ServerUrl, Token, config } from '../component/include'
 const UploadStaff=()=> {
    
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });

const [CurrentSession, setCurrentSession] = useState('')
const [csvFile, setCsvFile]=useState({
    staffCsv:'',
    fileName:'Please select .csv files (allowed file size 1 Mb)',
    sessionID:CurrentSession, 
})
let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
const [errors, setErrors] = useState({});

		const  handleSession =()=>{
		
			var sql ="Select  startYear from tbl_school_session where status ='Active'"
			const fd = new FormData();
			fd.append("sql", sql);
			fd.append("jwt", Token);
			let url = ServerUrl+'/fetchBySql_controller';
			axios.post(url, fd, config)
			.then(result => {
				if(result.data.length!==0){
				setCurrentSession(result.data[0].startYear)
			}
			})
			.catch((error)=>{
				Alerts('Error!', 'danger', JSON.stringify(error.message)) 
			})
			} 
const  handleSubmit=(event)=>{ 
    event.preventDefault(); 
    if(!csvFile.staffCsv){
        errors.staffCsv ='You have not choose any file to upload';
        Alerts('Error!', 'danger', errors.staffCsv)
    }else{

    
    let formData = csvFile;
    
    const fd = new FormData();
    fd.append('jwt', Token); 
     for(let k in formData){
        fd.append(k,formData[k]);
    };  
     setNotice({...notice,   isLoading: true}) 
       let url = ServerUrl+'/save_controller/importStaff'
      axios.post(url, fd, config).then(response =>{
        if (response.data.type ==='success'){
			Alerts('Saved!', 'success', response.data.message)
		} else if (response.data.type ==='info'){
            Alerts('Saved!', 'default', response.data.message)
            
        }else{
			Alerts('Error!', 'danger', JSON.stringify(response.data))
	}  
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', JSON.stringify(error.message)) 
	})
	.finally(()=>{
	  setNotice({...notice, isLoading: false}) 
     setCsvFile({...csvFile, staffCsv:'', fileName:'Please select .csv files (allowed file size 1 Mb)'}); 
     document.getElementById('staffCsv').value=''
}) 
}
 }

    const  handleFile = function(fieldName){
        return function(newValue){  
           
            setErrors({...errors, [fieldName]:''})
       readURL(newValue);
       }
       
    } 
   

    const readURL = (input)=>{
       
        let displayMessage = '';
        const PreviewImage = input.target.name;
            var fileName = document.getElementById(PreviewImage).value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        

        if (extFile==="csv" || extFile==="CSV"){
            //TO DO    
            
             var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
        if (FileSize > 1) {
            displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 1 Mb';
           document.getElementById(input.target.name).value = '';
        } else {
            if (input.target.files && input.target.files[0]) { //Check if input has files.
                   const value = input.target.files[0]
            setCsvFile({...csvFile, staffCsv:value, fileName:value.name})
           
            }
        }
        }else{

            displayMessage = 'Only csv|CSV  files are allowed with maximum of 1 Mb'
            document.getElementById(input.target.name).value = '';
            setCsvFile({...csvFile, staffCsv:'', fileName:'Please select .csv files (allowed file size 1 Mb)'})
        }  
        
        if(displayMessage.length > 0){
            Alerts('Error!', 'danger', displayMessage)
          }
        
    } 


    useEffect(()=>{
        //setIsAuthenticated(verifyRouter(location.pathname))
        handleSession()
    },[]);

        return (   <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 

                   {/* <!-- Page-header start --> */}

        <PageHeader title="Import Staff ">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-users"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="/staff/view_staff">Staff</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Upload Staff</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
                <div className="row  ">
	<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>Prepare your Staff file</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
                    <div className="well">
                       
                        <div className="box-body">
                        <div className="row">
                            <div className="col-sm-3 text-right  text-danger"><u><b>Required Columns</b></u></div>
                            <div className="col-sm-7"><u><b>Allowed Values</b></u></div>                           
                        <div className="col-sm-3 text-right text-bold"><b>Staff Name</b></div>
                    <div className="col-sm-7">The Name of Staff<br/>                      <br/>    </div>          								
                            <div className="col-sm-3 text-right text-bold"><b>Gender</b></div>
                        <div className="col-sm-7">Staff Sex       <br/>  </div>    
                        <div className="col-sm-3 text-right text-bold"><b>Mobile</b></div>
                        <div className="col-sm-7">Staff Mobile GSM Number     <br/>  </div>                        
                            <div className="col-sm-3 text-right text-bold"><b>Email</b></div>
                            <div className="col-sm-7">Staff Email Address<br/>                    <br/></div>                           
                    <div className="col-sm-3 text-right text-bold"><b>Date of Joining</b></div>
                    <div className="col-sm-7">The date of employment<br/>                          
                                </div>                       
                            <div className="col-sm-3 text-right text-bold"><b>Status</b></div>
            <div className="col-sm-7">Staff Employment Status<br/>                          
                                </div>
								 <div className="col-sm-3 text-right text-bold"><b>Date of Birth</b></div>
                            <div className="col-sm-7">Staff Birth Date<br/>                      <br/>      
                                </div> 
								
								<div className="col-sm-3 text-right text-bold"><b>Staff Address</b></div>
                    <div className="col-sm-7">Staff Present Address<br/>                          
                                </div>  
                            <div className="col-sm-3 text-right text-bold text-warning"><u><b>Optional Columns</b></u></div>
                            <div className="col-sm-7 "><u><b>Allowed Values</b></u></div>                           
                            <div className="col-sm-3 text-right text-bold"><b>Religion</b></div>
                     <div className="col-sm-7">Staff Religion<br/>                      <br/>      
                                </div>
                                <div className="col-sm-3 text-right text-bold"><b>NIN</b></div>
                     <div className="col-sm-7">National Identity Number<br/>                      <br/>      
                                </div>
                                <div className="col-sm-3 text-right text-bold"><b>File Number</b></div>
                     <div className="col-sm-7">Staff Unique File number<br/>                      <br/>      
                                </div>
                        </div>
                    </div>
                </div>
	</div>
    </div>

</div>
</div>


                <div className="row  ">
								<div className="col-sm-6">
	<div className="card z-depth-0">
		<div className="card-block">
		<fieldset>
			<div className="row">
									
			<section className="col-md-12 ">
			<div className="well well-sm alert alert-success border-success background-success ">
					<h4 className="alert-heading ">Download Sample File</h4>
					
<p><span >The first line in downloaded csv file should remain as it is. Please do not change the order of columns.</span><br/>
<span >The correct column order is (<b>
    Staff Name, Gender, Mobile, Email, Date Join, Status, Date of Birth, Current Address, NIN, File No, Religion </b>)</span> <span >&amp; you must follow this.</span></p>
					<hr className="simple" /><p className="text-align-left">
						

                    <form method='POST' action={ServerUrl+'/csvSample_controller/staff'} target='_blank'>
                <input type='hidden' name='jwt' value ={Token} />
                
        <button type="submit" className="btn btn-sm btn-inverse"> <i className="fa fa-download"></i> Download Sample</button>
        
        </form>
					</p>
				</div>					
				</section>
										</div>
										
				</fieldset>
				
							</div>
							</div>
							</div>
		
	
	

	<div className="col-sm-6">
	<div className="card z-depth-0">
	
		<div className="card-block">


		<div className="alert alert-success border-success">
						<fieldset className="well well-sm">
                        <h4 className="alert-heading ">Upload file with .csv extension</h4>
							<section>
                            <div className="form-group">
							<label  className={errors.staffCsv  ? 'btn btn-sm btn-danger' : 'btn btn-sm btn-inverse'}  htmlFor="staffCsv" > <i className="fa fa-upload"></i>Choose File </label>
                    <input id="staffCsv"  onChange={handleFile('staffCsv')} className="form-control file" name="staffCsv" type="file" accept=".csv" /> 
						<div className="note" style={{color:'red'}}>
			{csvFile.fileName}
		</div>	
										</div>          
                                        
							</section>
						</fieldset><span style={{color:'red'}}>{errors.staffCsv && errors.staffCsv}</span></div><br/><br/>
						<footer>
							<button type="button" id="submit" onClick={handleSubmit} className="btn  btn-primary pull-right"><i className="fa fa-save"></i> Upload Staff</button>
						</footer>

	</div>

</div>

</div></div>
	

                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(UploadStaff) 