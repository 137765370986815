import React, {useState, useEffect} from 'react'
import {LoadEffect}  from '../component/loader'
import PageHeader from '../component/pageheader'

import {ServerUrl, Token, config} from '../component/include'
import axios from 'axios'
import {  convertDate, formatGroupLabel, getMonth, getMonthByDate} from '../component/globalFunction'
import {Bar, Line} from 'react-chartjs-2'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import Select from 'react-select'
import { useSelector } from 'react-redux'

const Chart =()=> {
    
  let location =  useLocation()
  const [isAuthenticated, setIsAuthenticated] = useState(true);
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})



    const [studentAttendance, setStudentAttendance] = useState([]);
    const [classAttendance, setClassAttendance] = useState([]);
    const [staffAttendance, setStaffAttendance] = useState([]);
    const [staffMonth, setStaffMonth] = useState([]);
    var date = new Date()
    var first = new Date(date.getFullYear(), date.getMonth(), 1, 23, 59, 59)
    var last = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59)

    var firstDayOfYear = new Date(date.getFullYear(), 0, 1, 23, 59, 59)
    var lastDayOfYear = new Date(date.getFullYear()+1, 0, 0, 23, 59, 59)

const schoolClass  = useSelector(state => state.rootReducer.schoolClass);

const [search, setSearch]=useState({
  studentClass:[],
  
});

let thisMonth = getMonth(new Date().getMonth());
    
     

const fetchStaffMonthlyAttendance=()=>{
       
  var sql = "select attendanceDate as label, sum(case when attendance = 'P'   then 1 else 0 end) AS 'present', sum(case when attendance = 'A'   then 1 else 0 end) AS 'absent', sum(case when attendance = 'H'   then 1 else 0 end) AS 'half' from tbl_staff_attendance where attendanceDate  >=  '"+convertDate(firstDayOfYear)+"' and attendanceDate  <= '"+convertDate(lastDayOfYear)+"' group by  DATE_FORMAT(attendanceDate, '%m.%Y')   order by label  "
  
           const fd = new FormData();
           fd.append("sql", sql);
           fd.append('jwt', Token)
           let url = ServerUrl+'/fetchBySql_controller';
           axios.post(url, fd, config).then(result=>{ 

            let responseData = []
            for (var i = 0; i< result.data.length;  i++) {
              responseData.push(
                  {  label:String(getMonthByDate(result.data[i].label)),
                      present:result.data[i].present,
                      absent:result.data[i].absent,
                      half:result.data[i].half
                  });
                  } 

           setStaffMonth(responseData)
           })
       } 



         const fetchStudentAttendance=()=>{
       
          var sql = "select attendanceDate as label, sum(case when indicator = 'P'   then 1 else 0 end) AS 'present', sum(case when indicator= 'A'   then 1 else 0 end) AS 'absent' from tbl_student_attendance where attendanceType ='fullday' and  attendanceDate >=  '"+convertDate(first)+"' and attendanceDate  <= '"+convertDate(last)+"' group by attendanceDate  order by label  "
          
                   const fd = new FormData();
                   fd.append("sql", sql);
                   fd.append('jwt', Token)
                   let url = ServerUrl+'/fetchBySql_controller';
                   axios.post(url, fd, config).then(result=>{ 
                   setStudentAttendance(result.data)
                   })
               } 



         const fetchStaffAttendance =()=>{
       
          var sql = "select attendanceDate as label, sum(case when attendance = 'P'   then 1 else 0 end) AS 'present', sum(case when attendance = 'A'   then 1 else 0 end) AS 'absent', sum(case when attendance = 'H'   then 1 else 0 end) AS 'half' from tbl_staff_attendance where attendanceDate >=  '"+convertDate(first)+"' and attendanceDate  <= '"+convertDate(last)+"' group by attendanceDate  order by label  "
          
                   const fd = new FormData();
                   fd.append("sql", sql);
                   fd.append('jwt', Token)
                   let url = ServerUrl+'/fetchBySql_controller';
                   axios.post(url, fd, config).then(result=>{ 
                   setStaffAttendance(result.data)
                   })
               } 
    
               const dataStudentAttendance = {
                labels: studentAttendance.map((data)=>data.label),
                datasets: [
                 
                  {
                    label: 'Total Present',
                    data: studentAttendance.map((data)=>data.present),
                    fill: false,
                    backgroundColor: 'rgb(12, 206, 138)',
                    borderColor: 'rgb( 11, 218, 147)',
                    yAxisID: 'y-axis-2',
                  },
                  {
                    label: 'Total Absent',
                    data: studentAttendance.map((data)=>data.absent),
                    fill: false,
                    backgroundColor: 'rgb(255, 99, 132)',
                    borderColor: 'rgb( 173, 24, 39)',
                    yAxisID: 'y-axis-2',
                  }
                ],
              }; 
              const optionsStudentAttendance = {
                scales: {
                  yAxes: [
                    {
                      type: 'linear',
                      display: true,
                      position: 'left',
                      id: 'y-axis-1',
                    },
                    {
                      type: 'linear',
                      display: true,
                      position: 'right',
                      id: 'y-axis-2',
                      gridLines: {
                        drawOnArea: false,
                      },
                    },
                  ],
                },
              } 
              


         const dataStaffAttendance = {
          labels: staffAttendance.map((data)=>data.label),
          datasets: [
           
            {
              label: 'Total Present',
              data: staffAttendance.map((data)=>data.present),
              fill: false,
              backgroundColor: 'rgb(12, 206, 138)',
              borderColor: 'rgb( 11, 218, 147)',
              yAxisID: 'y-axis-2',
            },
            {
              label: 'Total Absent',
              data: staffAttendance.map((data)=>data.absent),
              fill: false,
              backgroundColor: 'rgb(255, 99, 132)',
              borderColor: 'rgb( 173, 24, 39)',
              yAxisID: 'y-axis-2',
            },
            {
              label: 'Total Half Day',
              data: staffAttendance.map((data)=>data.half),
              fill: false,
              backgroundColor: 'rgb(1, 190, 193)',
              borderColor: 'rgb(0, 191, 195)',
              yAxisID: 'y-axis-2',
            }
          ],
        }; 
        
  
        const optionsStaffAttendance = {
          scales: {
            yAxes: [
              {
                type: 'linear',
                display: true,
                position: 'left',
                id: 'y-axis-1',
              },
              {
                type: 'linear',
                display: true,
                position: 'right',
                id: 'y-axis-2',
                gridLines: {
                  drawOnArea: false,
                },
              },
            ],
          },
        } 
        
        const data= {
          labels: classAttendance.map((data)=>data.label),
          datasets: [
            {
              label: 'Present ',
              data:  classAttendance.map(data=>data.present),
              backgroundColor: 'rgb(49, 168, 13)',
              
              
            },
            {
              label: 'Absent ',
              data:  classAttendance.map(data=>data.absent),
              backgroundColor: 'rgb( 173, 24, 39)',
            }
          ],
        }
        
        const options = {
          scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
        }; 

        const dataStark = {
          labels: staffMonth.map((data)=>data.label),
          datasets: [
            {
              label: 'Present',
              data: staffMonth.map((data)=>data.present),
              backgroundColor: 'rgb(49, 168, 13)',
            },
            
            {
              label: 'Absent',
              data: staffMonth.map((data)=>data.absent),
              backgroundColor: 'rgb( 173, 24, 39)',
            },
            {
              label: 'Half Day',
              data: staffMonth.map((data)=>data.half),
              backgroundColor: 'rgb(1, 190, 193)',
            }
          ],
        };
        
        
        const optionsStark = {
          scales: {
            yAxes: [
              {
                stacked: true,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
            xAxes: [
              {
                stacked: true,
              },
            ],
          },
        };
        


const handleSelect = (option, action)=>{
	setSearch({...search, [action.name]: option});
  fetchClassAttendance(option.value)
 }  

        const fetchClassAttendance =(section)=>{
          var date = new Date()
          const day = 1000 * 60 * 60 * 24
          var answer = []
          for(var i =0; i<7; i++){
              
              answer.push(date.toISOString().slice(0,10))
              date.setTime(date.getTime()-day)
          }
  
      
  var sql = "select attendanceDate as label, sum(case when attendanceType ='fullday' and indicator ='P'   then 1 else 0 end) AS 'present', sum(case when attendanceType ='fullday' and indicator ='A'   then 1 else 0 end) AS 'absent' from tbl_student_attendance where  sectionID = '"+section+"' and attendanceDate IN("+answer.map(data=>'"'+data+'"')+")  GROUP BY attendanceDate order by label  "
  
           const fd = new FormData();
           fd.append("sql", sql);
           fd.append('jwt', Token)
           let url = ServerUrl+'/fetchBySql_controller';
           axios.post(url, fd, config).then(result=>{ 
              
               setClassAttendance(result.data)
           })
       }
     

   useEffect(()=>{
    //setIsAuthenticated(verifyRouter(location.pathname))
     fetchStaffMonthlyAttendance()
     fetchStudentAttendance()
     fetchStaffAttendance()
},[]);

        return (  
   <>
  <div  className="main-body">
  {isAuthenticated?   <div className="page-wrapper">
                {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                   {/* <!-- Page-header start --> */}

        <PageHeader title='Chart'>
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-dashboard"></i> </a>
                            </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">



    <br/>


    <div className="row">
      <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
             <div className="card-body">
             <div className="col-sm-6 pull-left ">
             <strong>
     Class Attendnace </strong>
               </div>
             <div className="col-sm-4 pull-right ">
             <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID,isSection:'1', value: sec.ID, label: sec.sname}:{classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
                             })
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  name="studentClass" value={search.studentClass}  /> 
			</div>
							</div>
              <Bar
          data={data}
          options={options}
          
        /> 

             </div>
          </div>
        </div>

        <div className="row">
      <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
		   <div className="card-header"><strong>
     Student Attendance ({thisMonth}) </strong>
				</div>
             <div className="card-body">

              <Line
          data={dataStudentAttendance}
          options={optionsStudentAttendance}
          
        /> 

             </div>
          </div>
        </div></div> 


        <div className="row">
        <div className="col-12">
          <div className="card">
		   <div className="card-header">
            <strong> STAFF MONTHLY ATTENDANCE</strong>
				</div>
             <div className="card-body">

              <Bar
          data={dataStark}
          options={optionsStark}
        />  
             </div>
          </div>
        </div></div>


        <div className="row">
      <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
		   <div className="card-header"><strong>
     Staff Attendance ({thisMonth}) </strong>
				</div>
             <div className="card-body">

              <Line
          data={dataStaffAttendance}
          options={optionsStaffAttendance}
          
        /> 

             </div>
          </div>
        </div></div> 





   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Chart) 