import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')


   export const encrypt=data=>CryptoJS.AES.encrypt(data, 'greatschool').toString();
 
  export const decrypt=data=>{
  var byte =  CryptoJS.AES.decrypt(data, 'greatschool')
  return byte.toString(CryptoJS.enc.Utf8)
  } 



  export const verifyRouter =(route)=>{
    return true
    
      }
  
export const userToken =Cookies.get('greatkarfsh')?decrypt(Cookies.get('greatkarfsh')):[]