import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token,  config} from '../component/include'
import Select from 'react-select'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { userToken } from '../component/authentication'

const DefineHolidays =()=> {
    const [errors, setErrors] = useState({});
    const [isHolidays, setIsHoliday]=useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [CurrentSession, setCurrentSession] = useState('')
    const [holidays, setHolidays]= useState({
        eventType:'Events',
        startDate:'',
        endDate:'',
        startTime:'',
        endTime:'',
        colors:'',
        title:'',
        day:[],
        holydayType:'Specific',
        notification:'No',
        replaceAll:'No',
        sessionYear:CurrentSession

    })
    const [allHolidays, setAllHolidays]= useState([])
    const  handleSession =()=>{
    
        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config)
        .then(result => {
            if(result.data.length!==0){
            setCurrentSession(result.data[0].startYear)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        } 
    const fetchAllHolidays =()=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let sql = "Select * from tbl_events_holidays order by startDate DESC"
            let url = ServerUrl+'/fetchBySql_controller'
            fd.append("sql", sql)
            axios.post(url, fd, config).then(result =>setAllHolidays(result.data)) 
    }



    const handleChange = event =>{
		 const {name, value} = event.target
        setHolidays({...holidays, [name] : value });
       setErrors({...errors, [name]:''});
    }
    const handleSelect = (option, action)=>{
        setHolidays({...holidays, [action.name]: option});
        setErrors({...errors, [action.name]:''})
     }


    
    

    
    const handleSubmit = event =>{
        event.preventDefault();
    setErrors(ValidateInput(holidays));
        setIsSubmitting(true);
    } 
    const ValidateInput=(values)=>{
        let errors = {};  
                   
        if(!values.startDate){
            errors.startDate ='Please select start date';
        }
        if(!values.title){
            errors.title ='Please enter event title';
        }
        if(!values.endDate){
            errors.endDate ='Please select end Date';
        }
        
        if(Number(getDays())<0){
            errors.endDate = 'Invalid date range selected'
           
        }
        if(Number(getDays())===''){
            errors.endDate = 'One of the date typed is not valid'
        }
        return errors;
        }
    function submit(){  
      
        setNotice({...notice, 
            isLoading: true})         
            const fd = new FormData();
    fd.append('jwt', Token); 
            let formData = holidays; 
           for(let k in formData){
               fd.append(k,formData[k]);
           }; 
          
           let url = ServerUrl+'/save_controller/tbl_events_holidays'
          axios.post(url, fd, config)
          .then(response =>{
    
            if(response.data.type ==='success'){
                Alerts('Saved!', 'success', response.data.message)
                    }else if(response.data.type ==='info'){
                        Alerts('Update!', 'default', response.data.message)
                            }else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
                    }   
            })
            .catch((error)=>{
              Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                fetchAllHolidays()
          setIsSubmitting(false); 
          setNotice({...notice, 
            isLoading: false
        })
         setHolidays({
            eventType:'Events',
            startDate:'',
            endDate:'',
            startTime:'',
            endTime:'',
            colors:'#00ff00',
            title:'',
            day:[],
            holydayType:'Specific',
            notification:'No',
            sessionYear:CurrentSession
         })
        })
       
    }
    

    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmitting){
        submit()
        }
        },[errors])





        const handleHoliday = event =>{
            event.preventDefault();
        setErrors(ValidateErrors(holidays));
            setIsHoliday(true);
        }
    
        const ValidateErrors=(values)=>{
            let errors = {};  
                       
            if(!values.startDate){
                errors.startDate ='Please select start date';
            }
            if(!values.title){
                errors.title ='Please enter event title';
            }
            if(!values.endDate){
                errors.endDate ='Please select end Date';
            }
            
            if(Number(getDays()) < 0){
                errors.endDate = 'Invalid date range selected'
            }
    
            if(Number(getDays())===''){
                errors.endDate = 'One of the date typed is not valid'
            }
            if(holidays.holydayType==='Recurring'){
    
                if(holidays.day.length===0){
                    errors.day ='Please select day';
                }
            }
            return errors;
            }
    
        const getDays=()=>{
    
            var fromDate =  new Date(holidays.startDate);
            var toDate =  new Date(holidays.endDate);
            var diff_time = toDate.getTime()-fromDate.getTime()
            var differ = diff_time/(1000*3600*24);
    
            return differ
        }
    
  function submitHoliday(){  
      
                setNotice({...notice, 
                    isLoading: true})         
                    const fd = new FormData();
    fd.append('jwt', Token);
                    let formData = holidays; 
                   for(let k in formData){
                       fd.append(k,formData[k]);
                   }; 
         if(holidays.holydayType==='Specific'){   
        fd.append('dateList',  getDateBetween(holidays.startDate, holidays.endDate))
         }else{
        fd.append('dateList',  getDaysBetween(holidays.startDate, holidays.endDate, holidays.day.value))
         }
                  
            let url = ServerUrl+'/save_controller/tbl_events_holidays_specific'
                  axios.post(url, fd, config)
                  .then(response =>{
            
                    if(response.data.type ==='success'){
                        Alerts('Saved!', 'success', response.data.message)
                            }else if(response.data.type ==='info'){
                                Alerts('Update!', 'default', response.data.message)
                                    }else{
                        Alerts('Error!', 'danger', JSON.stringify(response.data))
                            }   
                    })
                    .catch((error)=>{
                      Alerts('Error!', 'danger', error.message)
                    }).finally(()=>{
                        setNotice({...notice, 
                            isLoading: false
                        })
                        fetchAllHolidays()
                  setIsSubmitting(false); 
                 setHolidays({
                    eventType:'Events',
                    startDate:'',
                    endDate:'',
                    startTime:'',
                    endTime:'',
                    colors:'#00ff00',
                    title:'',
                    day:[],
                    holydayType:'Specific',
                    notification:'No'
                 })
                })
               
                }





    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isHolidays){
        submitHoliday()
        }
        },[errors])
    

        const getDaysBetween =(startDate, endDate, day)=>{

            const start = new Date(startDate)
            const end =  new Date(endDate)
        
            var result = []
            start.setDate(start.getDate()+(Number(day)-start.getDay()+7)%7)
            while (start < end){
                result.push(new Date(+start).toISOString().slice(0,10))
                start.setDate(start.getDate()+7)
            }
            return JSON.stringify(result, null, 2)
        }



            const getDateBetween =(startDate, endDate)=>{
                    let dates = [];
                    const theDate = new Date(startDate)
                    const theEnd =  new Date(endDate)
                    while (theDate <= theEnd){
                        dates = [...dates, new Date(theDate).toISOString().slice(0,10)]
                        theDate.setDate(theDate.getDate()+1)
                    }
                   
                    return JSON.stringify(dates, null, 2)
            }

    
const handleDelete = (code)=>{  
    setNotice({...notice, 
       isLoading: true}) 
       let close =   document.getElementById('btnWarningDialog-'+code)
          close.click();
          const fd = new FormData();
          fd.append('jwt', Token);
       fd.append('ID', 'ID')
		fd.append('data', code)

       
        let url = ServerUrl+'/delete_controller/tbl_events_holidays'
       axios.post(url, fd, config)
       .then(response =>{
        if(response.data.type ==='success'){
           Alerts('Saved!', 'success', response.data.message)
               } else{
           Alerts('Error!', 'danger', JSON.stringify(response.data))
               }   
       })
       .catch((error)=>{
         Alerts('Error!', 'danger', error.message)
       }).finally(()=>{
           fetchAllHolidays()
           setNotice({...notice, 
               isLoading: false
           })
       }) 
}
    

    const tableHeader = [
        {dataField: 'ID', text: 'Action', formatter: (cell, row)=><div>
            
<button type='button' className='btn-danger' data-toggle="modal"   data-target={'#warningdialog-'+row.ID}  title="Delete"><i className="icofont icofont-delete-alt"></i></button>
         
         <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.ID)} mID={row.ID} /> 
        </div>, editable: false},
        {dataField: 'title', text: 'Title',validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Event title  is required'
              };
            }
            return true;
          }},
        {dataField: 'startDate', text: 'Start Date', editor: {
            type: Type.DATE
          }, formatter: (cell, row)=>`${cell} ${row.startTime} `},  

        {dataField: 'endDate', text: 'End Date', editor: {
            type: Type.DATE
          }, formatter: (cell, row)=>`${cell} ${row.endTime} `},
    {dataField: 'eventType', text: 'Type', editor: {
        type: Type.SELECT, options:[{value:'Holidays', label:'Holidays'}, {value:'Events', label:'Events'}]
      }}
     ];


     
    const EventTable=(props)=>{

       
        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData();
            fd.append('jwt', Token); 
             fd.append('columnField', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
           
            let url = ServerUrl+'/updateById_controller/tbl_events_holidays'
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>console.log(error)) 
           
         } 
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                            
      <div className="dt-buttons btn-group pull-right"> 
      
    <div className="btn-group dropdown-split-primary ">
        <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-download"></i>Export
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>

         

            <form method='POST' action={ServerUrl+'/excel_controller.php?api=holidays'} target='_blank'>
      

                <input type='hidden' name='jwt' value ={Token} />
               
        <button type="submit" className="btn dropdown-item waves-effect waves-light" > Export To Excel</button>
        
        </form>

        <form method='POST' action={ServerUrl+'/printout/school_holidays.php?token='+userToken} target='_blank'>
                
                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>
        
        </form>
        </div>
    </div> 
    </div> 
      
                                 </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
     useEffect(()=>{
        //setIsAuthenticated(verifyRouter(location.pathname))
        fetchAllHolidays();
        handleSession()
    },[]);
        return (    <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Holidays Timetable">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-table"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Timetable</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Define Holidays</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


                    <div className="row  ">
   <div className="col-sm-12">

    <div className="card-block">
    <div className="alert alert-info background-info">
    Staff, Student And Parents Will Be Notified Immediately. Please Be Sure Before You Enter The Details !!
</div>

</div>

</div>  </div>

                <div className="row  ">
						   <div className="col-sm-12">
   <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Add Event or Holiday</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<div className="row">
					<section className="col-md-6">
                                    
     <label ><a href="/communication/template" ><i className="fa faa-flash animated fa-hand-o-right"></i> Click Here To Configure Notification Settings</a></label>


     <div className="form-group">
     <div className="form-radio">
<div className="radio radio-inline"><label>
        <input type="radio" checked={holidays.eventType ==="Events"} onChange={handleChange} value='Events' name='eventType' />
            <i className="helper"></i>Events</label></div>

            <div className="radio radio-inline"><label>
        <input type="radio" checked={holidays.eventType ==="Holidays"} onChange={handleChange} value="Holidays" name='eventType' />
            <i className="helper"></i>Holidays</label></div>
</div> </div> 

<div style={{display:holidays.eventType ==="Events"?'none':'block'}} className="form-group">
     <div className="form-radio">
<div className="radio radio-inline"><label>
        <input type="radio" checked={holidays.holydayType ==="Recurring"} onChange={handleChange} value='Recurring' name='holydayType' />
            <i className="helper"></i>Recurring Holiday</label></div>

            <div className="radio radio-inline"><label>
        <input type="radio" checked={holidays.holydayType ==="Specific"} onChange={handleChange} value="Specific" name='holydayType' />
            <i className="helper"></i>Specific Date Holiday</label></div>
</div> </div> 


<div style={{display:holidays.eventType ==="Events"?'none':'block'}} className="form-group"><label >Holiday Notification Will Be Sent</label>
     <div className="form-radio">
<div className="radio radio-inline"><label>
        <input type="radio" checked={holidays.notification ==="Yes"} onChange={handleChange} value='Yes' name='notification' />
            <i className="helper"></i>Yes</label></div>

            <div className="radio radio-inline"><label>
        <input type="radio" checked={holidays.notification ==="No"} onChange={handleChange} value="No" name='notification' />
            <i className="helper"></i>No</label></div>
</div> </div> 


</section>
<section className="col-md-6">
     <div className="form-group">
						<label >Title<span style={{color:'red'}}>*</span></label>
		<input type="text"  name="title" value={holidays.title}  onChange={handleChange} className={errors.title  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.title && errors.title}</span>	
			</div>

            <div style={{display:holidays.eventType ==="Events"?'block':'none'}} className="form-group">
						<label >Color</label>
		<input type="color"  name="colors" value={holidays.colors}  onChange={handleChange} className='form-control' /> 
			</div>
         
</section>
<section className="col-md-6">
            <div className="form-group">
            <label >Start Date<span style={{color:'red'}}>*</span></label><label className="pull-right" style={{display:holidays.eventType ==="Events"?'block':'none'}}  >Start Time<span style={{color:'red'}}>*</span></label>
          <div className="input-group">
         <input type="date"  name='startDate' onChange={handleChange} value={holidays.startDate} className={errors.startDate ? 'form-control form-control-danger' : 'form-control'} />

        <input type="time"  name='startTime' style={{display:holidays.eventType ==="Events"?'block':'none'}}  onChange={handleChange} value={holidays.startTime} className={errors.startTime ? 'form-control form-control-danger' : 'form-control'} />
             </div>

              <span style={{color:'red'}}>{errors.startTime && errors.startTime}</span>
          </div>

          </section>
<section className="col-md-6">
        <div className="form-group"><label >End Date<span style={{color:'red'}}>*</span></label><label className="pull-right" style={{display:holidays.eventType ==="Events"?'block':'none'}} >End Time<span style={{color:'red'}}>*</span></label>
         
          <div className="input-group">
         <input type="date"  name='endDate' onChange={handleChange} value={holidays.endDate} className={errors.endDate ? 'form-control form-control-danger' : 'form-control'} />
        <input type="time"  name='endTime' style={{display:holidays.eventType ==="Events"?'block':'none'}}  onChange={handleChange} value={holidays.endTime} className={errors.endTime ? 'form-control form-control-danger' : 'form-control'} />
             </div>

             <span style={{color:'red'}}>{errors.endDate && errors.endDate}</span>
              <span style={{color:'red'}}>{errors.endTime && errors.endTime}</span>
          </div>

			
</section>
<section className="col-md-6">
<div style={{display:holidays.holydayType ==="Specific"?'none':'block'}} className="form-group">
                        <label > Day</label>
                        <Select  options={[
                    { value: '1', label: 'Monday' },
                    { value: '2', label: 'Tuesday' },
                    { value: '3', label: 'Wednessday' },
                    { value: '4', label: 'Thursday' },
                    { value: '5', label: 'Friday' },
                    { value: '6', label: 'Saturday' },
                    { value: '0', label: 'Sunday' }
                ]} 
                className={errors.day ? 'form-control form-control-danger' : ''}               onChange={handleSelect}  name="day" value={holidays.day}  /> 
                 <span style={{color:'red'}}>{errors.day && errors.day}</span>
             </div>
            </section>
										</div>	

	<footer className="pull-right">
	
	<button type="button" className="btn btn-inverse "><i className="fa fa-refresh"></i> Cancel</button>&nbsp;&nbsp;
	<button type="button" id="submit" onClick={holidays.eventType ==="Holidays"?handleHoliday:handleSubmit} className="btn btn-primary ">Save</button>
	
								</footer>				
							</div>
							</div>

                            </div>
		
	

	<div className="col-sm-12">
      
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Listing All Events and Holidays</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
        <div className="col-md-12 table-responsive">

        {notice.isDataFetching ? <TableLoader />:
   <EventTable data={allHolidays}  columns={tableHeader}  />} 

    </div></div></div>
</div>



</div>
	

   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(DefineHolidays) 