import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import { ServerUrl, Token, config, selectStyles } from '../component/include'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import { useDispatch, useSelector } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import Select from 'react-select'

 const FeeType =()=>{   
    const Initials = {
        code:Math.random().toString(36).substr(2,9),
        feessession:[],
        feesCharge: "", 
        status: "true"
    }
const [errors, setErrors] = useState({});

let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
const schoolSession  = useSelector(state => state.rootReducer.schoolSession);
const [data, setData] =  useState([]);
const [isSubmitting, setIsSubmitting] = useState(false);    
const [feetype, setFeetype] = useState(Initials);
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
}); 


    const validateInput =(values)=>{
    let errors = {};     
    if(!values.feesCharge){
        errors.feesCharge='Fees charge field is required';
    }
    if(values.feessession.length===0){
        errors.feessession='Please select session';
    }
    if (data.filter(item=>item.feesCharge ===values.feesCharge&&item.sessionCode ===values.feessession.value).length!==0){
        errors.feessession ='Fee type already exist';
    }

    return errors;
    }


const handleSubmit =(event)=>{
    event.preventDefault();
    setErrors(validateInput(feetype));
    setIsSubmitting(true);
}
const handleReset = ()=>{
    setFeetype(Initials);
}

function submit(){
   
    setNotice({...notice, isLoading: true}) 

     let formData = feetype; 
        const fd = new FormData(); 
        for(let k in formData){
            fd.append(k,formData[k]);
        }; 
        fd.append('jwt', Token);
fd.append('sessionCode', feetype.feessession.value)
       
         let url = ServerUrl+'/save_controller/tbl_fees_charge'
        axios.post(url, fd, config)
        .then(response =>{
            if (response.data.type ==='success'){
                Alerts('Saved!', 'success', response.data.message)
            } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
        }  
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        .finally(()=>{
          setNotice({...notice, isLoading: false}) 
        handleReset(); 
        setIsSubmitting(false)
        handleFetchData()
        }) 
    
}


const  handleFetchData =()=>{
    const fd = new FormData();
            fd.append('jwt', Token);
    let url = ServerUrl + '/fetch_controller/tbl_fees_charge'
    setNotice({...notice,  isDataFetching: true });
    axios.post(url, fd, config)
    .then(result =>setData(result.data))
    .catch((error)=>{
        Alerts('Error!', 'danger', JSON.stringify(error.message)) 
    }).finally(()=>{
        setNotice({...notice, 
            isDataFetching: false
        })
    })    
        }



useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
},[errors])




const handleDelete = (feeID)=>{  
    setNotice({...notice, 
       isLoading: true}) 
       let close =   document.getElementById('btnWarningDialog-'+feeID)
          close.click();
          const fd = new FormData();
          fd.append('jwt', Token);
       fd.append('ID', 'ID')
		fd.append('data', feeID)

        let url = ServerUrl+'/delete_controller/tbl_fees_charge'
       axios.post(url, fd, config)
       .then(response =>{
        if(response.data.type ==='success'){
           Alerts('Saved!', 'success', response.data.message)
               } else{
           Alerts('Error!', 'danger', JSON.stringify(response.data))
               }   
       })
       .catch((error)=>{
         Alerts('Error!', 'danger', error.message)
       }).finally(()=>{
           handleFetchData()
           setNotice({...notice, 
               isLoading: false
           })
       }) 
}


const handleCheckbox =()=>{
const value = feetype.status ==='true'? 'false':'true';
 setFeetype({...feetype, status : value});
}


const handleSelect = (option, action)=>{
    setFeetype({...feetype, [action.name]: option });
   setErrors({...errors, [action.name]:''})
} 

const handleChange =(event)=>{
    const {name, value} = event.target
    setFeetype({...feetype, [name] : value});
    setErrors({...errors, [name]:''})
}

 
const tableFeeHeader = [
    {dataField: 'sessionCode',  text: 'Session', editable: false},
    {dataField: 'feesCharge',  text: 'Charges', validator: (newValue, row, column) => {
        if (!newValue) {
          return {
            valid: false,
            message: 'Fees charge is required'
          };
        }
        return true;
      }},
     
    {dataField: 'status', text: 'Active', sort: true, editor: {
                type: Type.SELECT, options:[{value:'true', label:'Active'},
                {value:'false', label:'Inactive'}]
              }, formatter:(cell)=>cell==='true'? <div className="text-center"><i title="Double click to change" className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i title="Double click to change" className="fa fa-times badge-inverse-danger"></i></div>},

    {dataField: 'ID', text: 'Action', formatter: (cell)=><div>
         <WarningModal  handleAction={()=>handleDelete(cell)} mID={cell} message='Once deleted, you will no be able to recover this record. Are you realy sure?' />
         <button type="button" className="btn btn-danger btn-sm delete" data-toggle="modal"   data-target={`#warningdialog-${cell}`} title="Delete"><i className="fa fa-trash"></i></button>
         
         </div>, editable: false}
 ];


const TableRecord=(props)=>{

const handleUpdate=(column, newValue, ID)=>{       
    const fd = new FormData();
            fd.append('jwt', Token);
        fd.append('newValue', newValue);
        fd.append('column', column.dataField);
        fd.append('ID', ID);
     
       let url = ServerUrl+'/updateCustom_controller/tbl_fees_charge'
      axios.post(url, fd, config)
      //.then(result => console.log(result.data))
      .then()
      .catch((error)=>Alerts('Error!', 'danger', error.message)) 
    } 


     const { SearchBar } = Search;
     
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
      );
     const options = {
        showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
      { text: 'All', value: props.data.length }]
       
      };
     return  <ToolkitProvider search columnToggle 
                 keyField='ID' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                              
 </div>
                             
                 <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                   mode: 'dbclick',
                                   blurToSave: true,
                                   autoSelectText: true,
                         beforeSaveCell: (oldValue, newValue, row, column) => {
                           if(oldValue !==newValue){
                           handleUpdate(column, newValue, row.ID);
                           
                          return true;
                         }
                         
                       }
                                 }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  }
        

  useEffect(()=>{
      
    setIsAuthenticated(verifyRouter(location.pathname))
    handleFetchData();
},[]);



        return ( 
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Fees Charge">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Fees</a> </li>

<li className="breadcrumb-item"><a href="#!">Fees Charge</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">              

                <div className="row  ">
				<div className="col-sm-5">
<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Add Fees charge</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
	<div className="card-block">
		<fieldset>
            <div className="row">	
            
            <section className="col-md-12">
            <div className="form-group">
         <label> Fees Session<span style={{color:'red'}}>*</span></label>
         <Select   options={schoolSession.map((dat, id)=> {
                                    return {key:id, value:dat.startYear, label:dat.startYear};
                                  })}
onChange={handleSelect}  name="feessession" value={feetype.feessession} styles={selectStyles} className={errors.feessession ? 'form-control form-control-danger' : ''} />
                     <span style={{color:'red'}}>{errors.feessession && errors.feessession}</span>
            </div>
</section>


<section className="col-md-12">
            <div className="form-group">
                        <label >Fee Charge Name<span style={{color:'red'}}>*</span></label>
                        <input type='text' onChange={handleChange}  name='feesCharge' value={feetype.feesCharge} className={`form-control ${errors.feesCharge?'form-control-danger':''}`} placeholder='e.g First Term school fees' />
                                    
            </div>
            <span style={{color:'red'}}>{errors.feesCharge && errors.feesCharge}</span>
            </section>
        </div>
        
         
        
        <div className="row">
            <section className="col-md-12"><hr/>
            <div className="checkbox-color checkbox-primary">
        <input id="status"  name="status" type="checkbox" onChange={handleCheckbox} checked={feetype.status==='true'? true:false} />
                <label htmlFor="status">Active</label>
            </div>	
            </section>
        </div>
     </fieldset>


        <footer className="pull-right">
       
        <button onClick={handleSubmit} className='btn btn-inverse'  type="button" >Save</button>

        </footer>				
    </div>
</div>
</div>

    <div className="col-sm-7">
    <div className="card z-depth-0">
        <div className="card-header">
            <h5>Listing All Fee Charge</h5>
            <div className="card-header-right">
                <ul className="list-unstyled card-option">
                    <li><i className="feather icon-maximizes full-card"></i></li>
                    <li><i className="feather icon-minus minimize-card"></i></li>
                    <li><i className="feather icon-trash-2 close-card"></i></li>
                </ul>
            </div>
        </div>

        

<div className="card-block">
<div className="col-md-12 table-responsive">

        {notice.isDataFetching ? 
        <TableLoader />:
        <TableRecord data={data} fetchdata={handleFetchData} columns={tableFeeHeader}  />
        }
</div></div>
</div>
</div>

</div>
</div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        }


export default React.memo(FeeType)