import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import {Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import Select from 'react-select'
import {ServerUrl, Token, config} from '../component/include'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { longDate } from '../component/globalFunction'

const Session = ()=>{

    const Initials = {
        startDate: "",
        endDate: "",
        startYear:""
    }

const [session, setSession] = useState(Initials);
const [data, setData] =  useState([]);
const [errors, setErrors] = useState({});


let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
const [isSubmitting, setIsSubmitting] = useState(false);
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
}); 

const handleYear =(num)=>{
    let currentYear = new Date().getFullYear();
    let from = currentYear-num;
    let to = from+1;
    let result = from+'/'+to;
    return result;
}


let sessionYear = [
        { value: handleYear(2), label: handleYear(2)},
        { value: handleYear(1), label: handleYear(1) },
        { value: handleYear(0), label: handleYear(0) },
        { value: handleYear(-1), label: handleYear(-1) }
    ]
function submit(){
      
             setNotice({...notice, 
            isLoading: true}) 
          let close =   document.getElementById('myModal')
          close.click();
           let formData = session; 
            const fd = new FormData(); 
            for(let k in formData){
                fd.append(k,formData[k]);
            }
            fd.append('jwt', Token);
             let url = ServerUrl+'/save_controller/tbl_school_session';
            axios.post(url, fd, config)
            .then(response =>{
                if (response.data.type ==='success'){
                    Alerts('Saved!', 'success', response.data.message)
                } else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data))
            }  
                    
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', JSON.stringify(error.message)) 
            })
            .finally(()=>{
              setNotice({...notice, isLoading: false}) 
            handleReset(); 
            handleFetchData()
            }) 
    }

    function UpdateSession(code){
      
        setNotice({...notice, isLoading: true}) 
     
       const fd = new FormData(); 
       fd.append('code', code);
       fd.append('jwt', Token);
        let url = ServerUrl+'/update_controller/tbl_update_school_session';
       axios.post(url, fd, config)
       .then(response =>{
           console.log(response.data)
           if (response.data.type ==='info'){
               Alerts('Info!', 'info', response.data.message)
               window.location.reload()
           } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
       }  
               
       })
       .catch((error)=>{
           Alerts('Error!', 'danger', JSON.stringify(error.message)) 
       })
       .finally(()=>{
         setNotice({...notice, isLoading: false}) 
       handleFetchData()
       }) 
}




    const handleDelete =(ID)=>{
		let close =   document.getElementById('btnWarningDialog-'+ID)
		close.click();
		

		setNotice({...notice, 
			isLoading: true}) 

		const fd = new FormData();
		fd.append('ID', 'ID')
		fd.append('data', ID)
        fd.append('jwt', Token);
	let url = ServerUrl+'/delete_controller/tbl_school_session';
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'success', response.data.message)
              window.location.reload()
				  } else{
			  Alerts('Error!', 'danger', JSON.stringify(response.data))
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
              handleFetchData()
			  setNotice({...notice, 
				  isLoading: false
			  })
		  }) 
}

    const handleReset = ()=>{
        setSession(Initials);
    }

    function ValidateInput(values){
        let errors = {};     
        
        if(!values.startDate){
            errors.startDate ='Please choose start date';
        }
        if(!values.endDate){
            errors.endDate ='Please choose end date';
        }
        if(!values.startYear){
            errors.startYear ='Please select session year';
        }
        if (data.filter(item=>item.startYear ===values.startYear).length!==0){
            errors.startYear ='Session Year already created';
        }
        return errors;
    }

   const  handleFetchData =()=>{
    const fd = new FormData();
    fd.append('jwt', Token);
    let url = ServerUrl + '/fetch_controller/tbl_school_session';
    axios.post(url, fd, config)
    .then(result => setData(result.data))
    .catch((error)=>{
        Alerts('Error!', 'danger', JSON.stringify(error.message)) 
    })
    }


const handleChange = event =>{
    const {name, value} = event.target
   setSession({...session, [name] : value });
   setErrors({...errors, [name] :'' });
}

const handleSelect = (option, action)=>{
    setSession({...session, [action.name]: option.value });
    setErrors({...errors, [action.name] :'' });
};

 const handleSubmit = event =>{
    event.preventDefault();
    setErrors(ValidateInput(session));
    if(Object.keys(errors).length === 0){
        setIsSubmitting(true);
    }
    
} 

useEffect(()=>{
if(Object.keys(errors).length === 0 && isSubmitting){
submit()
}
},[errors])



    const tableSessionHeader = [
        {dataField: 'startYear',  text: 'Session', editable: false},
        {dataField: 'startDate', text: 'Start Date', editor: {
            type: Type.DATE },  formatter: (cell)=>longDate(cell)},
            {dataField: 'endDate', text: 'End Date', editor: {
                type: Type.DATE },  formatter: (cell)=>longDate(cell)},
        {dataField: 'ID', text: 'Action', formatter: (cell, row)=><div>
{row.status==='Active'?
<button type="button"  className="btn btn-inverse btn-sm" title="Active"><i className="fa fa-unlock"></i> Active</button>:<button type="button" onClick={()=>UpdateSession(row.ID)} className="btn btn-warning btn-sm" title="Inactive"><i className="fa fa-lock"></i> Lock</button>}&nbsp;

			 <WarningModal  handleAction={()=>handleDelete(cell)} mID={cell} message='Once deleted, you will no be able to recover this record. Are you realy sure?' />
			{/*  <button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${cell}`} title="Delete"><i className="fa fa-trash"></i> Delete</button> */}
			 
			 </div>, editable: false}
     ];


const TableRecord=(props)=>{

    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData(); 
            fd.append('newValue', newValue);
            fd.append('column', column.dataField);
            fd.append('ID', ID);
            fd.append('jwt', Token);
           let url = ServerUrl+'/updateCustom_controller/tbl_school_session'
          axios.post(url, fd, config)
          //.then(result => console.log(result.data))
          .then()
          .catch((error)=>Alerts('Error!', 'danger', error.message)) 
        } 


         const { SearchBar } = Search;
         
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 
    <div className="dt-buttons btn-group pull-right"> 
         
    <button type="button" className="btn btn-outline-primary" data-toggle="modal" data-target="#myModal"><i className="fa fa-pencil"></i>Create More Session</button>
 </div>                   
     </div>
                                 
                     <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return true;
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }

useEffect(()=>{
    
    //setIsAuthenticated(verifyRouter(location.pathname))
    handleFetchData();

},[]);

 
return ( <>
    
    <div  className="main-body">
    {isAuthenticated?   <div className="page-wrapper">
                  {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" } 
        <PageHeader title="School Session">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-calendar"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="#!">School Session</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

<div className="row">
<div className="col-sm-12">





{/* Modal start here */}

<div className="modal fade" id="myModal" tabIndex="-1" role="dialog" >
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h4 className="modal-title">Create New School Session</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">×</span>
</button> </div>
            <div className="modal-body">
           
            <form name="addSession" >
        <div className="row">                
                <div className="col-md-12">                                                  
                        <div className="form-group">			
                        <label >Select Session</label>	
                        <Select  options={sessionYear} 
onChange={handleSelect} className={errors.startYear  ? 'form-control form-control-danger' : ''}  
name="startYear" value={{value: session.startYear, label: session.startYear}}  /> 	
                       <span style={{color:'red'}}>{errors.startYear && errors.startYear}</span>
                        </div>		
									</div>
								</div>
            <div className="row">
                    <div className="col-md-6">		
                    <div className="form-group">		
                    <label  >Start Date<span style={{color:'red'}}>*</span></label>
<input type="date" value={session.startDate} className={errors.startDate ? 'form-control form-control-danger' : 'form-control'}  placeholder="start date" onChange={handleChange} name="startDate"   />
<span style={{color:'red'}}>{errors.startDate && errors.startDate}</span>
</div></div>
								
        <div className="col-md-6">
                <div className="form-group">			
            <label >End Date<span style={{color:'red'}}>*</span></label>		
            <input name="endDate" value={session.endDate}  placeholder="End Date" onChange={handleChange} className={errors.endDate ? 'form-control form-control-danger' : 'form-control'} type="date" />
            <span style={{color:'red'}}>{errors.endDate && errors.endDate}</span>
            </div></div>
    </div>
</form>
</div>
            <div className="modal-footer">
 <button type="button" id="btnClose" className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
    <button type="button" onClick={handleSubmit}  className="btn btn-inverse waves-effect waves-light ">Create</button>
            </div>
        </div>
    </div>
</div>

{/* Modal end here */}

<div className="alert alert-primary">
        <h3><p className="alert alert-danger border-danger background-danger" >Important : Choose Session Start Date Carefully</p></h3>
        
        
 <p >Fees calculation as well as Other Academic activity is dependent on the Session .  <strong  >So before Saving Make sure You Confirm The Dates </strong></p>
 <p>To edit a column, double click on the field to show editing input</p>
						
					</div></div> </div>


        <div className="row  ">
     
	<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>Listing All Sessions</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
        <div className="row">
          <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? 
        <TableLoader />:
        <TableRecord data={data}  columns={tableSessionHeader}  />
        }
</div></div></div> 


</div>
</div>
</div>  
                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 

);


}

export default React.memo(Session) 