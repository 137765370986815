import React, { useEffect } from 'react'
 import Cookies from 'js-cookie'
 
const LogOut =()=> {


  const clearCookies=()=>{
    Cookies.remove('greatkaustnm')
     Cookies.remove('greatkauscd')	
    Cookies.remove('greatkagpcd')
    Cookies.remove('greatkamtk')
    Cookies.remove('greatkarfsh')
    localStorage.setItem('_greatexpt', Date.now())
    localStorage.setItem('_greatbexpt', Date.now())
    localStorage.setItem('_greatmnulk', Date.now())
    localStorage.setItem('greatkarls', '[]')
  }

 useEffect(()=>{  
  clearCookies()
  window.open("/", '_self')
},[]); 

  return (
    <div>...please wait</div>
  )
}

export default LogOut