import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl, Token, config, schoolName} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel, getClassName, longDate} from '../component/globalFunction'
import { userToken } from '../component/authentication'
import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import { useDispatch, useSelector } from 'react-redux'

const FamilyList =()=> {
    const structure = useRef(null)
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const [allResult, setAllResult]=useState([])
    const [allFamily, setAllFamily]= useState([])
    const [result, setResult] = useState({
        studentClass:[],
        family:[],
        orderBy:{value:' order by studentName ASC', label:'order by Name'}
    })
  


const handleSelect = (option, action)=>{
    setResult({...result, [action.name]: option });
   setErrors({...errors, [action.name]:''})
  
}


const fetchResult =()=>{
        setNotice({...notice, isLoading: true}) 
var sql ="SELECT admissionNumber, gender, registerNumber, studentName, termination, termination_date, termination_remark, sectionID, classID, email, fatherName, father_mobile, fatherEmail, mother_mobile, motherEmail, altMobile, mobile, currentAddress, family_name FROM tbl_students  where family_name <> '' "

if(result.studentClass.length!==0 ){
    sql = sql + '  and sectionID = "'+result.studentClass.value+'" '
}

if(result.family.length!==0 ){
    sql = sql + '  and family_name = "'+result.family.value+'" '
}

sql = sql + result.orderBy.value 

const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(response=>{

    if(response.data.length!==0){
        var options = response.data;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
          value.push({
            admissionNumber:options[i].admissionNumber,  
            studentName:options[i].studentName, 
            termination:options[i].termination, 
            termination_date:options[i].termination_date, 
            termination_remark:options[i].termination_remark, 
            sectionID:options[i].sectionID, 
            classID:options[i].classID, 
            email:options[i].email, 
            fatherName:options[i].fatherName, 
            father_mobile:options[i].father_mobile, 
            fatherEmail:options[i].fatherEmail, 
            mother_mobile:options[i].mother_mobile, 
            motherEmail:options[i].motherEmail, 
            altMobile:options[i].altMobile, 
            mobile:options[i].mobile, 
            currentAddress:options[i].currentAddress, 
            family_name:getFamilyName(options[i].family_name)
        });
        }


setAllResult(value.filter(item=>item.family_name!==''))
structure.current.scrollIntoView({ behavior: "smooth" });

    }else{
        setAllResult([])
        Alerts('Info!', 'info', 'There are no student found for the selected family or class')
    }
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
    
}) 
    
}


const getFamilyName =(code)=>{
    const result = allFamily.filter(list=>list.code===code);
  const answer = result.map(ans=>ans.familyName)
    return String(answer)
  }


const fetchFamily =()=>{
    const fd = new FormData();
    fd.append("jwt", Token);
    let url = ServerUrl+'/fetch_controller/tbl_family'
    axios.post(url, fd, config).then(result=>setAllFamily(result.data))
}

useEffect(()=>{
    //setIsAuthenticated(verifyRouter(location.pathname))
    fetchFamily();
},[]);

        return (  <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Family Summary">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Students</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Family Summary</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  no-print">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i>Family Summary</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		<div className="row">

                       

     <section className="col-md-4">
           <div className="form-group">
         <label> Student Class</label>
         <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  name="studentClass" value={result.studentClass} className={errors.studentClass ? 'form-control form-control-danger' : ''} /> 

<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>
            </div>
     
     </section>     
     <section className="col-md-4">
           <div className="form-group">
         <label> Family Name</label>
         <Select  options={
                            allFamily&&allFamily.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.familyName} 
							 })
							 
                        } 
onChange={handleSelect}   name="family" value={result.family}  /> 

            </div>
     
     </section>  

     <section className="col-md-4">
           <div className="form-group">
         <label> Order</label>
         <Select  options={[
             {value:' order by studentName ASC', label:'order by Name'},
                    { value: ' order by registerNumber ASC', label: 'Order By Register Number'},
             { value: ' order by admissionNumber ASC', label: 'Order By Admission Number'},
                ]} 
onChange={handleSelect}  name="orderBy" value={result.orderBy}  /> 

            </div>
     
     </section>

				</div>	

	<footer className="pull-right">
	
	<button type="button"  onClick={fetchResult} className="btn btn-inverse "><i className="fa fa-search"></i> Search</button>
								</footer>				
							</div>
                            </div>
							</div></div>
</div>

</div>
	



{allResult.length!==0?

<div className="card z-depth-0">
		<div className="card-block">
	
                       <div className="row" >

                       {schoolInfo&&schoolInfo.map((st, id)=>
                       <table className='table'  key={id}>
       <tbody>
                <tr>
                    <td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
 </td>
                </tr>
            </tbody>
       </table>  )}

</div> 
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>FAMILY SUMMARY </b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">
        <table className="table table-striped table-bordered  table-full-width">
    

            <thead>
                <tr>
                <th>SN</th>
                <th>Adm. No</th>
                    <th>Student</th>
                    
                    <th>Class</th>
                    <th>Family Name</th>
                    <th>Current Address</th>
                    <th>Phone Numbers</th>
                    <th>Email</th>
                </tr>
            </thead>
            <tbody>{allResult&&allResult.map((row, idx)=>
            
            <tr key={idx}>
                    <td>{idx+1}</td>
                    <td>{row.admissionNumber}</td>
                <td style={{maxWidth:'350px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.studentName} <br/>
                {row.termination==='Yes'?<span className="pcoded-badge label label-danger">Admission Terminated on {longDate(row.termination_date)} {row.termination_remark}</span>:''}</td>

                    <td>{getClassName(row.classID, row.sectionID, schoolClass)}</td>
                    <td>{row.family_name}</td>
                    

                    <td style={{maxWidth:'350px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.currentAddress}</td>
                    <td style={{maxWidth:'350px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.father_mobile+' '+row.mother_mobile+' '+row.mobile+' '+row.altMobile}</td>
                    <td style={{maxWidth:'350px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.fatherEmail+' '+row.motherEmail+' '+row.email}</td>
                </tr>)}
            </tbody> 
        </table>
 </div>	 </div> 

 <div ref={structure} className="row no-print">
        <div className="col-md-12">
       

       
        <form method='POST' action={ServerUrl+'/printout/family_summary.php?token='+userToken} target='_blank' className='pull-right' >
        <input type='hidden' name='classID' value ={result.studentClass.value} />
                <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='jwtToken' value ={Token} />
                <input type='hidden' name='allResult' value ={JSON.stringify(allResult, null, 2)} />
               
                <button type="submit" className="btn btn-inverse  " > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>
        </div>:''}




   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(FamilyList) 