import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import { ImagesUrl, ServerUrl, Token, config } from '../component/include'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

 const ELibrary =()=>{   
    const Initials = {
        title:'',
        fileUrl:''
    }
    
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
const [errors, setErrors] = useState({});
const [data, setData] =  useState([]);
const [isSubmitting, setIsSubmitting] = useState(false);    
const [book, setBook] = useState(Initials);
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
}); 

    const validateInput =(values)=>{
    let errors = {};     
    if(!values.title){
        errors.title ='Title field is required';
    }
    if(!values.fileUrl){
        errors.fileUrl ='Please upload ebook';
    }
    if (data.filter(item=>item.title ===values.title).length!==0){
        errors.fees ='Book title already exist';
    }

    return errors;
    }


const handleSubmit =(event)=>{
    event.preventDefault();
    setErrors(validateInput(book));
    setIsSubmitting(true);
}


const handleReset = ()=>{
    setBook(Initials);
}

function submit(){
   
    setNotice({...notice, isLoading: true}) 

     let formData = book; 
     const fd = new FormData();
     fd.append('jwt', Token);
        for(let k in formData){
            fd.append(k,formData[k]);
        }; 

         let url = ServerUrl+'/save_controller/tbl_elibrary'
        axios.post(url, fd, config)
        .then(response =>{
            if (response.data.type ==='success'){
                Alerts('Saved!', 'success', response.data.message)
            } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
        }  
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        .finally(()=>{
          setNotice({...notice, isLoading: false}) 
        handleReset(); 
        setIsSubmitting(false)
        handleFetchData()
        document.getElementById('fileUrl').value = ''
        }) 
    
}

const  handleFetchData =()=>{
    const fd = new FormData();
    fd.append('jwt', Token);
    let url = ServerUrl + '/fetch_controller/tbl_elibrary'
    setNotice({...notice, isDataFetching: true  });
    axios.post(url, fd, config)
    .then(result =>setData(result.data))
    .catch((error)=>{
        Alerts('Error!', 'danger', JSON.stringify(error.message)) 
    }).finally(()=>{
        setNotice({...notice, 
            isDataFetching: false
        })
    })    
        }


useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
},[errors])



useEffect(()=>{
    handleFetchData();
},[]);


const handleDelete = (code)=>{  
    setNotice({...notice,   isLoading: true}) 
       let close =   document.getElementById('btnWarningDialog-'+code)
          close.click();
          const fd = new FormData();
          fd.append('jwt', Token);
       fd.append('ID', 'code')
		fd.append('data', code)

        let url = ServerUrl+'/delete_controller/tbl_elibrary'
       axios.post(url, fd, config)
       .then(response =>{
        if(response.data.type ==='success'){
           Alerts('Saved!', 'success', response.data.message)
               } else{
           Alerts('Error!', 'danger', JSON.stringify(response.data))
               }   
       })
       .catch((error)=>{
         Alerts('Error!', 'danger', error.message)
       }).finally(()=>{
           handleFetchData()
           setNotice({...notice, 
               isLoading: false
           })
       }) 
}




const handleChange =(event)=>{
    const {name, value} = event.target
    setBook({...book, [name] : value});
    setErrors({...errors, [name]:''})
}

 
const tableFeeHeader = [
    {dataField: 'title',  text: 'Title', validator: (newValue, row, column) => {
        if (!newValue) {
          return {
            valid: false,
            message: 'Title is required'
          };
        }
        return true;
      }},
     
    {dataField: 'fileLink', text: 'File Link', editable: false, formatter:(cell)=><a href={ImagesUrl+'/library/'+cell} target='blank'><i className='fa fa-download'></i> View</a>},
    {dataField: 'viewNo', text: 'View', editable: false},
   /*  {dataField: 'code', text: 'Action', formatter: (cell)=><div>
         <WarningModal  handleAction={()=>handleDelete(cell)} mID={cell} message='Once deleted, you will no be able to recover this record. Are you realy sure?' />
         <button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${cell}`} title="Delete"><i className="fa fa-trash"></i></button>
         
         </div>, editable: false} */
 ];


const TableRecord=(props)=>{

const handleUpdate=(column, newValue, ID)=>{       
    const fd = new FormData();
        fd.append('jwt', Token);
        fd.append('newValue', newValue);
        fd.append('column', column.dataField);
        fd.append('ID', ID);
      
       let url = ServerUrl+'/updateCustom_controller/tbl_elibrary'
      axios.post(url, fd, config)
      //.then(result => console.log(result.data))
      .then()
      .catch((error)=>Alerts('Error!', 'danger', error.message)) 
    } 


     const { SearchBar } = Search;
     
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
      );
     const options = {
        showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
      { text: 'All', value: props.data.length }]
       
      };
     return  <ToolkitProvider search columnToggle 
                 keyField='ID' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                              
 </div>
                             
                 <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                   mode: 'dbclick',
                                   blurToSave: true,
                                   autoSelectText: true,
                         beforeSaveCell: (oldValue, newValue, row, column) => {
                           if(oldValue !==newValue){
                           handleUpdate(column, newValue, row.ID);
                           
                          return true;
                         }
                         
                       }
                                 }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  }


  const  handleFile = function(fieldName){
    return function(newValue){                 
        const value = newValue.target.files[0]
      // setImage(value)
   readURL(newValue);
   }
   
  } 
  const readURL = (input)=>{
       
    let displayMessage = '';
    const PreviewImage = input.target.name;
        var fileName = document.getElementById(PreviewImage).value;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

    if (extFile==="pdf" || extFile==="PDF" ){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 10) {
        displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 10mb';
        document.getElementById('fileUrl').value = '';
       setBook({...book, fileUrl:''})
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.

            setBook({...book, fileUrl:input.target.files[0]})
        }
    }
    }else{

        displayMessage = 'Only PDF  files are allowed with maximum of 10Mb'
        document.getElementById(input.target.name).value = '';
        setBook({...book, fileUrl:''})
    }  
     if(displayMessage.length > 0){
        Alerts('Error!', 'danger', displayMessage)
      }
    
} 

useEffect(()=>{
    setIsAuthenticated(verifyRouter(location.pathname))
}, [])
        
        return (  <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" } 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="E-Library">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-book"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Library</a> </li>

<li className="breadcrumb-item"><a href="#!">eLibrary</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">              

                <div className="row  ">
				<div className="col-sm-12">
<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Add Books</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
	<div className="card-block">
		<fieldset>
            <div className="row">	
            <section className="col-md-12">
            <div className="form-group">
                        <label >Title<span style={{color:'red'}}>*</span></label>
                        <input type='text' onChange={handleChange}  name='title' value={book.title} className={`form-control ${errors.title?'form-control-danger':''}`} placeholder='Book title' />
                                    
            </div>
            <span style={{color:'red'}}>{errors.title && errors.title}</span>
            </section>

            <section className="col-md-12">
            <div className="form-group">
                        <label >Upload PDF<span style={{color:'red'}}>*</span></label>
                        <input type='file' onChange={handleFile('fileUrl')} id='fileUrl' name='fileUrl'  className='form-control' accept="pdf/*"  />
                                    
            </div>
            <span style={{color:'red'}}>{errors.fileUrl && errors.fileUrl}</span>
            </section>
        </div>

     </fieldset>


        <footer className="pull-right">
       
        <button onClick={handleSubmit} className='btn btn-inverse'  type="button" >Save</button>

        </footer>				
    </div>
</div>
</div>

    <div className="col-sm-12">
    <div className="card z-depth-0">
        <div className="card-header">
            <h5>Listing All Books</h5>
            <div className="card-header-right">
                <ul className="list-unstyled card-option">
                    <li><i className="feather icon-maximizes full-card"></i></li>
                    <li><i className="feather icon-minus minimize-card"></i></li>
                    <li><i className="feather icon-trash-2 close-card"></i></li>
                </ul>
            </div>
        </div>

        

<div className="card-block">
<div className="col-md-12 table-responsive">

        {notice.isDataFetching ? 
        <TableLoader />:
        <TableRecord data={data}  columns={tableFeeHeader}  />
        }
</div></div>
</div>
</div>

</div>
</div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        }


export default React.memo(ELibrary)