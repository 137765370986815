import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token,FormatNumber, Currency, config} from '../component/include'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Select from 'react-select'
import { longDate } from '../component/globalFunction'

const CreateAccount =()=> {
    const [errors, setErrors] = useState({});
    
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    
    const [account, setAccount] = useState({
        accountType:[],
        accountName:'',
        openingBalance:0,
        openingDate:'0000-00-00'
    })

    const [accountType, setAccountType] = useState([])
    const [allAccount, setAllAccount]= useState([])




    const fetchAccount =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });

        var sql =  'select  a.ID, a.accountName, a.code, a.openingDate, t.account_type, a.openingBalance as balanceOpen,  (coalesce(a.openingBalance,0) - coalesce((select sum(e.amount) from tbl_expenses e where e.account = a.code  group by e.account),0)  + coalesce((select sum(i.amount) from tbl_income i where i.account = a.code  group by i.account),0) - coalesce( (select sum(s.totalAmount) from tbl_salary_payment  s where s.paymentAccount = a.code group by s.paymentAccount),0)) as openingBalance from tbl_account a, tbl_account_type t where a.accountType = t.code  '
       
        setNotice({...notice, isLoading: true}) 
        const fd = new FormData();
        fd.append('jwt', Token);
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=> setAllAccount(result.data))
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
        setNotice({...notice, isDataFetching: false})

        })  
} 

const fetchAccountType =()=>{
    const fd = new FormData();
        fd.append('jwt', Token);
    let url = ServerUrl + '/fetch_controller/tbl_account_type'
    axios.post(url, fd, config).then(result=>setAccountType(result.data))
}

const handleDelete = (code)=>{  
    setNotice({...notice, 
       isLoading: true}) 
       let close =   document.getElementById('btnWarningDialog-'+code)
          close.click();
          const fd = new FormData();
          fd.append('jwt', Token);
       fd.append('ID', 'code')
        fd.append('data', code)

        let url = ServerUrl+'/delete_controller/tbl_account'
       axios.post(url, fd, config)
       .then(response =>{
        if(response.data.type ==='success'){
           Alerts('Saved!', 'success', response.data.message)
               } else{
           Alerts('Error!', 'danger', JSON.stringify(response.data))
               }   
       })
       .catch((error)=>{
         Alerts('Error!', 'danger', error.message)
       }).finally(()=>{
        fetchAccount()
           setNotice({...notice, 
               isLoading: false
           })
       }) 
}


const handleChange = event =>{
    const {name, value} = event.target
   setAccount({...account, [name] : value });
   setErrors({...errors, [name]:''});
}

const handleSelect = (option, action)=>{
    setAccount({...account, [action.name]: option.value});
    setErrors({...errors, [action.name]:''})
     }


const tableHeader = [
    {dataField: 'ID', text: 'Action', formatter: (cell, row)=><div>
        <a href="#!" data-toggle="modal"   data-target={`#warningdialog-${cell}`} className='btn btn-danger delete'     title="Delete"><i className="icofont icofont-delete-alt"></i></a>&nbsp;

<WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code)} mID={cell} />
    </div>, editable: false},
    {dataField: 'account_type', text: 'Type', editable:false},
    {dataField: 'accountName', text: 'Account Name', validator: (newValue, row, column) => {
        if (!newValue) {
          return {
            valid: false,
            message: 'Account Name is required'
          };
        }
        return true;
      } },
    
    {dataField: 'balanceOpen', text: 'Opening Balance', editable:false, formatter:(cell)=>`${Currency+FormatNumber(cell)}`},
    {dataField: 'openingDate', text: 'Opening Date', formatter:(cell)=>longDate(cell), editor: {
        type: Type.DATE
      } }
 ];

 
 const TableAccount=(props)=>{
       
    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData();
        fd.append('jwt', Token); 
            fd.append('newValue', newValue);
            fd.append('column', column.dataField);
            fd.append('ID', ID);
          const config = {
              headers: { 'content-type': 'multipart/form-data'}
          }
           let url = ServerUrl+'/updateCustom_controller/tbl_account'
          axios.post(url, fd, config)
          //.then(result => console.log(result.data))
          .then()
          .catch((error)=>Alerts('Error!', 'danger', error.message)) 
        }  
        
        const { SearchBar } = Search;
        
        const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
        );
        const options = {
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
        { text: 'All', value: props.data.length }]
        
        };
        return  <ToolkitProvider search columnToggle 
                    keyField='code' data={ props.data } columns={ props.columns } >
                        
                        {
                            props => (
                            
                            <div className="form-group">
                                <SearchBar
                                { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                    
                                           
                    <BootstrapTable
                                { ...props.baseProps }
                                            striped
                                        hover
                                        condensed
                                        noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                    pagination={paginationFactory(options) }
                                    cellEdit={ cellEditFactory({
                                    mode: 'dbclick',
                                    blurToSave: true,
                                    autoSelectText: true,
                            beforeSaveCell: (oldValue, newValue, row, column) => {
                            if(oldValue !==newValue){
                            handleUpdate(column, newValue, row.ID);
                            
                            return true;
                            }
                            
                        }
                                    }) }
                                />
                            </div>
                            )
                        }
    </ToolkitProvider>
    }

    
     const handleSubmit = event =>{
        event.preventDefault();
         
    setErrors(ValidateInput(account));
        setIsSubmitting(true);
    } 

   
const ValidateInput =(values)=>{
	let errors = {};   
    
    if(values.accountType.length===0){
        errors.accountType ='Please enter account type';
    }
    if(!values.accountName){
        errors.accountName ='Please enter account Name';
    }
    
    return errors;
    }
    

function submit(){  
      
    const fd = new FormData();
    fd.append('jwt', Token);
       let formData = account; 
      for(let k in formData){
          fd.append(k,formData[k]);
      }; 
    setNotice({...notice, 
	  isLoading: true}) 

       let url = ServerUrl+'/save_controller/tbl_account'
	  axios.post(url, fd, config)
	  .then(response =>{

        if(response.data.type ==='success'){
            Alerts('Saved!', 'success', response.data.message)
                } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
              setIsSubmitting(false)
           fetchAccount()
              setNotice({...notice, 
                  isLoading: false
              })
              setAccount({
                accountType:[],
                accountName:'',
                openingBalance:0,
                openingDate:'0000-00-00'}) 
          }) 
}


useEffect(()=>{
    setIsAuthenticated(verifyRouter(location.pathname))
    fetchAccount();
    fetchAccountType();
},[]);

useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])
    
     
        return (  
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Create Account">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-home"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Finance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Create Account</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
							
                               
                               <div className="col-sm-4">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Create Account</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<fieldset>
            <div className="row">
									
										<section className="col-md-12">
                                        <div className="form-group">
						<label >Account Type<span style={{color:'red'}}>*</span></label>
						<Select options={
                            accountType&&accountType.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.account_type }
                             })} 
onChange={handleSelect} className={errors.accountType ? 'form-control form-control-danger' : ''} name="accountType" value={account.accountSelect}  /> <span style={{color:'red'}}>{errors.accountType && errors.accountType}</span>
												
											</div>
										<div className="form-group">
						<label >Account Name<span style={{color:'red'}}>*</span></label>
				<input type="text" name="accountName" autoComplete="false" value={account.accountName} onChange={handleChange} className={errors.accountName  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.accountName && errors.accountName}</span>	
			</div>

            <div className="form-group">
						<label >Opening Balance</label>
				<input type="number" min='0' name="openingBalance" value={account.openingBalance} onChange={handleChange} className='form-control' /> 
												
											</div>

                                            <div className="form-group">
						<label >Account Start Date</label>
						<input type="date" name="openingDate" value={account.openingDate} onChange={handleChange} className='form-control' /> 
												
											</div>
										</section>
										</div>
                                      	</fieldset>

	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-primary ">Save</button>
	
								</footer>				
							</div>
							</div>
							</div>
		
	
	

	<div className="col-sm-8">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Listing All Accounts</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


        <div className="card z-depth-0">
            <div className="card-block">
        <div className=" col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
   <TableAccount data={allAccount}  columns={tableHeader}  />} 
</div>
	</div>
    </div></div></div>
</div>

</div>
	
   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(CreateAccount) 