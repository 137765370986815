import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config} from '../component/include'
import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import {Type} from 'react-bootstrap-table2-editor';
import { useDispatch, useSelector } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { getTime } from '../component/globalFunction'

const Conference =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [conference, setConference] = useState({
        conferenceName:'',
        conferenceDate:'',
        startTime:'',
        endTime:'',
        intervalMins:''
    })
    const [allconference, setAllconference]= useState([])
    const handleChange = event =>{
		const {name, value} = event.target
       setConference({...conference, [name] : value });
       setErrors({...errors, [name]:''});
    }

    const handleDelete = (ID)=>{  
        setNotice({...notice,  isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+ID)
              close.click();
           const fd = new FormData();
           fd.append('ID', 'ID')
            fd.append('data', ID)
            fd.append("jwt", Token);
            let url = ServerUrl+'/delete_controller/tbl_conference';
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
                   }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
              fetchconference()
               setNotice({...notice, 
                   isLoading: false
               })
           }) 
    }

  

    const tableHeader = [
      /*   {dataField: 'ID', text: 'Action', formatter: (cell, row)=><div>
            <a href="#!" data-toggle="modal"  className='btn btn-danger btn-sm delete'  data-target={`#warningdialog-${cell}`} title="Delete"><i className="icofont icofont-delete-alt"></i></a> 

<WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(cell)} mID={cell} />
        </div>, editable: false}, */
        {dataField: 'conferenceName', text: 'Conference',  sort: true,  validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'conference Name is required'
              };
            }
            return true;
          } },
          {dataField: 'conferenceDate', text: 'Date', sort: true, editor: {
            type: Type.DATE
          } }, 
          {dataField: 'startTime', text: 'Time', editable: false,  formatter: (_, row)=>getTime(row.startTime)+ ' - '+getTime(row.endTime)},
          {dataField: 'intervalMins', text: 'Interval', editable: false},   
          {dataField: 'dateCreate', text: 'Date Posted', editable: false},
          {dataField: 'status', text: 'status', formatter: (cell)=>cell==='Open'? <span className="pcoded-badge label label-success">{cell}</span>: <span className="pcoded-badge label label-danger">{cell}</span>,
          editor: {
              type: Type.SELECT, options:[
                  { value: 'Open', label: 'Open' },
                  { value: 'Closed', label: 'Closed' }
              ] }},
     ];

  

         const fetchconference =()=>{
            const fd = new FormData();
            fd.append("jwt", Token);
            let url = ServerUrl+'/fetch_controller/tbl_conference'
            axios.post(url, fd, config).then(result=>setAllconference(result.data))
        }

       

    

     const handleSubmit = event =>{
        event.preventDefault();

        let errors = {};   
		
    if(!conference.conferenceName){
        errors.conferenceName ='Please enter conference name';
    }
   
    if(!conference.conferenceDate){
        errors.conferenceDate ='Please enter conference date';
    }
    
    if(!conference.intervalMins){
        errors.intervalMins ='Please enter interval in miunutes';
    }

    if(!conference.endTime){
        errors.endTime ='Please enter end time';
    }

    if(!conference.startTime){
        errors.startTime ='Please enter start time';
    }

    if(Number(conference.interval)<1){
        errors.interval ='Please enter valid interval in miunutes';
    }

    setErrors(errors);


    if(Object.keys(errors).length === 0){
        submit()
    } 
    
}
   

function submit(){  

      let formData = conference; 
      const fd = new FormData();
              fd.append('jwt', Token);
      for(let k in formData){
          fd.append(k,formData[k]);
      }; 


      fd.append("code", 'c'+Math.random().toString(36).substr(2,9))

    setNotice({...notice, isLoading: true}) 

       let url = ServerUrl+'/save_controller/tbl_conference'
	  axios.post(url, fd, config)
	  .then(response =>{
        if(response.data.type ==='success'){
            Alerts('Saved!', 'success', response.data.message)
                } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
            setConference({ 
                conferenceName:'',
            conferenceDate:'',
            startTime:'',
            endTime:'',
            intervalMins:''}); 
              fetchconference()
              setNotice({...notice,  isLoading: false })
          }) 
}

useEffect(()=>{
    //setIsAuthenticated(verifyRouter(location.pathname))
    fetchconference();
},[]);


const BasicTable=(props)=>{

    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData(); 
        fd.append('newValue', newValue);
        fd.append('column', column.dataField);
        fd.append('ID', ID);
        fd.append("jwt", Token);
       let url = ServerUrl+'/updateCustom_controller/tbl_conference'
      axios.post(url, fd, config)
       .then()
       .catch((error)=>alert(error)) 
       
     } 
  
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } entries</span>
      );
      
const { SearchBar } = Search;
     const options = {
         showTotal: false,
     paginationTotalRenderer: customTotal,
     sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
     { text: 'All', value: props.data.length }]
        
       };
  
     return <React.Fragment>  
    
             <ToolkitProvider search  className="table-responsive"
                 keyField='code' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           
                             <SearchBar
                             { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />
                             
                             <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                   mode: 'dbclick',
                                   blurToSave: true,
                                   autoSelectText: true,
                         beforeSaveCell: (oldValue, newValue, row, column) => {
                           if(oldValue !==newValue){
                           handleUpdate(column, newValue, row.ID);
                           
                          return props.fetchdata;
                         }
                         
                       }
                                 }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  </React.Fragment>
  }

  
    
        return (  <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Setup Conference">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-home"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Staff</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Define Conference</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Create Conference</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<fieldset>
            
            <div className="row">
									
										<section className="col-md-6">


										<div className="form-group">
						<label >Conference Name<span style={{color:'red'}}>*</span></label>
						<input type="text" role={'presentation'} autoComplete='off' name="conferenceName" value={conference.conferenceName} onChange={handleChange} className={errors.conferenceName  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.conferenceName && errors.conferenceName}</span>	
			
												
											</div>
                                            </section>
                                            <section className="col-md-6">
                                            <div className="form-group">
						<label >Conference Date<span style={{color:'red'}}>*</span></label>
						<input type="date" name="conferenceDate" value={conference.conferenceDate} role={'presentation'} autoComplete='off'   onChange={handleChange} className={errors.conferenceDate  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.conferenceDate && errors.conferenceDate}</span>	
			
												
											</div>

</section>
                                            <section className="col-md-4">


										<div className="form-group">
						<label >Start Time<span style={{color:'red'}}>*</span></label>
						<input type="time" role={'presentation'} autoComplete='off' name="startTime" value={conference.startTime} onChange={handleChange} className={errors.startTime ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.startTime && errors.startTime}</span>	
			
												
											</div>
                                            </section>
                                            <section className="col-md-4">


										<div className="form-group">
						<label >End Time<span style={{color:'red'}}>*</span></label>
						<input type="time" role={'presentation'} autoComplete='off' name="endTime" value={conference.endTime} onChange={handleChange} className={errors.endTime ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.endTime && errors.endTime}</span>	
			
												
											</div>
                                            </section>
                                        

<section className="col-md-4">
                                            <div className="form-group">
						<label >Interval (In Mins)<span style={{color:'red'}}>*</span></label>
						<input type="number" name="intervalMins" value={conference.intervalMins} role={'presentation'} autoComplete='off'   onChange={handleChange} className={errors.intervalMins  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.intervalMins && errors.intervalMins}</span>	
			
												
											</div>

</section>

										</div>
										
										</fieldset>

	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-primary ">Save</button>
	
								</footer>				
							</div>
							</div>
							</div>
		
	
	

	<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Listing All Conference</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12 table-responsive">
        
        {notice.isDataFetching ? <TableLoader />:
   <BasicTable data={allconference}   columns={tableHeader}  />} 
</div>
	</div>
    </div>
</div>

</div>
	
   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Conference) 