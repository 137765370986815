import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config} from '../component/include'
import Select from 'react-select'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import {formatGroupLabel} from '../component/globalFunction'
const TimetableDay =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    const [schoolClass, setSchoolClass]=useState([]);
    const [subject, setSubject]= useState([]) 
    const [periodList, setPeriodList] = useState([]);
    const [staff, setStaff]= useState([])  
    const [allTimetable, setAllTimetable]=useState([])
    const [timetable, setTimetable]=useState({
    schoolClass:[],
     tableDate:''
    })
    const [activities, setActivities]=useState([])
    const [holidays, setHolidays]=useState([])
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const getTime =(value)=>{
        var timeString = String(value)
       var hourEnd = timeString.indexOf(":");
       var H = timeString.substr(0,2);
       var h = H % 12 || 12;
       var ampm =  (H<12 || H===24)?"AM":"PM";
        if (h < 10) h = "0" + h
       var result = h + timeString.substr(hourEnd, 3)+" "+ampm
     
    
return result;
    }
    const fetchHolidays =(startDate)=>{

        const fd = new FormData();
        let sql = "Select title from tbl_events_holidays where eventType='Holidays' and startDate ='"+startDate+"'"     
            let url = ServerUrl+'/fetchBySql_controller/'+Token;          
            fd.append("sql", sql)
            const config = {
                headers: { 'content-type': 'multipart/form-data'}
            }
            axios.post(url, fd, config).then(result =>setHolidays(result.data)) 
    }
   
    const getStaff=(ID)=>{
        const result = staff.filter((list)=>list.ID===ID);
       const answer = result.map((c)=>`${c.staffName.slice(0, 20)}` ); 
       
       return answer
    }
    const getSubject =(ID)=>{
        const result = subject&&subject.filter((list)=>list.ID===ID);
        const answer = result.map((c)=>c.subjectName);
        return  answer
        
    }
    const getActivityImage =(ID)=>{
        const result = activities&&activities.filter((list)=>list.ID===ID);
        const answer = result.map((c)=>c.imageUrl);
        return  answer
        
    }
    const getActivity =(ID)=>{
        const result = activities&&activities.filter((list)=>list.ID===ID);
        const answer = result.map((c)=>c.activity);
        return  answer
        
    }
    const fetchSchoolClass =()=>{
        let url = ServerUrl + '/fetchColumnById_controller/tbl_school_class/schoolClass/1/'+Token;
        axios.get(url).then(result=>setSchoolClass(result.data))

    }
    const fetchSubject =()=>{
        let Url = ServerUrl+'/fetch_controller/tbl_subject/'+Token;
        axios.get(Url).then(result=>setSubject(result.data))
    }
   
const configurePlan =()=>{
    var start = new Date(timetable.tableDate)
  var end =  new Date(start.setDate(start.getDate()+7))
var year = start.getFullYear();
var month = start.getMonth();
var date = start.getDate();

  var result=[start]

  while (result[result.length-1]< end){
      result.push(new Date(year, month, ++date))
    //  start = new Date(start)
  }
  
  
  //console.log(result)
 return JSON.stringify(result, null, 2)
}

    const fetchAllTimetable =(sectionID)=>{
        
        const fd = new FormData();
        fd.append('jwt', Token);
        let sql = "Select ID, periodList, tableDate from tbl_timetable where sectionID ='"+sectionID+"'"     
            let url = ServerUrl+'/fetchBySql_controller'         
            fd.append("sql", sql)
           
            axios.post(url, fd, config).then(result =>setAllTimetable(result.data)) 
    }


    const handleSelect = (option, action)=>{
        setTimetable({...timetable, [action.name]: option, tableDate:''});
        setErrors({...errors, [action.name]:''})            
       
    };

    const handleChange=(event)=>{
        let value = event.target.value;
        setTimetable({...timetable, [event.target.name]:value});
        setErrors({...errors, [event.target.name]:''})
       
    }

    const  fetchActivities =()=>{  
        
        const fd = new FormData();
   let sql = "Select ID, activity, imageUrl from tbl_activities order by activity ASC"

       let url = ServerUrl+'/fetchBySql_controller'
     
       fd.append("sql", sql)
       
       axios.post(url, fd, config).then(result =>setActivities(result.data)) 
   
    }  

    const  fetchTableList =(tableDate)=>{  
        
    setNotice({...notice, 
        isLoading: true,
        isSuccess : false})
        const fd = new FormData();
        fd.append('jwt', Token);

   let sql = "Select periodList from tbl_timetable where tableDate = '"+tableDate+"' and   sectionID ='"+timetable.schoolClass.value+"'"

       let url = ServerUrl+'/fetchBySql_controller'
       fd.append("sql", sql)
       
       axios.post(url, fd, config).then(result =>{

        if(result.data.length!==0){
        const res = result.data.map((tb)=>tb.periodList)
                setPeriodList(JSON.parse(res))
        }else{
            setPeriodList([])
        }
    setNotice({...notice, 
        isLoading: false,
        isSuccess : false})
        }) 

    }  

    const  fetchStaff =()=>{  
        
        const fd = new FormData();
        fd.append('jwt', Token);
   let sql = "Select s.ID, s.staffName, s.fileNo from tbl_staff s"

       let url = ServerUrl+'/fetchBySql_controller'
     
       fd.append("sql", sql)
       
       axios.post(url, fd, config).then(result =>setStaff(result.data)) 
   
    }    

useEffect(()=>{
    //setIsAuthenticated(verifyRouter(location.pathname))
    fetchSchoolClass()
    fetchSubject()
    fetchStaff()    
    fetchActivities()
},[]);



        return (    <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Timetable">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-table"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Timetable</a> </li>
                    <li className="breadcrumb-item"><a href="#!">View Subject Plan</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

         
<div  className="row  ">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Timetable</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximize full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            
            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
       
<section className="col-md-6">

<div className="form-group">
<label >Date</label>
<input type="date" className={errors.tableDate ? 'form-control form-control-danger' : 'form-control'} name="tableDate"  onChange={handleChange} value={timetable.tableDate} />
<span style={{color:'red'}}>{errors.tableDate && errors.tableDate}</span>
</div>

</section>
     
      <section className="col-md-6">
      <div className="form-group">
						<label >Select Class<span style={{color:'red'}}>*</span></label>
                        <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID,isSection:'1', value: sec.ID, label: sec.sname}:{classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
                             })
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect} className={errors.schoolClass ? 'form-control form-control-danger' : ''}  name="schoolClass" value={timetable.schoolClass}   /> 
								
<span style={{color:'red'}}>{errors.schoolClass && errors.schoolClass}</span>				
											</div>	
   
                            

</section>	</div>


<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button"  className="btn btn-inverse "><i className="fa fa-refresh"></i> Back</button>&nbsp;&nbsp;
	<button type="button" id="submit" onClick={configurePlan}  className="btn btn-primary "><i className="fa fa-search"></i> Proceed</button>
								</footer>		</section>		
							</div>
 </div> </div> 
							
</div>

</div>   



<div  className="card z-depth-0" >
		<div  className="card-block">
        <div className="row">
       
        <div className="table-responsive">
        <table bordered="true" className="table">
            <thead>
                <tr><td></td></tr>
                <tr>
                    <th>Day</th>
                    <th>Date</th>
                    <th>End Time</th>
                     
                    <th>Subject</th>
                    <th>Teacher</th> 
                    <th>Chapter</th>
                    <th>Topic</th> 
                    
                </tr>
            </thead>
            <tbody>
               
               
            </tbody> 
        </table>

        </div>
        </div>
        </div>
</div>

     
     </div>  </div>
							
                            </div>
                            
                            </div>

   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(TimetableDay) 