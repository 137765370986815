import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config} from '../component/include'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
const ApplyLeave =()=> {
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
      
    const [leaveList, setLeaveList]= useState([])
   
let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
  
    const  fetchLeaveList =()=>{  
        const fd = new FormData();
        fd.append('jwt', Token); 
   let sql = "Select s.ID, s.code, s.days, f.staffName, s.sessionYear, l.leaveName, s.fromDate,	s.toDate, s.status  from tbl_staff_leave s, tbl_staff_leave_settings l,  tbl_staff f where s.staff=f.code and s.leaveType = l.code "

       let url = ServerUrl+'/fetchBySql_controller'
     
       fd.append("sql", sql)
       
       axios.post(url, fd, config).then(result =>setLeaveList(result.data)) 
   
    }   

    const long=(conv)=>{
        var date = new Date(conv).toDateString().slice(3, 15)
        
        return date
    }
   

    
    const getDiffer=(toDate)=>{

        var today =  new Date();
        var secondDate =  new Date(toDate);
        var diff_time = secondDate.getTime()-today.getTime()
        var differ = diff_time/(1000*3600*24);
        return differ
    } 

    const handleAction = (cell, row)=>{
        return <div>     
           {Number(getDiffer(row.toDate))<=0?'':
    <button className="btn btn-sm btn-danger" onClick={()=>UpdateLeave(row.ID, 'Canceled')}  title="Cancel">Cancel</button>}
    &nbsp;&nbsp;

     {row.status==='Canceled'?<button onClick={()=>UpdateLeave(row.ID, 'Approved')} className="btn btn-sm btn-success" title="Approve"> Approve</button>:'' } 
        </div> 
    }
  
   
     const tableLeaveListHeader = [
        
        {dataField: 'ID', text: 'Action', formatter: handleAction, editable: false},
        {dataField: 'staffName', text: 'Name', editable: false},
        {dataField: 'leaveName', text: 'Leave Type', editable: false},
        {dataField: 'fromDate', text: 'From Date', editable: false, formatter:(cell)=>
        long(cell)},
        {dataField: 'toDate', text: 'To Date', editable: false, formatter:(cell)=>
        long(cell)},        
        {dataField: 'days', text: 'Days', editable: false},        
        {dataField: 'status', text: 'Status', formatter:(cell, row)=>cell==='Approved'?<span className="pcoded-badge label label-success">{cell}</span>:<span className="pcoded-badge label label-danger">{cell}</span>,  editor: {
            type: Type.SELECT, options:[
                { value: 'Pending', label: 'Pending' },
                { value: 'Approved', label: 'Approved' }
            ] }},        
        {dataField: 'sessionYear', text: 'Session', editable: false} 
     ]

    


     const TableBasic=(props)=>{
       
        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData();
        fd.append('jwt', Token); 
                fd.append('newValue', newValue);
                fd.append('column', column.dataField);
                fd.append('ID', ID);
             
               let url = ServerUrl+'/updateCustom_controller/'+props.table
              axios.post(url, fd, config)
              //.then(result => console.log(result.data))
              .then()
              .catch((error)=>Alerts('Error!', 'danger', error.message)) 
            }  
            
            const { SearchBar } = Search;
            
            const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
            );
            const options = {
            showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
            { text: 'All', value: props.data.length }]
            
            };
            return  <ToolkitProvider search columnToggle 
                        keyField='code' data={ props.data } columns={ props.columns } >
                            
                            {
                                props => (
                                
                                <div className="form-group">
                                    <SearchBar
                                    { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                        
                                        <div className="dt-buttons btn-group pull-right"> 
                <a href='/payroll/apply_leave' className="btn btn-outline-primary " >
        <i className="fa fa-plus"></i>Apply Leave </a>

</div>  

                        <BootstrapTable
                                    { ...props.baseProps }
                                                striped
                                            hover
                                            condensed
                                            noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                        pagination={paginationFactory(options) }
                                        cellEdit={ cellEditFactory({
                                        mode: 'dbclick',
                                        blurToSave: true,
                                        autoSelectText: true,
                                beforeSaveCell: (oldValue, newValue, row, column) => {
                                if(oldValue !==newValue){
                                handleUpdate(column, newValue, row.ID);
                                
                                return true;
                                }
                                
                            }
                                        }) }
                                    />
                                </div>
                                )
                            }
        </ToolkitProvider>
        }
    
    
    
  


    const UpdateLeave=(ID, status)=>{
        setNotice({...notice, 
            isLoading: true,
            isSuccess : false})   
    
            const fd = new FormData();
        fd.append('jwt', Token);           
            fd.append('status', status);
            fd.append('ID', ID); 
            
             let url = ServerUrl+'/update_controller/tbl_staff_leave'
            axios.post(url, fd, config)
            .then(response =>{
                if(response.data.type ==='success'){
                    Alerts('Saved!', 'success', response.data.message)
                        }else if(response.data.type ==='info'){
                            Alerts('Update', 'default', response.data.message)
                                } else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data))
                        }   
                })
                .catch((error)=>{
                  Alerts('Error!', 'danger', error.message)
                }).finally(()=>{
                    setNotice({...notice, 
                        isLoading: false})     
            })

            
    }
    
   

useEffect(()=>{
    
    setIsAuthenticated(verifyRouter(location.pathname))
   fetchLeaveList()

},[]);

        return (   <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Staff Leave Approval">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-users"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Payroll</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Approve Leave</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

         
    <div  className="row  ">   
<div className="col-sm-12">                                                                  
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Listen All Leaves</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12 table-responsive">

          {notice.isDataFetching ? <TableLoader />:
      <TableBasic data={leaveList}  table={'tbl_staff_leave'} columns={tableLeaveListHeader}  />} 

            </div></div>
    
    </div>
    
    </div>
    </div>

   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(ApplyLeave) 