import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import PageHeader from '../component/pageheader'
import { LoadEffect } from '../component/loader'
import { FormLabel } from '../component/formControl'
import {Alerts} from '../component/notify'
import {ServerUrl, Token, config} from '../component/include'
import axios from 'axios'
import Select from 'react-select'
import {useHistory} from 'react-router'

 const Roles =()=> {
	 let myRef = useRef()
	 let history = useHistory();
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	 

	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
	const [errors, setErrors] = useState({});
    const [permission, setPermission]=useState({
        role:[],
        priviledge:[],
        status:true
    })
    const [roles, setRoles]=useState({
      roles:[],
      menuLink:[]
    }) 
    const [menu, setMenu]=useState([]) 
    const [usergroup, setUsergroup]=useState([])  
    
    const handleSelect = (option, action)=>{
      fetchRoles(option.value)
        setPermission({...permission, [action.name]: option});
        setErrors({...errors, [action.name]:''})
         }

		const selectAll =()=>{          
		var newMenu = JSON.parse(JSON.stringify(menu));
		for (var i in newMenu){
		newMenu[i].status =!permission.status
		for(var k in newMenu[i].submenu){
			newMenu[i].submenu[k].status =  !permission.status 
			for(var m in newMenu[i].submenu[k].subsubmenu){
				newMenu[i].submenu[k].subsubmenu[m].status =  !permission.status 
		}}
		}

	setMenu(newMenu)
	setPermission({...permission, status:!permission.status})
		}
       
	const  handleSubSubChange = (status, subsub, subIndex, mainIndex) => {                
	const newmenu =JSON.parse(JSON.stringify(menu))
		newmenu[mainIndex].submenu[subIndex].subsubmenu[subsub].status = !status;      
		setMenu(newmenu); 
	}

	const  handleChangeSubmenu = (status, subIndex, mainIndex) => {                 
	const newmenu =JSON.parse(JSON.stringify(menu))
		newmenu[mainIndex].submenu[subIndex].status = !status;      
		setMenu(newmenu); 
	}

         const  handleChangeMenu=(status, i)=>{
          let newmenu = [...menu];
          newmenu[i] = {...newmenu[i], status: !status};
          setMenu(newmenu);
        }

    
        const fetchRoles =(group)=>{

          const rolesList = usergroup.filter(item=>item.code===group)
          if(rolesList[0].roles.length!==0){
          setMenu(JSON.parse(rolesList[0].roles))

          }
      
    }	


   

    const fetchGroup =()=>{
		var sql ="select code, groupName, roles from tbl_usertype" ;
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller'
		axios.post(url, fd, config).then(result=>{  
			
            setUsergroup(result.data)
        })
    }	  
    
   
     const handleSubmit = event =>{
          event.preventDefault();
    if(permission.role.length===0){
      var ermsg = "Please select priviledge group"
      setErrors({...errors, role:ermsg})
      Alerts('Error!', 'danger', ermsg) 
      window.scrollTo(document.body.scrollHeight, 0)
    }else{

setNotice({...notice, isLoading:true})
      const fd = new FormData();
	fd.append("code", permission.role.value)
	fd.append("roles", JSON.stringify(menu, null, 2))
	
  fd.append("jwt", Token);
		let url = ServerUrl+'/update_controller/tbl_user_role';
		axios.post(url, fd, config)
    .then(response =>{
        if (response.data.type ==='info'){    
        Alerts('Info!', 'default', response.data.message)
        }else{
          Alerts('Error!', 'danger', JSON.stringify(response.data))
        }              
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message) 
    }).finally(()=>{   
        setNotice({...notice, 
            isLoading: false
        })
        fetchGroup()
}) 
    }

    } 



function submit(){  

    setNotice({...notice,  isLoading: true}) 
    let roles = []
    let menuLink = []
    var newMenu = JSON.parse(JSON.stringify(menu));
    for (var i in newMenu){
            if(newMenu[i].status===true){
            roles.push(newMenu[i].ID)
            menuLink.push(newMenu[i].menuUrl)
            }

     for(var k in newMenu[i].submenu){
            if(newMenu[i].submenu[k].status===true){
            roles.push(newMenu[i].submenu[k].ID)
            menuLink.push(newMenu[i].submenu[k].subUrl)
            }

         for(var m in newMenu[i].submenu[k].subsubmenu){
            if(newMenu[i].submenu[k].subsubmenu[m].status===true){
            roles.push(newMenu[i].submenu[k].subsubmenu[m].ID)
            menuLink.push(newMenu[i].submenu[k].subsubmenu[m].subsubUrl) 
            }
		 }}
	}
     
	const fd = new FormData();
	fd.append("groupId", permission.role.value)
	fd.append("roles", JSON.stringify(roles, null, 2))
	fd.append("menuLink", JSON.stringify(menuLink, null, 2),)
  fd.append("jwt", Token);
		let url = ServerUrl+'/save_controller/tbl_staff_role';
		axios.post(url, fd, config)
    .then(response =>{
        if (response.data.type ==='success'){    
        Alerts('Success!', 'success', response.data.message)
        }else if (response.data.type ==='info'){
            Alerts('Info!', 'info', response.data.message)
        }else{
          Alerts('Error!', 'danger', JSON.stringify(response.data))
        }              
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message) 
    }).finally(()=>{   
        setNotice({...notice, 
            isLoading: false
        })
}) 
}

useEffect(()=>{
    fetchGroup()
 },[]);


        return (<>
          
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">
                <PageHeader title=' Staff Roles'>
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-dashboard"></i> </a>
                            </li>
                    </PageHeader>
{/* The Page Body start here */}
<div className="page-body">

	<div className="row  ">
	<div className="col-sm-12">
								
	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-cogs"></i> Add/edit User privileges information to already created group</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="row">
        <div className="col-lg-12">
		  <div id="contentHere" > 
		     
      <div className="row">
        <div className="col-lg-12">
              <div className="card-body">    
                <form  className="form-bordered">                
              

                <div className="form-group row">
                  <label htmlFor="role" className="col-sm-2 col-form-label">Select Group</label>
                  <div className="col-sm-10">
                  <Select  options={
                            usergroup&&usergroup.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.groupName }
                             })} 
onChange={handleSelect} name="role" value={permission.role} styles={selectStyles} /> 


<span style={{color:'red'}}>{errors.role && errors.role}</span>
                  </div>
                </div>
                {menu.length!==0? <div className="form-group row">
                  <label htmlFor="priviledge"  className="col-sm-6 col-form-label">Check/Uncheck all privileges</label>
                  <div className="col-sm-6">
                       
     <button type="button"  id="saveClass" onClick={selectAll} className="btn btn-primary "  >Check All Priviledges</button>
                  </div>
                </div>:''}
                </form>
                <hr style={{borderTop:'solid 2px'}}/>
                <br/>
              
                <div className="col-lg-12">
          <div id="accordion1">

{menu&&menu.map((main, k)=>main.order!=='0'?
              <div key={k} className="card mb-2">
                <div className="card-header">  
                      <div className="form-group row">
                          <div className="col-sm-1">
                                         
                <input type="checkbox" className="border-checkbox" name={main.ID} checked={main.status}  id={k} onChange={()=>handleChangeMenu(main.status, k)}  />
                <label htmlFor={k}></label>
 </div>
                          <div className="col-sm-3 text-left btn btn-link shadow-none collapsed" data-toggle="collapse" data-target={'#'+main.ID}><i className="fa fa-sitemap fa-2x"></i> {main.menuName}  </div>
                          <div className="col-sm-8 btn shadow-none text-left">{main.description}</div>
                      </div>
                    
                </div>

                <div id={main.ID} className="collapse" data-parent="#accordion1">
                  <div className="card-body">
                  {main.submenu && main.submenu.map((smenu, id)=><div key={id}>
                  <div className="form-group row">
                          <div className="col-sm-1">                                         
                <input type="checkbox" className="border-checkbox" name={smenu.ID} checked={smenu.status}  id={smenu.ID} onChange={()=>handleChangeSubmenu(smenu.status, id, k)} />
                <label htmlFor={smenu.ID}></label>
                    </div>
                          <div className="col-sm-5">{smenu.subName}  </div>
                          <div className="col-sm-6">{smenu.subDescription}</div>
                    </div>
                    {smenu.subsubmenu && smenu.subsubmenu.map((subsub, idx)=>
                          <div key={idx} className="row">
                          <div className="col-sm-1"> </div>

                          <div className="col-sm-1">                                         
                <input type="checkbox" className="border-checkbox" checked={subsub.status}  name={subsub.ID}  id={subsub.ID} onChange={()=>handleSubSubChange(subsub.status, idx, id, k)}  />
                <label htmlFor={subsub.ID}></label>
 </div>
                          <div className="col-sm-5">{subsub.subsubName}  </div>
                          <div className="col-sm-4">{subsub.subsubDescription}</div>
                          <div className="col-sm-1"> </div>
                      </div>)}</div>)}
                  </div>
                </div>
              </div>:'')}


            </div>
        </div>

           
      {menu.length!==0?
      <div className="form-footer pull-left">
                  <button type="button" onClick={()=>history.goBack()} className="btn btn-dark shadow-dark m-1"><i className="fa fa-arrow-left"></i> Back</button>
                  <button type="button" onClick={handleSubmit} className="btn btn-success shadow-success m-1"><i className="fa fa-check-square-o"></i> Saved Priviledges for the selected Group</button>
                </div>:''}

                </div>
          </div></div> 
          </div>
        </div>
      </div>
	</section>
	
	
	</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 

 );
        
}

export default React.memo(Roles) 