import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import PageHeader from '../component/pageheader'
import { Alerts } from '../component/notify'

import {ServerUrl, Token, config, Currency} from '../component/include'
import axios from 'axios'
import {  convertDate, FormatNumber, getClassName, getMonth, getMonthByDate} from '../component/globalFunction'
import {Bar, Line} from 'react-chartjs-2'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'

const Chart =()=> {
    
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
   
let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
    var date = new Date()
    
    var firstDayOfYear = new Date(date.getFullYear(), 0, 1, 23, 59, 59)
    var lastDayOfYear = new Date(date.getFullYear()+1, 0, 0, 23, 59, 59)

const [salaryMonth, setSalarayMonth] = useState([])
const [salaryYear, setSalarayYear] = useState([])
const [staffPayment, setStaffPayment] = useState([])
const staff  = useSelector(state => state.rootReducer.staff);
const [staffFee, setStaffFee]=useState({
  staff:0
});



            
const handleSelect = (option, action)=>{
  setStaffFee({...staffFee, [action.name]: option});
  fetchStaffPayment(option.value)
 }  

    const fetchStaffPayment =(value)=>{ 

    var sql = "select DATE_FORMAT(STR_TO_DATE(paymentMonth, '%Y-%m'), '%M') as label, sum(totalAmount) as data from tbl_salary_payment  where paymentDate >=  '"+convertDate(firstDayOfYear)+"' and paymentDate <= '"+convertDate(lastDayOfYear)+"' and staff ='"+value+"'   GROUP BY  DATE_FORMAT(STR_TO_DATE(paymentMonth, '%Y-%m'), '%m.%Y') "
    
              const fd = new FormData();
              fd.append("sql", sql);
              fd.append('jwt', Token)
              let url = ServerUrl+'/fetchBySql_controller';
              axios.post(url, fd, config).then(result=>{ 
                setStaffPayment(result.data)
              })
          }

          const data= {
            labels: staffPayment.map((data)=>data.label),
            datasets: [
              {
                label: 'Paid ',
                data:  staffPayment.map(data=>data.data),
                backgroundColor: 'rgb(10, 76, 181)',
                
                
              }
            ],
          }
          
          const options = {
            scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
          }; 
      

         const fetchSalaryYear =()=>{ 

          var sql = "select paymentYear as label, sum(totalAmount) as data from tbl_salary_payment   GROUP BY  paymentYear "
          
                    const fd = new FormData();
                    fd.append("sql", sql);
                    fd.append('jwt', Token)
                    let url = ServerUrl+'/fetchBySql_controller';
                    axios.post(url, fd, config).then(result=>{ 
                      setSalarayYear(result.data)
                    })
                } 

                const salaryList = {
                  labels: salaryYear.map((data)=>data.label),
                  datasets: [
                    {
                               
                      label: 'Total Paid',
                      backgroundColor: [
                        '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b'],
                      hoverBackgroundColor: [
                      '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507'],
                      data: salaryYear.map((data)=>data.data)
                    }
                  ]
                }

    const fetchSalaryMonth =()=>{ 

    var sql = "select DATE_FORMAT(STR_TO_DATE(paymentMonth, '%Y-%m'), '%M') as label, sum(totalAmount) as data from tbl_salary_payment  where paymentDate >=  '"+convertDate(firstDayOfYear)+"' and paymentDate <= '"+convertDate(lastDayOfYear)+"'   GROUP BY  DATE_FORMAT(STR_TO_DATE(paymentMonth, '%Y-%m'), '%m.%Y') "
    
              const fd = new FormData();
              fd.append("sql", sql);
              fd.append('jwt', Token)
              let url = ServerUrl+'/fetchBySql_controller';
              axios.post(url, fd, config).then(result=>{ 
                setSalarayMonth(result.data)
              })
          } 

          const salaryFees = {
            labels: salaryMonth.map((data)=>data.label),
            datasets: [
              {
                label: 'Amount '+Currency,
                fill: false,
                lineTension: 0.5,
                backgroundColor: 'rgba(75,192,192,1)',
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 2,
                data: salaryMonth.map((data)=>data.data)
              }
            ]
          }; 

     

   useEffect(()=>{
     
    setIsAuthenticated(verifyRouter(location.pathname))
    fetchSalaryMonth() 
    fetchSalaryYear()
},[]);

        return (   <>
          <div  className="main-body">
          {isAuthenticated?   <div className="page-wrapper">
                        {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title='Chart'>
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-dashboard"></i> </a>
                            </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


    <br/>


    <div className="row">
      <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
             <div className="card-body">
             <div className="col-sm-6 pull-left ">
             <strong>
     Staff Payment History </strong>
               </div>
             <div className="col-sm-4 pull-right ">
             <Select  options={
                            staff.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.fileNo, staffName:list.staffName}
                             })} 
                        getOptionLabel={option =>`${option.label} - ${option.staffName}`}
onChange={handleSelect}  name="staff" value={staffFee.staff}  /> 
							</div>
              <Bar
          data={data}
          options={options}
          
        /> 

             </div>
          </div>
        </div></div> 



        <div className="row">
      <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
		   <div className="card-header"><strong>
     Salary Payment ({getMonth(new Date().getFullYear())}) </strong>
				</div>
             <div className="card-body">

              <Line
          data={salaryFees}
          options={{
            legend:{
              display:false,
              position:'right'
            }
          }}
          
        /> 

             </div>
          </div>
        </div></div> 





        <div className="row">
        <div className="col-12">
          <div className="card">
		   <div className="card-header">
            <strong> Payment History</strong>
				</div>
             <div className="card-body">

             <Bar
          data={salaryList}
          options={{ legend:{
              display:false,
              position:'right'
            }
          }}
          />
             </div>
          </div>
        </div></div> 



   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}
export default React.memo(Chart) 