import React from 'react'
import {ServerUrl, ImagesUrl} from '../component/include'
import Preloader from '../component/preloader'
import { useHistory } from 'react-router-dom'

 const Error403 =()=> {
	let history = useHistory()
        return (<>
          <Preloader />

		  <section style={{background: `url(${ImagesUrl}/bg.jpg) no-repeat`}} 
		  className="login-block">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">




                <div className="text-center error-pages">
                            
                     <h1 className="error-title text-danger"> Error 403</h1>
                     <p className="error-message text-white text-uppercase"> We could not let you access this page.
                     YOU DON'T HAVE PERMISSION TO DO THAT</p>
                            
                            <div className="mt-4">
                              <a href="/dashboard" className="btn btn-danger btn-round shadow-danger m-1">Go To Dashboard </a>
                              <a href="#!" onClick={()=>history.goBack()} className="btn btn-outline-danger btn-round m-1">Previous Page </a>
                            </div>
    
                            <div className="mt-4">
                                <p className="text-light">Copyright © 2020  <span className="text-primary">Admotek Solutions </span>| All rights reserved.</p>
                            </div>
                               <hr className="w-50" />
                           
                        </div>




                </div>
            </div>
        </div>
    </section>



         
</> 

 );
        
}

export default React.memo(Error403) 