import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, FormatNumber, Currency,  config, DatabaseName} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel} from '../component/globalFunction'
import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import { useDispatch, useSelector } from 'react-redux'

const StudentAdmission =()=>{
	const structure = useRef(null)
	const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    
	const [settings, setSettings]=useState([])

	const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);	
const schoolClass  = useSelector(state => state.rootReducer.schoolClass);

    const [isAuthenticated, setIsAuthenticated] = useState(true);
const totalStudent  = useSelector(state => state.rootReducer.totalStudent);
		const [CurrentSession, setCurrentSession] = useState('')
	const Initials = 
        { 
			code:'st'+Math.random().toString(36).substr(2,9),
			currentSession:CurrentSession&&CurrentSession,
			classID:'',
			studentClass:[],
			gender:'',
			feecharge:'',
			studentName:'',
			remark:'',
			totalFee:0,
			regDate: '',
			motherName:'',
			transportFee:'',
			distanceSelect:'',
			 fatherName:'',
			 admissionNumber:'',
			  registerNumber:'',
			   emailAddress:'',
				mobile:'',
				religion:'',
				dateOfBirth:'',
				 feeCharge:'',
				 transportFeeSelect:'',
				 feeStructure:[],
				 total:1	 
		}
		
    const [errors, setErrors] = useState({});
     
const [isDiplaying, setIsDiplaying] = useState(false);
	const [admission, setAdmission] = useState(Initials);
	const [classFee, setClassFee] =  useState([]);
	const [totalFees, setTotalFees] =  useState(0);
	/* 
	const [distance, setDistance] =  useState([]);
	const [distanceFee, setDistanceFee] =  useState([]); */
		const [actualFee, setActualFee] = useState([]);
		const [feecharge, setFeecharge] = useState([]);

		
const fetchSettings =()=>{
    const fd = new FormData();
fd.append('jwt', Token);
    let url = ServerUrl + '/fetchField/tbl_result_slip/settings/ID/1';
    axios.post(url, fd, config).then(result=>{
        setSettings(result.data[0])
      
    })
}

const  handleSession =()=>{

    var sql ="Select  startYear from tbl_school_session where status ='Active'"
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append("jwt", Token);
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config)
    .then(result => {
        if(result.data.length!==0){
        setCurrentSession(result.data[0].startYear)
    }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', JSON.stringify(error.message)) 
    })
    }
		

		const fetchFeecharge =()=>{
			const fd = new FormData();
        fd.append('jwt', Token)
			let url = ServerUrl+'/fetch_controller/tbl_fees_charge';
			axios.post(url, fd, config).then(result=>setFeecharge(result.data))
		}

	const handleChange = event =>{
		const {name, value} = event.target
	   setAdmission({...admission, [name] : value });
	   setErrors({...errors, [name]:''})
	}
	
	const handleSelect = (option, action)=>{
		let number = String(Number(totalStudent)+1).padStart(4, '0')
	var pattern = schoolInfo&&schoolInfo[0].admissionNumberPattern || ''
	let newPattern = pattern&&pattern.replace('{schoolAbbreviation}', schoolInfo&&schoolInfo[0].schoolAbbreviation);
	
	var shortYear = new Date().toLocaleDateString('en', {year: '2-digit'})
	 let pat = newPattern&&newPattern.replace('{AdmissionYear}', shortYear);
	 let admissionNo = pat&&pat.replace('{AdmissionNumber}', number);

	 
	 setAdmission({...admission, currentSession:CurrentSession, registerNumber:number, admissionNumber:admissionNo, [action.name]: option});

		setErrors({...errors, [action.name]:''})
	}


	


	const generateAdmissionNumber =()=>{
		let number = String(Number(totalStudent)+1).padStart(4, '0')
	var pattern = schoolInfo&&schoolInfo[0].admissionNumberPattern || ''
	let newPattern = pattern&&pattern.replace('{schoolAbbreviation}', schoolInfo&&schoolInfo[0].schoolAbbreviation);
	
	var shortYear = new Date().toLocaleDateString('en', {year: '2-digit'})
	 let pat = newPattern&&newPattern.replace('{AdmissionYear}', shortYear);
	 let admissionNo = pat&&pat.replace('{AdmissionNumber}', number);

	 
	 setAdmission({...admission, currentSession:CurrentSession, registerNumber:number, admissionNumber:admissionNo});
	}


	const handleSubmit = event =>{
		event.preventDefault();
		var errormessage = [];

		if(!admission.admissionNumber){
			let msg ='Unable to generate admission number, pls try again';
			setErrors({...errors, studentClass:msg})
			errormessage.push(msg);
		} 
	
		if(admission.studentClass.length===0){
			let msg  ='Please select admission class';
			setErrors({...errors, studentClass:msg})
			errormessage.push(msg);
		}

		if(admission.gender.length===0){
			let msg  ='Please select gender';
			setErrors({...errors, gender:msg})
			errormessage.push(msg);
		}

		

		if (errormessage.length<=0) {
			submit()
		}

	} 
	

const submit =()=>{  
	let formData = admission; 

      const fd = new FormData(); 
    for(let k in formData){
        fd.append(k,formData[k]);
	}; 
	

	
	 
	 
	fd.append('admissionOfficer', settings.admissionOfficer);
	fd.append('sectionID', admission.studentClass.value);
	fd.append('selectedGender', admission.gender.value);
	fd.append('classID', admission.studentClass.classID);
	fd.append('feecharge', 'admission.feecharge.value');
	fd.append('classFee', JSON.stringify(classFee, null, 2));
	fd.append('total', totalFees)
	fd.append('jwt', Token)

 setNotice({...notice,  isLoading: true}) 
	   let url = ServerUrl+'/save_controller/tbl_students';
	  axios.post(url, fd, config)
	  .then(response =>{
		if (response.data.type ==='success'){
			Alerts('Saved!', 'success', response.data.message)
			setTimeout(() => {
				window.location.reload()
			}, 3000);
		} else{
			setErrors({...errors, errorMessage: JSON.stringify(response.data)})
	}  
	})
	.catch((error)=>{
		//setErrors({...errors, errorMessage: JSON.stringify(error.message)}) 
	})
	.finally(()=>{
	  setNotice({...notice, isLoading: false}) 
	  window.location.reload()
	handleReset(); 
	window.scrollTo(0,0)
	})  
}



const handleReset =()=>{
	setAdmission(Initials);
	setIsDiplaying(false); 
}


		useEffect(()=>{
			fetchFeecharge()
			handleSession()
			fetchSettings()
		},[]);
		
        return (  <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                
                   {/* <!-- Page-header start --> */}
        <PageHeader title="Student Admission">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-graduation-cap"></i> </a>
                            </li>
                        <li className="breadcrumb-item"><a href="/students/view_students">Students</a> </li>  
                    <li className="breadcrumb-item"><a href="#!">Student Admission</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
                <div className="row  ">


				{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}

								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Register New Student</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		
		<div className="card-block">
		<div className="card z-depth-0">
			<div className="card-block">

				<form method='post' autoComplete="off" onSubmit={handleSubmit}>
		<fieldset>
			
			<div className="row">
		
		<section className="col-md-6">
			
						{/* <div className="form-group">
						<label >Registration Session</label>
			<label className="form-control "  >{CurrentSession}</label>		
											</div> */}

									
											<div className="form-group">
			<label >Student Name<span style={{color:'red'}}>*</span></label>
			<div className={errors.studentName  ? 'input-group input-group-danger' : 'input-group input-group-primary'}>
				<span className="input-group-addon">
		<i className="fa fa-user"></i>
		</span>
        <input type='text' id="studentName" autoComplete="off" name="studentName" required  value={admission.studentName}  className={errors.studentName  ? 'form-control form-control-danger' : 'form-control'}  onChange={handleChange} placeholder="Student Name" />
		</div>
		</div>

				
		<div className="form-group">
			<label >Date of Admission<span style={{color:'red'}}>*</span></label>
			<div className={errors.regDate  ? 'input-group input-group-danger' : 'input-group input-group-primary'}>
				<span className="input-group-addon">
		<i className="fa fa-calendar"></i>
		</span>
        <input type='date' id="regDate" autoComplete="off" name="regDate" required value={admission.regDate}  className={errors.regDate  ? 'form-control form-control-danger' : 'form-control'}  onChange={handleChange} placeholder="Date of Admission" />
		</div>
		</div>

		

<div className="form-group">
			<label >Email Address</label>
			<div className={errors.emailAddress  ? 'input-group input-group-danger' : 'input-group input-group-primary'}>
				<span className="input-group-addon">
		<i className="fa fa-at"></i>
		</span>
        <input type='email' id="emailAddress" autoComplete="off" name="emailAddress"  value={admission.emailAddress}  className={errors.emailAddress  ? 'form-control form-control-danger' : 'form-control'}  onChange={handleChange} placeholder="Student Email Address" />
		</div>
		</div>

		<div className="form-group">
			<label >Date of Birth <span style={{color:'red'}}>*</span></label>
			<div className='input-group input-group-primary'>
				<span className="input-group-addon">
		<i className="fa fa-mobile"></i>
		</span>
        <input type='date' id='dateOfBirth' autoComplete="off" name='dateOfBirth' required value={admission.dateOfBirth} className="form-control" onChange={handleChange} placeholder='Date of Birth' />
		</div></div>
			

		<div className="form-group">
			<label >Student Mobile</label>
			<div className='input-group input-group-primary'>
				<span className="input-group-addon">
		<i className="fa fa-mobile"></i>
		</span>
        <input type='text' id='mobile' autoComplete="off" name='mobile' value={admission.mobile} className="form-control" onChange={(e)=>setAdmission({...admission, mobile:e.target.value.slice(0,11)})} placeholder='Student Mobile' />
		</div></div>

											
			</section> 
				
				<section className="col-md-6">
				<div className="form-group">
						<label >Register Number </label>
						<input type="text" name="registerNumber" autoComplete="off" placeholder='Register number or file number ' onChange={handleChange} value={admission.registerNumber} className="form-control" />
												
											</div>
				
			

					<div className="form-group">
						<label >Gender<span style={{color:'red'}}>*</span></label>
						<Select  options={[
                    { value: 'Male', label: 'Male' },
                    { value: 'Female', label: 'Female' },
                ]} 
onChange={handleSelect}  name="gender" value={admission.gender} className={errors.gender ? 'form-control form-control-danger' : ''} /> 
								<span style={{color:'red'}}>{errors.gender && errors.gender}</span>				
											</div>
							  
					
    {/* 
	<div className="form-group">
	
	<label >Applicable School Fees <span style={{color:'red'}}>*</span> </label>
	<Select  options={feecharge&&feecharge.map((list, idx)=> {
								   return {key:idx, value: list.code, label:list.feesCharge, session:list.sessionCode }
								 })
							} 
							getOptionLabel={option =>`${option.session}  ${option.label}`}       
	onChange={handleSelect} name="feecharge" value={admission.feecharge} className={errors.feecharge ? 'form-control form-control-danger' : ''}  /> 
	<span style={{color:'red'}}>{errors.feecharge && errors.feecharge}</span>
				</div> */}


					<div className="form-group">
				<label > Admission Class <span style={{color:'red'}}>*</span></label>
				<Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect} className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={admission.studentClass}  /> <span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>
										
									</div>
											
											<div className="form-group">
						<label >Father Name</label>
						<input type="text" onChange={handleChange} autoComplete="off" value={admission.fatherName} name="fatherName" className="form-control" />
												
											</div>
											
								<div className="form-group">
						<label >Mother Name</label>
						<input type="text" name="motherName" autoComplete="off" onChange={handleChange} value={admission.motherName} className="form-control" />
												
											</div>
											
										</section>
										</div>
										
										
	{/* 	<span style={{color:'red'}}>{errors.feeStructure &&errors.feeStructure}</span>
 */}

							{/* {isDiplaying ? 		
							<div className='row' >

					<section className="col-md-12"><br/>
<label><b>School Fees Details</b></label>
	 
<div className="table-responsive">
      <table className="table ">
        <tbody >

{classFee.length!==0 ? classFee.map((fee, idx)=> 
<tr key={idx}>
				<td>{fee.fees}<br/><span style={{fontSize: '10px',color: '#fa0000'}}>Actual Fee {Currency+FormatNumber(getActualFee(fee.ID))}</span></td>

				<td><input type="number" min="0" className="form-control" autoComplete="off" onChange={(e)=>handleChangeFee(idx, e)}  name="price[]" value={fee.price} /></td>
			</tr>
				):<tr><td colSpan="3">
					<div className="alert alert-danger background-danger">There are no fee structure define for the selected class, please contact the admin</div>
					</td></tr>
			
			}
				</tbody>
				{classFee.length!==0 ?	<tfoot>
					<tr>
						<td colSpan='' className='text-right'><b>Total Fees:</b></td>
						<td><b>{Currency+FormatNumber(totalFees)}</b></td>
					</tr>
				</tfoot>:[]}
		</table>
		</div>
					</section>
					<section className="col-md-12">
					{classFee.length!==0 ? <table className="table ">
				<tbody >
			
			<tr>
			<td colSpan="3"><label>Remark</label>
				<textarea value={admission.remark} rows='2' name='remark' autoComplete="off" className='form-control' onChange={handleChange}> {admission.remark}</textarea>
					</td>
			

			</tr>
			</tbody></table>:[]}
			</section></div>	:""} */}
						</fieldset>
						<div ref={structure} />
	<footer className="pull-right">
	<button type="submit"    className="btn btn-inverse ">Submit</button>
	
								</footer>	
								</form>	
								</div>	</div>		
							</div>
							</div>
							</div>
                 
                </div>


                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(StudentAdmission) 