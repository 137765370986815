import React, { useEffect, useState} from 'react'
import PageHeader from '../component/pageheader'

import {LoadEffect}  from '../component/loader'
import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import {Alerts} from '../component/notify'
import axios from 'axios'
import {ServerUrl,  Token,  config} from '../component/include'
const SoftwareDetails=()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
  
    const [license, setLicense] = useState([])
    const fetchLicense =()=>{
           
        const fd = new FormData();
        fd.append('jwt', Token)
        fd.append("url", "license.json");
    fd.append("actionType", "getData");
        let url = ServerUrl+'/fetch_jsonData';
        axios.post(url, fd, config).then(result=>{  
            setLicense(JSON.parse(result.data))
          
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
    }
           
   useEffect(()=>{
       
    //setIsAuthenticated(verifyRouter(location.pathname))
    fetchLicense() 
},[]);
 
        return (  <>
    
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Software Details">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-info"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="#!">Software Detailss</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


<div className="row">
<div className="col-sm-6">

<div className="card">
		<div className=" well well-sm">
			<div className="z-depth-0 alert-round alert alert-danger alert-round border-success background-success">
				<h4 className="alert-heading">Your software license expires on 25-07-2025 23:59:59 </h4>
								<br/>
				2180 Days Left For Your Software License......
				<br/>
				<br/>
				 
        <p className="text-align-left">
            <br/>
            </p><p id="Information" style={{display:'none'}}>To Extend Your License Please Contact support@kayus.com</p>
            {license&&license.map((ls,id)=>
        <form key={id} id="softwaredetails" >
    
        <div className="form-group">
        <label >License User </label>
        <input type="text" name="school" value={ls.school} disabled data-toggle="tooltip" id="school" className="form-control" autoComplete="off" title="License User" placeholder="License User" />
    </div>
    <div className="form-group">
        <label >License Email </label>
        <input type="text" name="license_email" value={ls.email}  disabled data-toggle="tooltip" id="license_email" className="form-control" autoComplete="off" title="License Email" placeholder="Name" />
    </div>

    <div className="form-group">
        <label >Product ID<span style={{color:'red'}}>*</span></label>
        <input type="text" name="license_phone" value={ls.telephone}  disabled data-toggle="tooltip" id="license_phone" className="form-control" autoComplete="off" title="Product ID" placeholder="Product Code" />
    </div>
        <div className="form-group">
        <label >License Key <span style={{color:'red'}}>*</span></label>
        <div className="input-group">

<input type="text" name="license_key"  className="form-control card_no" id="license_key" required data-toggle="tooltip"  autoComplete="off" value={ls.key} title="License Key"  />
<div className="input-group-addon" style={{paddingLeft: '10px', paddingRight: '10px'}}>
<a style={{cursor:'pointer'}} href="#!"  id="updatekey"><i className="fa fa-key"></i>Update Key</a></div>
</div>
					  <div id="response" style={{color:'red'}}></div>
                    </div>
					</form>)}
								</div>
					<div className="z-depth-0 alert alert-danger border-danger background-danger">
				<h4 className="alert-heading">Total Message Balance In Your Account Is 0  </h4>
					You Are Not Able To Send Message.
					<p className="text-align-left">
						<br/>
						</p><p id="MInformation" style={{dislay:'none'}}>To Message Balance Recharge Please Contact support@kayus.com.ng</p>
						<a href="#!"  className="btn btn-sm hor-grd btn-grd-* btn-inverse"><strong>Request to Message Balance Recharge </strong></a>
					<p></p>
					
				</div>
					</div></div></div>

    <div className="col-sm-6">
	<div className="z-depth-0">
		<div className=" well well-sm"> 
        <div className="z-depth-0 alert  alert-primary">
			<h3 style={{textAlign: 'center'}}><i className="fa fa-th"></i> Modules List</h3>
			</div>
			<div className="z-depth-0 alert alert-success border-success background-success">
			<h4 className="alert-heading"><i className="fa fa-graduation-cap"></i> Admission Process</h4>Activated
						</div>
        <hr className="simple"/>	<div className="z-depth-0 alert alert-success border-success background-success">
            <h4 className="alert-heading"> <i className="fa fa-calendar"></i> Attendance</h4>
            Activated
        </div>
        <hr className="simple"/><div className="z-depth-0 alert alert-success border-success background-success">
            <h4 className="alert-heading"> <i className="fa fa-cogs"></i> Settings </h4>
            Activated
        </div>
      
        <hr className="simple"/> <div className="z-depth-0 alert alert-success border-success background-success">
            <h4 className="alert-heading"> <i className="fa fa-user"></i> Class and Section</h4>
            Activated
        </div>
        <hr className="simple"/> <div className="z-depth-0 alert alert-success border-success background-success">
            <h4 className="alert-heading"> <i className="fa fa-commenting-o"></i> Communication </h4>
            Activated </div>
        <hr className="simple"/><div className="z-depth-0 alert alert-success border-success background-success">
            <h4 className="alert-heading"> <i className="fa fa-file"></i> Events</h4>
            Activated
        </div>
        <hr className="simple"/><div className="z-depth-0 alert alert-success border-success background-success">
            <h4 className="alert-heading"> <i className="fa fa-table"></i> Exam</h4>
            Activated
        </div>
        <hr className="simple"/><div className="z-depth-0 alert alert-success border-success background-success">
            <h4 className="alert-heading"> <i className="fa fa-money"></i> Fees</h4>
            Activated
        </div>
        <hr className="simple"/><div className="z-depth-0 alert alert-success border-success background-success">
            <h4 className="alert-heading"> <i className="fa fa-money"></i> Finance</h4>
            Activated
        </div>
        
        <hr className="simple"/><div className="z-depth-0 alert alert-success border-success background-success">
            <h4 className="alert-heading"><i className="fa fa-building"></i> Hostel</h4>
            Activated
        </div>

        <hr className="simple"/><div className="z-depth-0 alert alert-success border-success background-success">
            <h4 className="alert-heading"> <i className="fa fa-globe"></i> CMS</h4>
           Activated
        </div>

        <hr className="simple"/><div className="z-depth-0 alert alert-success border-success background-success">
            <h4 className="alert-heading"> <i className="fa fa-home"></i> Assets Management</h4>
            Activated
        </div>

        <hr className="simple"/><div className="z-depth-0 alert alert-success border-success background-success">
            <h4 className="alert-heading"> <i className="fa fa-money"></i> Online Payment</h4>
            Activated
        </div>

       
        <hr className="simple"/><div className="z-depth-0 alert alert-success border-success background-success">
            <h4 className="alert-heading"><i className="fa fa-bus"></i> Transport</h4>
           Activated
        </div>
        <hr className="simple"/><div className="z-depth-0 alert alert-success border-success background-success">
            <h4 className="alert-heading"> <i className="fa fa-book"></i> Library</h4>
            Activated
        </div>
        <hr className="simple"/><div className="z-depth-0 alert alert-success border-success background-success">
            <h4 className="alert-heading"> <i className="fa fa-users"></i> Manage Staff</h4>
            Activated
        </div>
        <hr className="simple"/><div className="z-depth-0 alert alert-success border-success background-success">
            <h4 className="alert-heading"> <i className="fa fa-calendar"></i> Session </h4>
            Activated
        </div>
        
        <hr className="simple"/><div className="z-depth-0 alert alert-success border-success background-success">
            <h4 className="alert-heading"> <i className="fa fa-users"></i> Payroll</h4>
            Activated
        </div>
        <hr className="simple"/><div className="z-depth-0 alert alert-success border-success background-success">
            <h4 className="alert-heading"> <i className="fa fa-graduation-cap"></i> Student</h4>
            Activated
        </div>
        <hr className="simple"/><div className="z-depth-0 alert alert-success border-success background-success">
            <h4 className="alert-heading"> <i className="fa fa-file"></i> Subject</h4>
            Activated
        </div>
       
        <hr className="simple"/><div className="z-depth-0  alert alert-success border-success background-success">
            <h4 className="alert-heading"><i className="fa fa-table"></i> Time Table</h4>
            Activated
        </div>
        

        <hr className="simple"/><div className="z-depth-0  alert alert-success border-success background-success">
            <h4 className="alert-heading"><i className="fa fa-globe"></i> Student Portal</h4>
            Activated
        </div>
        <hr className="simple"/><div className="z-depth-0  alert alert-success border-success background-success">
            <h4 className="alert-heading"><i className="fa fa-globe"></i> Teacher Portal</h4>
            Activated
        </div>
        <hr className="simple"/><div className="z-depth-0  alert alert-success border-success background-success">
            <h4 className="alert-heading"><i className="fa fa-globe"></i> Application Portal</h4>
            Activated
        </div>

        <hr className="simple"/><div className="z-depth-0  alert alert-success border-success background-success">
            <h4 className="alert-heading"><i className="fa fa-globe"></i> Administrator Portal</h4>
            Activated
        </div>

        <hr className="simple"/><div className="z-depth-0  alert alert-danger border-danger background-danger">
            <h4 className="alert-heading"><i className="fa fa-table"></i> School Website</h4>
            Not Applicable
        </div>


        <hr className="simple"/><div className="z-depth-0 alert alert-success border-success background-success">
            <h4 className="alert-heading"><i className="fa fa-users"></i> User Management</h4>
            Activated
        </div><hr className="simple" />
            </div>
	</div>
	</div>
            </div> 
                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}
export default React.memo(SoftwareDetails) 