import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import PageHeader from '../component/pageheader'
import { LoadEffect } from '../component/loader'
import {Alerts, WarningModal} from '../component/notify'
import {ServerUrl, Token, config} from '../component/include'
import axios from 'axios'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import { useParams } from 'react-router-dom'
 const EmailApi =()=> {
	 let myRef = useRef()
	 const [defaultUrl, setDefaultUrl] = useState('');
	
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
		isDataFetching: false,
		token:false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [content, setContent] = useState([])
	
let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);

    const fetchContent =()=>{
        setNotice({...notice, 
            isLoading: true
        });
       
        var sql ="Select * from tbl_email_api";
         
        const fd = new FormData();
      fd.append('jwt', Token);
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller'
        
        axios.post(url, fd, config).then(result=>{ 
            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            });
        }) 

  }
  const handleChangeTest = event =>{		
	const {name, value} = event.target;		
	setTestSms({...testSms, [name]:value})
	setErrors({...errors, [name]:''})
}
const [testSms, setTestSms] = useState({
	email:'',
	message:''

})
	
	const handleChange =(event)=>{
	const newContent =JSON.parse(JSON.stringify(content))  
		let {name, value} = event.target;	
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}
 

    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		   
		  let formData = content[0];
		  const fd = new FormData();
      fd.append('jwt', Token);
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		 
			 let url = ServerUrl+'/update_controller/tbl_email_api'
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				fetchContent()
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	


	const sendTest=()=>{

		if(testSms.email && testSms.message){
		document.getElementById('closeModal').click()
	
		setNotice({...notice,  isLoading: true})     
			  
			let formData = testSms;
			const fd = new FormData();
		fd.append('jwt', Token);
			for(let k in formData){
			  fd.append(k, formData[k])
			}
		   
			   let url = ServerUrl+'/save_controller/tbl_sendTestMail'
			  axios.post(url, fd, config)
			  .then(response =>{
				setErrors({...errors, infoMessage: JSON.stringify(response.data)})   
			  })
			  .catch((error)=>{
				setErrors({...errors, errorMessage: error.message})
			  }).finally(()=>{
				  setNotice({...notice,   isLoading: false })
			  }) 
			}else{
				alert('Please enter email and message')
			}
	}

	
useEffect(()=>{
	
	//setIsAuthenticated(verifyRouter(location.pathname))
	fetchContent()
},[]);	
	
        return (
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
       <PageHeader title="Email API Settings"
					   subsubmenu="Email API "
					   />


{errors.infoMessage?
<div className="alert alert-info background-info">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.infoMessage}</strong> 
    </div>:''}

{errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}


	

<div id="testSmsModalBox" className="modal fade in" role="dialog" aria-hidden="false" >
        <div className="modal-dialog">
            <div className="modal-content">
            <div className="modal-header">
                  <h4 className="modal-title">Send Test Mail</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
                <div className="modal-body">
                    <div className="row form-group">
                        <label className="col-md-3 control-label text-left">Receivers mail</label>
                        <div className="col-md-9">
                            <input type="email" name="email" value={testSms.email} autoComplete="false" onChange={handleChangeTest} className={errors.email ? 'form-control formerror' : 'form-control'} placeholder="Receivers mail" />
                        </div>
                    </div>
                    <div className="row form-group">
                        <label className="col-md-3 control-label text-left">Message</label>
                        <div className="col-md-9">
                            <textarea type="text" rows="6" value={testSms.message} onChange={handleChangeTest} className={errors.message ? 'form-control formerror' : 'form-control'} name="message"  placeholder="Message">{testSms.message} </textarea>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" onClick={sendTest} className="btn btn-success" id="btnSendSms">Send</button>
                    <button type="button" id="closeModal" className="btn btn-default" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>


{/* The Page Body start here */}
{content&&content.map((data, id)=>
<div key={id} className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
				  
	<button type="button" className="btn btn-inverse btn-sm" onClick={submit} ><i className="fa fa-save"></i> Update</button>&nbsp;

<button type="button" data-toggle="modal"  data-target='#testSmsModalBox' id="openModal" className="btn btn-success btn-sm"><i className="fa fa-copy"></i> Test Email</button>



			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			
			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i> Email API info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                    
	<div className="form-group row">
					<div className="col-sm-3">
					<label className="bold pull-right">API Token</label>
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="apitoken" value={data.apitoken} className='form-control ' />
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.apitoken}</span>
							</div>
	</div>
				
	<div className="form-group row">
					<div className="col-sm-3">
					<label className="bold pull-right">Default Email</label>
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="senderEmail" value={data.senderEmail} className={errors.senderEmail  ? 'form-control formerror' : 'form-control '}/>
							</div>
							</div>
	</div>



					<div className="form-group row">
					<div className="col-sm-3">
					<label className="bold pull-right">Global Header</label>
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<textarea rows="20" type="text" onChange={handleChange}   name="globalHeader" value={data.globalHeader} className='form-control'>{data.globalHeader}</textarea>
							
							</div>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
					<label className="bold pull-right">Global Footer</label>
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<textarea rows="20" type="text" onChange={handleChange}   name="globalFooter" value={data.globalFooter} className='form-control'>{data.globalFooter}</textarea>
							
							</div>
							</div>
					</div>

	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>

				</div>)}
				
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 

 );
        
}

export default React.memo(EmailApi) 