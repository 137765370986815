import React, {useState, useEffect} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, staffCode, Token, config } from '../component/include'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'

 const UploadIncome=()=> {
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });

const [csvFile, setCsvFile]=useState({
    incomeCsv:'',
    staffCode:staffCode,
    fileName:'Please select .csv files (allowed file size 200KB)'
})

let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
const [errors, setErrors] = useState({});

const  handleSubmit=(event)=>{ 
    event.preventDefault(); 
    if(!csvFile.incomeCsv){
        errors.incomeCsv ='You have not choose any file to upload';
        Alerts('Error!', 'danger', errors.incomeCsv)
    }else{
    
    let formData = csvFile;
    
    const fd = new FormData();
        fd.append('jwt', Token); 
     for(let k in formData){
        fd.append(k,formData[k]);
    };  
     setNotice({...notice,  isLoading: true}) 

       let url = ServerUrl+'/save_controller/importincome'

      axios.post(url, fd, config).then(response =>{
        if(response.data.type ==='success'){
            Alerts('Saved!', 'success', response.data.message)
                } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
        .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
        }).finally(()=>{ 
            setNotice({...notice,  isLoading: false}) 
     setCsvFile({...csvFile, incomeCsv:'', fileName:'Please select .csv files (allowed file size 200KB)'}); 
     document.getElementById('incomeCsv').value=''
        })
      
} 

}


    const  handleFile = function(fieldName){
        return function(newValue){  
           
            setErrors({...errors, [fieldName]:''})
       readURL(newValue);
       }
       
    } 
   

    const readURL = (input)=>{
       
        let displayMessage = '';
        const PreviewImage = input.target.name;
            var fileName = document.getElementById(PreviewImage).value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        

        if (extFile==="csv" || extFile==="CSV"){
            //TO DO    
            
             var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
        if (FileSize > 0.5) {
            displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 200 Kb';
           document.getElementById(input.target.name).value = '';
        } else {
            if (input.target.files && input.target.files[0]) { //Check if input has files.
                   const value = input.target.files[0]
            setCsvFile({...csvFile, incomeCsv:value, fileName:value.name})
           
            }
        }
    }else{

        displayMessage = 'Only csv|CSV  files are allowed with maximum of 500kb'
        document.getElementById(input.target.name).value = '';
        setCsvFile({...csvFile, incomeCsv:'', fileName:'Please select .csv files (allowed file size 500KB)'})
    }  
    
    if(displayMessage.length > 0){
        Alerts('Error!', 'danger', displayMessage)
      }
    
} 



useEffect(()=>{
    setIsAuthenticated(verifyRouter(location.pathname))
}, [])
        return (  
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 

                   {/* <!-- Page-header start --> */}

        <PageHeader title="Finance">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="#!">Finance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Upload Income</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
                <div className="row  ">
	<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>Prepare your income file</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
                    <div className="well">
                       
                        <div className="box-body">
                        <div className="row">
                            <div className="col-sm-3 text-right  text-danger"><u><b>Required Columns</b></u></div>
                            <div className="col-sm-7"><u><b>Allowed Values</b></u></div>                           
                        <div className="col-sm-3 text-right "><b>Income Type</b></div>
                    <div className="col-sm-7">The ID of Income Type of which income is Made <br/>                      <br/>    </div>          								
                            <div className="col-sm-3 text-right "><b>Account </b></div>
                        <div className="col-sm-7">The ID of Account Name       <br/>  </div>                           
                            <div className="col-sm-3 text-right "><b>Amount</b></div>
                            <div className="col-sm-7">The income Amount<br/>                    <br/></div>                           
                          <div className="col-sm-3 text-right "><b>Date of income</b></div>
                            <div className="col-sm-7">The date in which income is made<br/>                      <br/>      
                                </div>                       
                            
								
								
								
                            <div className="col-sm-3 text-right  text-warning"><u><b>Optional Columns</b></u></div>
                            <div className="col-sm-7 "><u><b>Allowed Values</b></u></div>                           
                            <div className="col-sm-3 text-right "><b>Remarks</b></div>
            <div className="col-sm-7">Additional Messages for the income<br/>                      <br/>      
                                </div>
                        </div>
                    </div>
                </div>
	</div>
    </div>

</div>
</div>


                <div className="row  ">
								<div className="col-sm-6">
	<div className="card z-depth-0">
		<div className="card-block">
		<fieldset>
			<div className="row">
									
			<section className="col-md-12 ">
			<div className="well well-sm alert alert-success border-success background-success ">
					<h4 className="alert-heading ">Download Sample File</h4>
					
<p><span >The first line in downloaded csv file should remain as it is. Please do not change the order of columns.</span><br/>
<span >The correct column order is (<b>
    Income Type,  Amount, Account, Date Of income, Remarks </b>)</span> <span >&amp; you must follow this.</span></p>
					<hr className="simple" /><p className="text-align-left">
						
                    <form method='POST' action={ServerUrl+'/csvSample_controller/income'} target='_blank'>
                <input type='hidden' name='jwt' value ={Token} />
                
        <button type="submit" className="btn btn-sm btn-inverse"> <i className="fa fa-download"></i> Download Sample</button>
        
        </form>
					</p>
				</div>					
				</section>
										</div>
										
				</fieldset>
				
							</div>
							</div>
							</div>
		
	
	

	<div className="col-sm-6">
	<div className="card z-depth-0">
	
		<div className="card-block">


		<div className="alert alert-success border-success">
						<fieldset className="well well-sm">
                        <h4 className="alert-heading ">Upload file with .csv extention</h4>
							<section>
                            <div className="form-group">
							
                    <input id="incomeCsv"  onChange={handleFile('incomeCsv')} className="form-control" name="incomeCsv" type="file" accept=".csv" /> 
						<div className="note" style={{color:'red'}}>
			{csvFile.fileName}
		</div>	
										</div>          
                                        
							</section>
						</fieldset><span style={{color:'red'}}>{errors.incomeCsv && errors.incomeCsv}</span></div>
						<footer>
							<button type="button" id="submit" onClick={handleSubmit} className="btn  btn-primary pull-right"><i className="fa fa-save"></i> Upload income</button>
						</footer>

	</div>

</div>

</div></div>
	
		

                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(UploadIncome) 