import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import PageHeader from '../component/pageheader'
import {LoadEffect, TableLoader } from '../component/loader'
import {Alerts} from '../component/notify'
import {ServerUrl, FormatNumber, Currency, Token, config} from '../component/include'
import axios from 'axios'
import { convertDate} from '../component/globalFunction'
import {Bar, Line} from 'react-chartjs-2'
import { useDispatch, useSelector } from 'react-redux'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
 const HostelChart =()=> {
	
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
    });
    const [feesPayment, setFeesPayment] = useState([])
    var today = new Date().toISOString().slice(0,10);

    var date = new Date()
    var first = new Date(date.getFullYear(), date.getMonth(), 1, 23, 59, 59)
    var last = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59)

    const [students, setStudents] = useState([])

    const [statistics, setStatistics] = useState({
        student:0,
        rooms:0,
        visitor:0,
        hostel:0
    });

    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    const [CurrentSession, setCurrentSession] = useState('')

    const feeChart = {
        labels: feesPayment.map((data)=>data.label),
        datasets: [
          {
            label: 'Amount '+Currency,
            fill: false,
            lineTension: 0.5,
            backgroundColor: 'rgba(75,192,192,1)',
            borderColor: 'rgba(0,0,0,1)',
            borderWidth: 2,
            data: feesPayment.map((data)=>data.data)
          }
        ]
      }
      



      const fetchFees =()=>{
      
        var sql = "Select dateCreate as label, sum(amountPaid) as data from tbl_hostel_allocation where dateCreate >=  '"+convertDate(first)+"' and dateCreate  <= '"+convertDate(last)+"' GROUP BY dateCreate order by label  "

         const fd = new FormData();
         fd.append("sql", sql);
         fd.append('jwt', Token)
         let url = ServerUrl+'/fetchBySql_controller';
         axios.post(url, fd, config).then(result=>{ 
            
             setFeesPayment(result.data)
         })
     }



    /*  const  handleSession =()=>{

        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config)
        .then(result => {
            if(result.data.length!==0){
            setCurrentSession(result.data[0].startYear)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        } */

     const fetchStudent = async ()=>{
      
        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        const {data} = await axios.post(url, fd, config)
        setCurrentSession(data[0].startYear)

        let sqls = "Select h.hostelName as label, count(*) as data from tbl_hostel_allocation a, tbl_hostel h where h.code = a.hostelCode and a.isApproved='true' and a.currentSession = '"+data[0].startYear+"' GROUP BY h.hostelName order by label  "

         const fds = new FormData();
         fds.append("sql", sqls);
         fds.append('jwt', Token)
         let urls = ServerUrl+'/fetchBySql_controller';
         axios.post(urls, fds, config).then(result=>{ 
            
             setStudents(result.data)
         })
     }



     const fetchStatistics =()=>{
        var sql ="Select (select count(*) from tbl_hostel_allocation where isApproved='true' and  currentSession = '"+CurrentSession+"' ) as student, (select count(*) from tbl_hostel_visitors where visitDate = '"+today+"') as visitor,  (select count(*) from tbl_hostel_rooms ) as rooms, (select count(*) from tbl_hostel ) as hostel, (select sum(noBedSpace) from tbl_hostel_rooms ) as bedSpace "
          
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
        
        axios.post(url, fd, config).then(result=>{ 
            
            setStatistics({
                student:result.data[0].student,
                visitor:result.data[0].visitor,
                rooms:result.data[0].rooms,
                hostel :result.data[0].hostel,
                bedSpace:result.data[0].bedSpace
            })
        })
    
    }
        
       
        
        const studentList = {
            labels: students.map((data)=>data.label),
            datasets: [
              {
                         
                label: 'Total Student',
                backgroundColor: [
                  '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b'],
                hoverBackgroundColor: [
                '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507'],
                data: students.map((data)=>data.data)
              }
            ]
          }


          

            const getFullMonth =(date)=>{
                const ndate  = new Date(date)
                const month = ndate.toLocaleString('default', {month:'long'})
                return month
              }

           
   useEffect(()=>{
    setIsAuthenticated(verifyRouter(location.pathname))
    fetchStudent()
    fetchStatistics()
    fetchFees()
},[]);
 

        return ( <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
       <PageHeader title="Hostel Chart"
					   />


{/* The Page Body start here */} 
<div className="page-body">
         



         
<div className="row">
        <div className="col-lg-12">
		  <div id="contentHere" >

      <div className="row">




<div className="col-xl-3 col-md-6">
    <div className="card bg-c-yellow text-white">
        <div className="card-block">
            <div className="row align-items-center">
                <div className="col">
                    <p className="m-b-5">Hostel</p>
                    <h4 className="m-b-0">{statistics.hostel}</h4>
                </div>
                <div className="col col-auto text-right">
                    <i className="fa fa-building f-50 text-c-yellow"></i>
                </div>
            </div>
        </div>
    </div>
</div>


<div className="col-xl-3 col-md-6">
        <div className="card bg-c-green text-white">
            <div className="card-block">
                <div className="row align-items-center">
                    <div className="col">
                        <p className="m-b-5">Total Rooms</p>
                        <h4 className="m-b-0">{statistics.rooms}</h4>
                    </div>
                    <div className="col col-auto text-right">
                        <i className=" fa fa-bed f-50 text-c-green"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>


<div className="col-xl-3 col-md-6">
            <div className="card bg-c-pink text-white">
                <div className="card-block">
                    <div className="row align-items-center">
                        <div className="col">
                            <p className="m-b-5">Bed Space</p>
                            <h4 className="m-b-0">{statistics.bedSpace}</h4>
                        </div>
                        <div className="col col-auto text-right">
                            <i className="feather icon-user f-50 text-c-pink"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>


<div className="col-xl-3 col-md-6">
                <div className="card bg-c-blue text-white">
                    <div className="card-block">
                        <div className="row align-items-center">
                            <div className="col">
                                <p className="m-b-5">Today Visitor</p>
                                <h4 className="m-b-0">{statistics.visitor}</h4>
                            </div>
                            <div className="col col-auto text-right">
                                <i className="fa fa-users f-50 text-c-blue"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




      </div>




      <div className="row">
        <div className="col-12">
          <div className="card">
		   <div className="card-header">
            <strong> CURRENT HOSTEL STUDENTS ( {CurrentSession})</strong>
				</div>
             <div className="card-body">

              <Bar
          data={studentList}
          options={{ legend:{
              display:false,
              position:'right'
            }
          }}
        />  
             </div>
          </div>
        </div></div>




       
  <div className="row">
      <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
		   <div className="card-header"><strong>
      Hostel Fees Payment ( {getFullMonth(new Date())})</strong>
				</div>
             <div className="card-body">

              <Line
          data={feeChart}
          options={{
            legend:{
              display:false,
              position:'right'
            }
          }}
        /> 

             </div>
          </div>
        </div></div>

          </div>
        </div>
      </div>

                </div> 
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 

 );
        
}

export default React.memo(HostelChart) 