import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import PageHeader from '../component/pageheader'
import { Alerts } from '../component/notify'

import {ServerUrl, Token, config} from '../component/include'
import axios from 'axios'
import {  getClassName} from '../component/globalFunction'
import {Bar, Line} from 'react-chartjs-2'
import { useDispatch, useSelector } from 'react-redux'

import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import Error403 from '../settings/error403'
const Chart =()=> {
    
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
const [CurrentSession, setCurrentSession] = useState('')   
    const [allSubject, setAllSubject]= useState([])
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
var cName = "Exam Chart -"+CurrentSession

let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
const [statistics, setStatistics] = useState({
    session:0,
    exam:0,
    class:0,
    subject:0
})


const getAllSection =()=>{
  let section = []
  var newClass = [...schoolClass];

   for (var i in newClass){
          if(newClass[i].section[0].sname === ''){   
          section.push({classID:newClass[i].ID, sectionID:newClass[i].ID})
          } else{ 
              for(var k in newClass[i].section){
                  section.push({classID:newClass[i].ID, sectionID:newClass[i].section[k].ID})
                  }
      } 
  } 

  return section
}


const fetchStatistics =  ()=>{
      
  let sqls = "select (Select  count(*) from tbl_school_session ) as session, (select count(*) from tbl_exam_type ) as exam, (select count(*) from tbl_subject ) as subject "
  
   const fds = new FormData();
   fds.append("sql", sqls);
   fds.append('jwt', Token)
   let urls = ServerUrl+'/fetchBySql_controller';
   axios.post(urls, fds, config).then(result=>{ 
      
      setStatistics({
        session:result.data[0].session,
        exam:result.data[0].exam,
        subject:result.data[0].subject
      })
   })
} 

var tClass = getAllSection()


const fetchClassSubject = async ()=>{
        var section = tClass;
          let final = []
  const fd = new FormData();
  var sql = "select classID, sectionID, subjectDetails	from tbl_class_subject"
      let url = ServerUrl+'/fetchBySql_controller'
      fd.append("jwt", Token)
      fd.append("sql", sql)
      const {data} = await axios.post(url, fd, config)
     
  for(var k in section){
  for(var i in data){
      if(data[i].sectionID===section[k].sectionID){
        final.push({label:String(getClassName(section[k].classID, section[k].sectionID, schoolClass)),data:JSON.parse(data[i].subjectDetails).length})
    }
  }
    }
     setAllSubject(final)
            
} 


const classList = {
  labels: allSubject.map((data)=>data.label),
  datasets: [
    {
               
      label: 'Total Subject',
      backgroundColor: [
        '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b', '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507'],
      hoverBackgroundColor: [
      '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507', '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507'],
      data: allSubject.map((data)=>data.data)
    }
  ]
}

   

        useEffect(()=>{
          setTimeout(()=>{
            fetchClassSubject()
          },2000)
        })      
              
   useEffect(()=>{
    //setIsAuthenticated(verifyRouter(location.pathname))
     fetchStatistics()
     
},[]);

        return (  
          <>
         <div  className="main-body">
         {isAuthenticated?   <div className="page-wrapper">
                       {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title='Chart'>
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-dashboard"></i> </a>
                            </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">



<div className="row">

<div className="col-xl-3 col-md-6">
    <div className="card bg-c-yellow text-white">
        <div className="card-block">
            <div className="row align-items-center">
                <div className="col">
                    <p className="m-b-5">Total Session</p>
                    <h4 className="m-b-0">{statistics.session}</h4>
                </div>
                <div className="col col-auto text-right">
                    <i className="fa fa-scribd f-50 text-c-yellow"></i>
                </div>
            </div>
        </div>
    </div>
</div>


<div className="col-xl-3 col-md-6">
                <div className="card bg-c-blue text-white">
                    <div className="card-block">
                        <div className="row align-items-center">
                            <div className="col">
                            <p className="m-b-5">Total Class</p>
                    <h4 className="m-b-0">{tClass.length}</h4>
                            </div>
                            <div className="col col-auto text-right">
                                <i className="fa fa-building f-50 text-c-blue"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


<div className="col-xl-3 col-md-6">
        <div className="card bg-c-green text-white">
            <div className="card-block">
                <div className="row align-items-center">
                    <div className="col">
                        <p className="m-b-5">Total Subject</p>
                        <h4 className="m-b-0">{statistics.subject}</h4>
                    </div>
                    <div className="col col-auto text-right">
                        <i className=" fa fa-reorder f-50 text-c-green"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>


<div className="col-xl-3 col-md-6">
            <div className="card bg-c-pink text-white">
                <div className="card-block">
                    <div className="row align-items-center">
                        <div className="col">
                            <p className="m-b-5">Exam Created</p>
                            <h4 className="m-b-0">{statistics.exam}</h4>
                        </div>
                        <div className="col col-auto text-right">
                            <i className="fa fa-calendar f-50 text-c-pink"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      </div>

    <br/>


  <div className="row">
        <div className="col-12">
          <div className="card">
		   <div className="card-header">
            <strong> CLASS SUBJECT</strong>
				</div>
             <div className="card-body">

              <Bar
          data={classList}
          options={{ legend:{
              display:false,
              position:'right'
            }
          }}
        />  
             </div>
          </div>
        </div></div>



   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Chart) 