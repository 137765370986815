import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config} from '../component/include'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
 const PayrollSettings =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });

const [paymentType, setPaymentType]=useState([])
const [leave, setLeave]=useState([])


const [master, setMaster] = useState({
    paymentType:'', 
    leaveName:'',
    leaveType:'Paid',
    timeCycle:'Termly',
    days:'0'
})
const [errors, setErrors] = useState({});
const [activeStep, setActiveStep] = useState(1);
const steps = [{title: 'Payment Type'},  {title: 'Leave Type'}]

let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
const handleOnClickNext = useCallback(() => {
    setActiveStep(activeStep + 1);
   
}, [activeStep])
const handleOnClickStepper = (step) => {
    setActiveStep(step);        
    
}
const handleOnClickBack = useCallback(() => {
    setActiveStep(activeStep - 1);
   
}, [activeStep])


const fetchPaymentType =()=>{
    const fd = new FormData();
          fd.append('jwt', Token); 
    let url = ServerUrl+'/fetch_controller/tbl_payment_type'
    axios.post(url, fd, config).then(result=>setPaymentType(result.data))
}


const fetchLeave =()=>{
    const fd = new FormData();
          fd.append('jwt', Token); 
    let url = ServerUrl+'/fetch_controller/tbl_staff_leave_settings'
    axios.post(url, fd, config).then(result=>setLeave(result.data))
}



const handleDelete = (code, table)=>{  
    setNotice({...notice, 
       isLoading: true}) 
       let close =   document.getElementById('btnWarningDialog-'+code)
          close.click();
          const fd = new FormData();
          fd.append('jwt', Token); 
       fd.append('ID', 'code')
        fd.append('data', code)
        let url = ServerUrl+'/delete_controller/'+table
       axios.post(url, fd, config)
       .then(response =>{
        if(response.data.type ==='success'){
           Alerts('Saved!', 'success', response.data.message)
               } else{
           Alerts('Error!', 'danger', JSON.stringify(response.data))
               }   
       })
       .catch((error)=>{
         Alerts('Error!', 'danger', error.message)
       }).finally(()=>{
        fetchPaymentType()
        fetchLeave()
           setNotice({...notice, 
               isLoading: false
           })
       }) 
}

const tablePaymentTypeHeader = [
   /*  {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>
         <a href="#!" data-toggle="modal"   data-target={'#warningdialog-'+row.code}   title="Delete"><i className="icofont icofont-delete-alt"></i></a>
     
     <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code, 'tbl_payment_type')} mID={row.code} /> 
    </div>, editable: false}, */
    {dataField: 'paymentType', text: 'Payment Type',  sort: true, validator: (newValue, row, column) => {
        if (!newValue) {
          return {
            valid: false,
            message: 'Payment field is required'
          };
        }
        return true;
      } }     
 ];



 const TableBasic=(props)=>{
       
    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData();
          fd.append('jwt', Token);  
            fd.append('newValue', newValue);
            fd.append('column', column.dataField);
            fd.append('ID', ID);
         
           let url = ServerUrl+'/updateCustom_controller/'+props.table
          axios.post(url, fd, config)
          //.then(result => console.log(result.data))
          .then()
          .catch((error)=>Alerts('Error!', 'danger', error.message)) 
        }  
        
        const { SearchBar } = Search;
        
        const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
        );
        const options = {
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
        { text: 'All', value: props.data.length }]
        
        };
        return  <ToolkitProvider search columnToggle 
                    keyField='code' data={ props.data } columns={ props.columns } >
                        
                        {
                            props => (
                            
                            <div className="form-group">
                                <SearchBar
                                { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                    
                                           
                    <BootstrapTable
                                { ...props.baseProps }
                                            striped
                                        hover
                                        condensed
                                        noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                    pagination={paginationFactory(options) }
                                    cellEdit={ cellEditFactory({
                                    mode: 'dbclick',
                                    blurToSave: true,
                                    autoSelectText: true,
                            beforeSaveCell: (oldValue, newValue, row, column) => {
                            if(oldValue !==newValue){
                            handleUpdate(column, newValue, row.ID);
                            
                            return true;
                            }
                            
                        }
                                    }) }
                                />
                            </div>
                            )
                        }
    </ToolkitProvider>
    }



    function submitPaymentType(event){  
        event.preventDefault();
        if(!master.paymentType){
            setErrors({...errors, paymentType:'Payment Type field is Required'})
        }else if(paymentType.filter(item=>item.paymentType===master.paymentType).length!==0){
            setErrors({...errors, paymentType:'Payment Type already exist'})
        }else{ 
    
        setNotice({...notice, 
            isLoading: true})         
            const fd = new FormData();
          fd.append('jwt', Token);  
            fd.append('paymentType', master.paymentType);
           
             let url = ServerUrl+'/save_controller/tbl_payment_type'
            axios.post(url, fd, config)
            .then(response =>{
                if(response.data.type ==='success'){
                    Alerts('Saved!', 'success', response.data.message)
                        } else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data))
                        }   
                })
                .catch((error)=>{
                  Alerts('Error!', 'danger', error.message)
                }).finally(()=>{
                    fetchPaymentType()
                    setNotice({...notice, 
                        isLoading: false})
                    setMaster({...master, paymentType: ''});
                })
            
        }
    }
    const handleChange = event =>{
        const {name, value} = event.target
       setMaster({...master, [name] : value });
       setErrors({...errors, [name]:''})
    }




    function submitLeave(event){ 
        event.preventDefault();
        if(!master.leaveName){
            setErrors({...errors, leaveName:'Leave Name field is Required'})
        }else{          
        setNotice({...notice, 
            isLoading: true})         
            const fd = new FormData();
          fd.append('jwt', Token); 	
            for(let k in master){
                fd.append(k, master[k]);
            }; 
    
            
             let url = ServerUrl+'/save_controller/tbl_staff_leave_settings'
            axios.post(url, fd, config)
            .then(response =>{
                if(response.data.type ==='success'){
                    Alerts('Saved!', 'success', response.data.message)
                        } else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data))
                        }   
                })
                .catch((error)=>{
                  Alerts('Error!', 'danger', error.message)
                }).finally(()=>{
                    fetchLeave()
                    setNotice({...notice, 
                        isLoading: false})
                    setMaster({...master, leaveName:'', timeCycle:'Monthly', leaveType:'Paid'});
                })
            
        }
    }


const handleSubmit =()=>{
    setActiveStep(1)
}



     const tableLeaveHeader = [
       /*  {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>
            <a href="#!" data-toggle="modal"   data-target={'#warningdialog-'+row.code}     title="Delete"><i className="icofont icofont-delete-alt"></i></a>&nbsp;
   
         
   <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code, 'tbl_staff_leave_settings')} mID={row.code} /> 
        </div>, editable: false}, */
        {dataField: 'leaveName', text: 'Leave Name',  sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Leave Name is required'
              };
            }
            return true;
          } },
          {dataField: 'leaveType', text: 'Type', editor: {
            type: Type.SELECT, options:[
                        {value:'Paid', label:'Paid'},
                        {value:'Unpaid', label:'Unpaid'}
            ] }},
          {dataField: 'timeCycle', text: 'Time Cycle',  editor: {
            type: Type.SELECT, options:[
                        {value:'Termly', label:'Termly'},
                        {value:'Session', label:'Session'},
                        {value:'Yearly', label:'Yearly'}
            ] }}   
     ];
        
      
   
useEffect(()=>{
    
    setIsAuthenticated(verifyRouter(location.pathname))
    fetchPaymentType();
    fetchLeave();
 },[]);  
     
     
     return (   <>
        <div  className="main-body">
        {isAuthenticated?   <div className="page-wrapper">
                      {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 

        <PageHeader title="Payroll">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-users"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Payroll</a> </li>       
                    <li className="breadcrumb-item"><a href="#!">Settings</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Settings</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
                    
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-primary btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>
<div style={{marginTop: '10px'}}>
{activeStep === 1 ?  <div className="row setup-content" id="paymentType">
		    
      <div className="col-md-12">

      <div className="card z-depth-0">

  <div className="card-block">
      <div className="card z-depth-0">

  <div className="card-block">
      <div className="row">
                          
      <section className="col-md-12">

      <div className="form-group">
          <div className="input-group">
                      <input type="text" placeholder='Payment Type' name='paymentType' onChange={handleChange} value={master.paymentType} className={errors.paymentType ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
                     <button className="btn btn-inverse" type="button" onClick={submitPaymentType}>Add</button>
             </div>

              <span style={{color:'red'}}>{errors.paymentType && errors.paymentType}</span>
          </div>		
     </section>
         </div>
                              </div>
                              
      </div>	
            <div className="card z-depth-0">

  <div className="card-block">
      <div className="row">
                          
      <section className="col-md-12 table-responsive">
      {notice.isDataFetching ? <TableLoader />:
   <TableBasic data={paymentType}  table="tbl_payment_type" columns={tablePaymentTypeHeader}  />} 
    </section>
              </div>
              </div>
      
      </div>	</div>
      
      </div>	
           
      </div>
   
</div>:
<div className="row setup-content" id="leave">
		    
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

   <div className="card-block">
       
   <div className="row">
                                
            <section className="col-md-6">
            <label>Leave Name </label>
            <div className="form-group">
                <div className="input-group">
                    <input type="text" placeholder='Leave Name' name='leaveName' onChange={handleChange} value={master.leaveName} className={errors.leaveName ? 'form-control form-control-danger' : 'form-control'} />
                    </div>

                    <span style={{color:'red'}}>{errors.leaveName && errors.leaveName}</span>
                </div>

                <div className="form-group">
               
               <label>Leave Type </label>
                               <div className="form-radio">
                               <div className="radio radio-inline"><label>
                                       <input type="radio" checked={master.leaveType ==="Paid"} onChange={handleChange} value="Paid" name="leaveType" />
                                                       <i className="helper"></i>Paid</label></div>
               
               
                                                       <div className="radio radio-inline"><label>
                                       <input type="radio" checked={master.leaveType ==="Unpaid"} onChange={handleChange} value="Unpaid" name="leaveType" />
                                                       <i className="helper"></i>Unpaid</label></div>
               
               
                                   </div>
                               </div>	
            </section>

            <section className="col-md-6">


                <label>Time Cycle </label>
                <div className="form-radio">

                                        
                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={master.timeCycle ==="Termly"} onChange={handleChange} value="Termly" name="timeCycle" />
										<i className="helper"></i>Termly</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={master.timeCycle ==="Session"} onChange={handleChange} value="Session" name="timeCycle" />
										<i className="helper"></i>Session</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={master.timeCycle ==="Yearly"} onChange={handleChange} value="Yearly" name="timeCycle" />
										<i className="helper"></i>Yearly</label></div>


                    </div><br/>
                <div className="form-group">
                    
                <button className="btn btn-inverse" type="button" onClick={submitLeave}>Add</button>                  
                </div>
            </section>


                </div>
            </div>
                               
       </div>	
             <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12 table-responsive">
       {notice.isDataFetching ? <TableLoader />:
   <TableBasic data={leave}  table={'tbl_staff_leave_settings'} columns={tableLeaveHeader}  />} 
       </section>
               </div>
               </div>
       
       </div>	</div>
       
       </div>	
			
       </div>
    
</div>}

                <button onClick={activeStep === steps.length ? handleSubmit : handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Finish </i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }
                </div>
                
                
                
                </div>
               
                </div>
                </div>
                </div> </div>
                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(PayrollSettings) 