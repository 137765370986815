import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')


export const FormatNumber = (num)=>{
    
    let newNumber = Number(num)
    return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const staffName = Cookies.get('greatkaustnm')?CryptoJS.AES.decrypt(Cookies.get('greatkaustnm'), 'greatschool').toString(CryptoJS.enc.Utf8):'null'
export const staffCode = Cookies.get('greatkauscd')?CryptoJS.AES.decrypt(Cookies.get('greatkauscd'), 'greatschool').toString(CryptoJS.enc.Utf8):'null'

export const groupCode = Cookies.get('greatkagpcd')?CryptoJS.AES.decrypt(Cookies.get('greatkagpcd'), 'greatschool').toString(CryptoJS.enc.Utf8):'null' 

export const Token = Cookies.get('greatkamtk')?CryptoJS.AES.decrypt(Cookies.get('greatkamtk'), 'greatschool').toString(CryptoJS.enc.Utf8):undefined
export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 
export const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

//school name and LocalUrl should be entered manually
export const schoolName = 'GREAT MINDS MODEL INTELLECTUAL ACADEMY'
export const Currency = '₦'
export  const smsAccountUrl = 'https://www.multitexter.com';  



//export  const ServerUrl = 'http://localhost/gmmia/controller';
//export  const ImagesUrl = 'http://localhost/gmmia/images'; 


export  const ServerUrl = 'https://app.greatmindsmia.com/controller';
export  const ImagesUrl = 'https://app.greatmindsmia.com/images'; 


export const domain = '.greatmindsmia.com'
export  const mainUrl = 'https://sms.greatmindsmia.com'; 
//export const LocalUrl ='app.greatmindsmia.com'

export const LocalUrl ='localhost'


export const applicantUrl = 'https://forms.greatmindsmia.com';
export  const ParentUrl= 'https://parent.greatmindsmia.com'; 
export  const teacherUrl = 'https://staff.greatmindsmia.com'; 
export  const studentUrl = 'https://learners.greatmindsmia.com';  
export const DatabaseName= 'greagjvo_db' 