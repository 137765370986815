import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import Select from 'react-select'
import {ServerUrl, ImagesUrl, Token, Currency, config, schoolName} from '../component/include'
import { longDate, FormatNumber, formatGroupLabel, getClassName } from '../component/globalFunction'
import { userToken } from '../component/authentication'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import { useDispatch, useSelector } from 'react-redux'

const PaymentReport=()=>{
	const structure = useRef(null)
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [feecharge, setFeecharge] = useState([]);
    
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);

    const [errors, setErrors] = useState({});
    const [classResult, setClassResult]=useState([]);
    const [payment, setPayment] =  useState([]);
const [fees, setFees] = useState([]);
    const [studentFee, setStudentFee]=useState({
        studentClass:[],
        feecharge:'',
        sumTotal:'',
        sumPaid:'',
        sumBalance:''
    });
    

    const fetchFeecharge =()=>{
        const fd = new FormData();
    fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_fees_charge'
        axios.post(url, fd, config).then(result=>setFeecharge(result.data))
    }

   




const fetchPayment =()=>{
	setNotice({...notice, isLoading: true}) 
    var sql ="SELECT  sf.classID, sf.sectionID, sf.feeCharge, sum(sf.total) as total,  sum((select sum(h.amount) from tbl_fees_payment_history h where h.scheduleCode =sf.code)) as paid from tbl_student_fees sf  where  sf.feeCharge = '"+studentFee.feecharge.value+"' "
    
    if(studentFee.studentClass.length!==0){
        sql = sql + '  and sf.sectionID =   "'+studentFee.studentClass.value+'"'
    }else{
        sql = sql + ' group by sf.sectionID '
    }
    
    const fd = new FormData();
    fd.append('jwt', Token);
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{
	if(result.data.length!==0){

    
setPayment(result.data)

var  sumTotal = 0, sumPaid = 0, sumBalance = 0;
            var option = result.data
    for(var i = 0; i < option.length; i++)
    {
        sumTotal = sumTotal + Number(option[i].total);
        sumPaid = sumPaid + Number(option[i].paid);
        sumBalance = sumBalance + (Number(option[i].total)- Number(option[i].paid));
    }
    setStudentFee({...studentFee,
        sumTotal:FormatNumber(sumTotal),
        sumPaid:FormatNumber(sumPaid),
        sumBalance:FormatNumber(sumBalance)
    })
    }else{
        setPayment([])
    }
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
	setNotice({...notice, isLoading: false})
	
})
}


const fetchFees =(feecharge)=>{
    setNotice({...notice, isLoading: true}) 
    var sql ="SELECT  sf.classID, sf.sectionID, sf.feeCharge, s.code, s.admissionNumber,  s.studentName, s.mobile, sf.total,  (select sum(h.amount) from tbl_fees_payment_history h where h.scheduleCode =sf.code) as paid from tbl_students s, tbl_student_fees sf  where s.code = sf.studentCode and sf.feecharge = '"+feecharge+"' "    
    const fd = new FormData();
    fd.append('jwt', Token);
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{
setFees(result.data)
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
})
.finally(()=>{
	setNotice({...notice, isLoading: false})
	
})
}



const fetchResult =()=>{
	setNotice({...notice, isLoading: true}) 
	
    var sql ="Select CONCAT(f.sessionCode, ' ', f.feesCharge) as fees, s.code, s.sectionID, s.classID from  tbl_student_fees s, tbl_fees_charge f  where f.code = s.feeCharge and s.feeCharge = '"+studentFee.feecharge.value+"'" ;

 if(studentFee.studentClass.length!==0){
        sql = sql + '  and s.sectionID =   "'+studentFee.studentClass.value+'"'
    }
    sql = sql + ' group by s.sectionID '

    const fd = new FormData();
    fd.append('jwt', Token);
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{
if(result.data.length!==0){
    setClassResult(result.data)

    structure.current.scrollIntoView({ behavior: "smooth" });
}else{
    setClassResult([])
    Alerts('Info!', 'info', 'There are no payment record found')
}


})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
	setNotice({...notice, isLoading: false})
	
})
}

const handleSelect = (option, action)=>{
	setStudentFee({...studentFee, [action.name]: option});
    setErrors({...errors, [action.name]:''})
    if(action.name==='feecharge'){
        fetchFees(option.value)
    }
 }




  const getTotal =(sectionID)=>{

    var  sumTotal = 0, sumPaid = 0, sumBalance = 0;
    var option = fees.filter(item=>item.sectionID===sectionID)

for(var i = 0; i < option.length; i++)
{
sumTotal = sumTotal + Number(option[i].total);
sumPaid = sumPaid + Number(option[i].paid);
sumBalance = sumBalance + (Number(option[i].total)- Number(option[i].paid));
}

return {sumTotal:FormatNumber(sumTotal), sumPaid:FormatNumber(sumPaid), sumBalance:FormatNumber(sumBalance)}
  }


    useEffect(()=>{ 
        
        setIsAuthenticated(verifyRouter(location.pathname))
    fetchFeecharge()
},[]);


const handleSearch=()=>{
    
    if(studentFee.feecharge.length===0){
        setErrors({...errors, feecharge:'Please select fee Charge'})
        Alerts('Error!', 'danger', 'Please select fees')
    }else{
        fetchResult()
        fetchPayment()
    } 
}

   return ( 
    <>
   <div  className="main-body">
   {isAuthenticated?   <div className="page-wrapper">
                 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Payment History">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Fees</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Payment History</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                
								<div className="row  no-print">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Payment History</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
        <div className="row">
        <section className="col-md-6">
    
        <div className="form-group">
        
        <label >Fee Charge<span style={{color:'red'}}>*</span> </label>
        <Select  options={feecharge&&feecharge.map((list, idx)=> {
                                       return {key:idx, value: list.code, label:list.feesCharge, session:list.sessionCode }
                                     })
                                } 
                                getOptionLabel={option =>`${option.session}  ${option.label}`}       
        onChange={handleSelect} className={errors.feecharge ? 'form-control form-control-danger' : ''} name="feecharge" value={studentFee.feecharge}  />  
                      <span style={{color:'red'}}>{errors.feecharge && errors.feecharge}</span> 
                    </div>
			</section>


           <section className="col-md-6">
                    <div className="form-group">
         <label> Student Class</label>
            <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect} name="studentClass" value={studentFee.studentClass}  /> 

            </div>
                            </section>
									</div>
										
                <div className="row">
                <section className="col-md-12">
<button type="button"  onClick={handleSearch} className="btn btn-inverse pull-right"><i className="fa fa-search"></i> Search Record</button>
</section>	                </div>
							</div>
							</div>

							</div>
                </div>



{classResult&&classResult.map((item, index)=>
<div className="card z-depth-0" key={index}>
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <table className='table'  key={id}>
       <tbody>
                <tr>
                <td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
 </td>
                </tr>
            </tbody>
       </table>  )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Payment Details</b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td colSpan='2'>Class: <b>{getClassName(item.classID, item.sectionID, schoolClass)}</b></td>
                    <td >Fees: <b>{item.fees}</b></td>
                    <td>Date Printed: <b>{longDate(new Date())} </b></td>
                </tr>
                
            </tbody>
       </table>
        <table id='paymentTable' className="table table-striped table-bordered  table-full-width">
    

            <thead>
                <tr>
                <th>Admission Number</th>
                <th>Student Name</th>
                <th> Mobile</th>
                <th> Due Amount ({Currency})</th>
                <th>Paid Amount ({Currency})</th>
                <th> Balance ({Currency})</th>
                
                </tr>
            </thead>
            <tbody >

                    {fees&&fees.map((dat, id)=>
                    dat.sectionID===item.sectionID?	<tr key={id}>
                        <td>{dat.admissionNumber}</td>
					<td>{dat.studentName}</td>
                    <td>{dat.mobile}</td>
					<td>{FormatNumber(dat.total)}</td>
					<td>{FormatNumber(dat.paid)}</td>
					<td>{FormatNumber(Number(dat.total) - Number(dat.paid))}</td>
					
						</tr>:[])}
				</tbody>
            <tfoot>
                <tr>
                    <td className="text-right" colSpan="3"><b>Total</b> </td>
                    <td ><b >{getTotal(item.sectionID).sumTotal}</b></td>
                    <td ><b >{getTotal(item.sectionID).sumPaid}</b></td>
                    <td ><b >{getTotal(item.sectionID).sumBalance}</b></td>
                </tr>
            </tfoot>
        </table>
 </div>	 </div> 

 <div className="row">
        <div className="col-md-12">

        <form method='POST' action={ServerUrl+'/printout/payment_history.php?token='+userToken} target='_blank'>
                <input type='hidden' name='fees' value ={JSON.stringify(fees, null, 2)} />
                <input type='hidden' name='classID' value ={item.classID} />
                <input type='hidden' name='sectionID' value ={item.sectionID} />
                <input type='hidden' name='feecharge' value ={item.fees} />
                <input type='hidden' name='jwtToken' value ={Token} />
                <input type='hidden' name='sumTotal' value ={getTotal(item.sectionID).sumTotal} />
                <input type='hidden' name='sumPaid' value ={getTotal(item.sectionID).sumPaid} />
                <input type='hidden' name='sumBalance' value ={getTotal(item.sectionID).sumBalance} />


        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>


        </div></div>

 </div>
        </div>)}




  {classResult.length!==0?
<div className="card z-depth-0">
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <table className='table' key={id}>
       <tbody>
                <tr>
                <td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
 </td>
                </tr>
            </tbody>
       </table>  )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Payment Summary</b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">
<tr>
                <td>Date Printed: <b>{longDate(new Date())} </b></td>
                </tr>
        <table className="table table-striped table-bordered  table-full-width">
    
            <thead>
                <tr>
                <th>Class</th>
                <th> Due Amount ({Currency})</th>
                <th>Paid Amount ({Currency})</th>
                <th> Balance ({Currency})</th>
                </tr>
            </thead>
            <tbody >
					{payment.length!==0?payment.map((dat, id)=>{
					return	<tr key={id}>
					<td>{getClassName(dat.classID, dat.sectionID, schoolClass)}</td>
					<td>{FormatNumber(dat.total)}</td>
					<td>{FormatNumber(dat.paid)}</td>
					<td>{FormatNumber(Number(dat.total) - Number(dat.paid))}</td>
					
						</tr>
					}):[]}
				</tbody>
                <tfoot>
                <tr>
                    <td className="text-right"><b>Total</b> </td>
                    <td ><b >{studentFee.sumTotal}</b></td>
                    <td ><b >{studentFee.sumPaid}</b></td>
                    <td ><b >{studentFee.sumBalance}</b></td>
                </tr>
                </tfoot>
        </table>
 </div>	 </div> 
 <div ref={structure} />
 <div className="row">
        <div className="col-md-12">


        <form method='POST' action={ServerUrl+'/printout/payment_summary.php?token='+userToken} target='_blank'>
                <input type='hidden' name='payment' value ={JSON.stringify(payment, null, 2)} />

                <input type='hidden' name='sumTotal' value ={studentFee.sumTotal} />
                <input type='hidden' name='sumPaid' value ={studentFee.sumPaid} />
                <input type='hidden' name='sumBalance' value ={studentFee.sumBalance} />
                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>

        </div></div>

 </div>
        </div>:''}

          {/* The Page Body close here */}
          
            </div>
            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(PaymentReport) 