import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config, smsAccountUrl} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel} from '../component/globalFunction'
import { Link } from 'react-router-dom'

import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";

 const Settings =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false
    }); 
    const theme = 'snow';
    const [sendMessage, setSendMessage] = useState({
        receiverType:'All',
        studentClass:'',
        studentList:[],
        allStudent:'Yes',
        messageNumber:'Email',
        message:'',
        emailMessage:''
    })
    
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const [students, setStudents] = useState([]);
	const [schoolClass, setSchoolClass]=useState([]); 
    const [studentDetails, setStudentDetails] = useState([]);

const [isSubmitting, setIsSubmitting] = useState(false);
const [errors, setErrors] = useState({});

const [activeStep, setActiveStep] = useState(1);

const [studentMobile, setStudentMobile] = useState([])
const [studentEmail, setStudentEmail] = useState([])

const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'
        ]
    ],
  };

  const placeholder = 'Enter your message...';

  const formats =  ['bold', 'italic', 'underline', 'strike'  ];

  const {quill, quillRef } = useQuill({ theme, modules, formats, placeholder });



const handleFetch =()=>{
    filterAllContact()
 }


const filterAllContact =()=>{

    var options = studentDetails;
    if(sendMessage.allStudent==='No'){
        options = sendMessage.studentList
    }
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
        
        if(options[i].fatherEmail !==''){
    value.push(options[i].fatherEmail);
        }

        if(options[i].motherEmail !==''){
            value.push(options[i].motherEmail);
                }
    }


    setStudentEmail(value);


    var valuesMob = [];
    for (var i = 0, l = options.length; i < l; i++) {

        if(options[i].father_mobile !==''){
            valuesMob.push(options[i].father_mobile);
                }
        
                if(options[i].mother_mobile !==''){
                    valuesMob.push(options[i].mother_mobile);
                        }
    }

    setStudentMobile(valuesMob);
}



const  fetchStudents =()=>{      
    var sql = "select code, admissionNumber, studentName, fatherName, mobile, email, altMobile, father_mobile, mother_mobile, fatherEmail, motherEmail, sectionID from tbl_students where admissionStatus ='Studying' "
    const fd = new FormData();
    fd.append('jwt', Token);
      fd.append("sql", sql);
      let url = ServerUrl+'/fetchBySql_controller'
      axios.post(url, fd, config).then(result=> {
          setStudents(result.data)
          setStudentDetails(result.data)
         
      })
      .catch((error)=>
      Alerts('Error!', 'danger', error.message))
      .finally(()=>{
          setNotice({...notice, isLoading: false  })
})    
 }
 


const handleChange = event =>{
    let {name, value} = event.target
 
    if (name==='receiverType' && value==='All'){     
        setStudentDetails(students);
    }
 
    if (name==='allStudent'){
        value = event.target.value ==='Yes'? 'No':'Yes';        
    }

   setSendMessage({...sendMessage, [name] : value });
   setErrors({...errors, [name]:''})
   handleFetch()
}


const handleSelect = (option, action)=>{
    setSendMessage({...sendMessage, [action.name]: option, studentList:''});
    setErrors({...errors, [action.name]:''}) 
    getStudentByClass(option.value)   
         
};

const handleSelectStudent = (option, action)=>{
    
    setSendMessage({...sendMessage, [action.name]: option});
    setErrors({...errors, [action.name]:''})
   // handleFetch()     
}




const getStudentByClass=(sectionID)=>{
    const result = students&&students.filter(list=>list.sectionID===sectionID);

    setStudentDetails(result)    
    return  ''
}

const handleOnClickStepper = (step) => {
        setActiveStep(step);        
        handleFetch()
    }
    const steps = [{title: 'Select Receiver'}, {title: 'Message '}, {title: 'Confirm '}]
    const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
        handleFetch()
    }, [activeStep])
    
    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
        handleFetch()
    }, [activeStep])
    


    const handleFetchClass =()=>{
        const fd = new FormData();
      fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_school_class/schoolClass/ID/1'
        axios.post(url, fd, config).then(result=>setSchoolClass(result.data))
    }

    

             const  fetchNotification =()=>{  
        
               /*  const fd = new FormData();
      fd.append('jwt', Token);
           let sql = "Select sms, email, mobile from tbl_notification_module where moduleName='Send Message'"
        
               let url = ServerUrl+'/function/fetchBySql_controller'
             
               fd.append("sql", sql)
               
               axios.post(url, fd, config).then(result =>setNotification(result.data))  */
           
            }

    const handleSubmit=(event)=>{
        event.preventDefault();
        handleFetch()
		 setErrors(ValidateInput(sendMessage));
         
        setIsSubmitting(true); 
      
    }

    useEffect(()=>{
        handleFetchClass()
        fetchStudents() 
    },[]);

 

    const ValidateInput=(values)=>{
        let errors = {};     
        if(values.receiverType==='byClass'){

            if(values.studentClass.length===0){
                errors.studentClass ='Please select student class';
            }

        }
        if(values.allStudent==='No'){

            if(!Array.isArray(values.studentList)){
            errors.studentList ='Please select student';
            }
        }

      /*   if(!values.message){
            errors.message ='Please enter message';
        }  */
           
       return errors; 
       }
       
useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
    },[errors])

    const submit=()=>{

        var ct = 1;
        let set ='';

        studentMobile.map(item=>{
            set = set +item
            if(ct<studentMobile.length) set = set+', '
            ct = ct+1
        })


      setNotice({...notice,  isLoading: true})     
		  
		const fd = new FormData();
	    fd.append('jwt', Token);
	
        
        fd.append('mailMessage', String(quill?.root.innerHTML));
        fd.append('message', sendMessage.message);
        fd.append('telephones', String(set));
        fd.append('emails', JSON.stringify(studentEmail, null, 1));
        fd.append('mode', sendMessage.messageNumber);


		   let url = ServerUrl+'/save_controller/tbl_sendBulkMessage'
		  axios.post(url, fd, config)
		  .then(response =>{
			setErrors({...errors, infoMessage: JSON.stringify(response.data)})  
            window.scrollTo(0,0) 
		  })
		  .catch((error)=>{
			setErrors({...errors, errorMessage: error.message})
		  }).finally(()=>{
			  setNotice({...notice,   isLoading: false })
		  })   

		}


        useEffect(()=>{

            if(quill){
            if(quill.root.innerHTML!=="<p><br></p>"){
               setErrors({...errors, message:''})
            }
        }
        },[quill]);
   
        return (  
   <> {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 

        <PageHeader title="Parent Communication">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-commenting-o"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="/communication/template">Communication</a> </li>       
                    <li className="breadcrumb-item"><a href="#!">Send Message</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">









{errors.infoMessage?
<div className="alert alert-info background-info">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.infoMessage}</strong> 
    </div>:''}

{errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}


                <div className="row  ">
   <div className="col-sm-12">
<div className="card z-depth-0">
    <div className="card-block">
    <div className="card z-depth-0">
        <div className="card-block">


        <Link to="parent" className="btn btn-success "> <i className="fa fa-graduation-cap"></i> Parent</Link>&nbsp;&nbsp;
        <Link to="staff" className="btn btn-danger "> <i className="fa fa-user"></i> Staff</Link>




</div>
</div></div>
</div>

</div>  </div>
<div className="row">
<div className="col-sm-12">
                  <div className="alert alert-success border-success background-success"  ><h5>Message Will Be Sent Using Email and or SMS </h5> <a href={smsAccountUrl} target="_blank" ><i className="fa fa-hand-o-right fa-spin"></i> Click Here To Recharge Your SMS Account </a></div></div>
                                      </div>
    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Send SMS to Students</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximize full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
                    
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-primary btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>
<div style={{marginTop: '10px'}}>



{activeStep === 1 ? 
	   <div className="row setup-content" id="selectClass">
		    
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12">

         
       <div className="form-group">
       <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={sendMessage.receiverType==='All'} onChange={handleChange} value="All" name="receiverType" />
										<i className="helper"></i>All</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={sendMessage.receiverType==='byClass'} onChange={handleChange} value="byClass" name="receiverType" />
										<i className="helper"></i>By Class</label></div>

								</div>
           </div>	

           <div style={{display:sendMessage.receiverType==='byClass'?'block':'none'}} className="form-group">
				<label >Select Class</label>
				<Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID,isSection:'1', value: sec.ID, label: sec.sname}:{classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
                             })
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect} className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={sendMessage.studentClass} styles={selectStyles}  /> <span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>
										
									</div>
      </section>
          </div>
                               </div>
                               
       </div>	

	</div>
       
       </div>	
			
       </div>
    
</div> : activeStep === 2 ?
	  <div className="row setup-content" id="messages">
		    
      <div className="col-md-12">

      <div className="card z-depth-0">

  <div className="card-block">
      <div className="card z-depth-0">

  <div className="card-block">
      <div className="row">
                          
      <section className="col-md-3">
          <label>Select Student</label>
</section>
          <section className="col-md-3">
      <div  className="form-group">
                     
<div className="border-checkbox-section">
					
                    <div className="border-checkbox-group border-checkbox-group-primary">
                        <input className="border-checkbox" type="checkbox"  onChange={handleChange} name="allStudent" value={sendMessage.allStudent} checked={sendMessage.allStudent==='Yes'? true:false} id="allStudent" />
                                <label className="border-checkbox-label" htmlFor="allStudent">All</label>
                            </div>
                            
                            </div> 
   </div>
   
   </section>

   <section style={{display:sendMessage.allStudent==='Yes'?'none':'block'}} className="col-md-6">
      	<div className="form-group">
						<Select isMulti options={
                            studentDetails&&studentDetails.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.admissionNumber, studentName:list.studentName, fatherName:list.fatherName, father_mobile:list.father_mobile, mobile:list.mobile, mother_mobile:list.mother_mobile, altMobile:list.altMobile, email:list.email, fatherEmail:list.fatherEmail, motherEmail:list.motherEmail }
                             })} 
                        getOptionLabel={option =>`(${option.label}) ${option.studentName} C/O  ${option.fatherName} `}
onChange={handleSelectStudent} className={errors.studentList ? 'form-control form-control-danger' : ''} name="studentList" value={sendMessage.studentList} styles={selectStyles} /> <span style={{color:'red'}}>{errors.studentList && errors.studentList}</span>
											</div>                                  
	
     </section>
         </div>
         <div className="row">
                          
            <section className="col-md-3">
                <label>Messaging Option</label>
    </section>

    <section className="col-md-9">

<div className="form-group">
<div className="form-radio">
         <div className="radio radio-inline"><label  >
                 <input type="radio" checked={sendMessage.messageNumber==='Email'} onChange={handleChange} value="Email"   name="messageNumber" />
                                 <i className="helper"></i>Email Only
                                 
                                 <a className="mytooltip" href="#!"> <i className="fa fa-question-circle"></i><span className="tooltip-content5">
            
            <span className="tooltip-text3">
                <span className="tooltip-inner2">Message will be sent to student registered All email (Fathers and Mothers)</span>
                </span>
            </span>
            </a></label></div>

                                 <div className="radio radio-inline"><label  >
                 <input type="radio"   checked={sendMessage.messageNumber==='sms'} onChange={handleChange} value="sms" name="messageNumber" />
                                 <i className="helper"></i>SMS Only
                                 <a className="mytooltip" href="#!"> <i className="fa fa-question-circle"></i><span className="tooltip-content5">
            
            <span className="tooltip-text3">
                <span className="tooltip-inner2">Message will be sent to student registered  Number (Fathers and Mothers)</span>
                </span>
            </span>
            </a>
                                 
                                 </label>
                                 
                                 
                                 </div>


                                 <div className="radio radio-inline"><label  >
                 <input type="radio"   checked={sendMessage.messageNumber==='both'} onChange={handleChange} value="both" name="messageNumber" />
                                 <i className="helper"></i>Both SMS and Email
                                 <a className="mytooltip" href="#!"> <i className="fa fa-question-circle"></i><span className="tooltip-content5">
            
            <span className="tooltip-text3">
                <span className="tooltip-inner2">Message will be sent to student registered  Email and Number (Fathers and Mothers)</span>
                </span>
            </span>
            </a>
                                 
                                 </label>
                                 
                                 
                                 </div>

                         </div>
    </div>	

    </section>

                    </div>

                    {sendMessage.messageNumber==='sms' || sendMessage.messageNumber==='both' ? 
                    <div className="row">
                          
                          <section className="col-md-3">
                              <label>SMS Content</label>
                  </section>
              
                  <section className="col-md-9">

               
            <div className="form-group">
             <textarea  className={errors.message ? 'form-control form-control-danger' : 'form-control'} rows="6" name="message" value={sendMessage.message} onChange={handleChange}> {sendMessage.message}</textarea><span style={{color:'red'}}>{errors.message && errors.message}</span>

   <div style={{color:'green'}}>{sendMessage.message.length} Characters,  {(Math.ceil(Number(sendMessage.message.length)/160))}  Pages, Remaining {(160 -(Number(sendMessage.message.length)%160))}</div>
                  </div> 
                  
                  </section>
                                  </div>:''}
                                 
                              </div>
                              
      </div>	
           	</div>
      
      </div>	
           
      </div>
   
</div>:<div className="row setup-content" id="confirm">
		    
            <div className="col-md-12">
      
            <div className="card z-depth-0">
      
        <div className="card-block">
            <div className="card z-depth-0">
      
        <div className="card-block">
            <div className="row">
                       <center><h1>Read And Confirm</h1></center>      
            <section className="col-md-12">
            <div style={{color:'red'}}>{errors.studentClass && errors.studentClass}</div>
            <div style={{color:'red'}}>{errors.studentList && errors.studentList}</div>
            <div style={{color:'red'}}>{errors.message && errors.message}</div>
    <div className="well well-sm alert alert-warning border-warning background-warning"  >{sendMessage.message}</div>
            <div className="well well-sm alert alert-info border-info background-info"  >
              
              
              
            {sendMessage.messageNumber==='sms' || sendMessage.messageNumber==='both' ?   <p>Total Number Of SMS In This Request: {(Math.ceil(Number(sendMessage.message.length)/160))} * {studentMobile.length} = {(Math.ceil(Number(sendMessage.message.length)/160)) * Number(studentMobile.length)}</p>:''}

            {sendMessage.messageNumber==='sms' || sendMessage.messageNumber==='both' ?    <p>Total Unit Needed:  {(Math.ceil(Number(sendMessage.message.length)/160)) * Number(studentMobile.length)*2}</p>:''}

           {sendMessage.messageNumber==='Email' || sendMessage.messageNumber==='both' ?  <p>Number Of People To Send Message In Email {studentEmail.length}</p> :''}

            
            </div> 
      </section>
         
               </div>     </div>
                                    
            </div>	
                     </div>
            
            </div>	
                 
            </div>
         
      </div>}



      
<div className="form-group "><b> Message Content</b><div className="h-10"></div>
                        
                       
                        <div style={{ width: '100%', height: 300 }}>
                                                <div ref={quillRef} />
                        
                                                </div>
                                        
                                                </div>
                        
                                                <br/>
                        <span style={{color:'red'}}>{errors.message}</span>
                        <div style={{ height: 10 }}></div>


<div className="h-20"></div>
                <button onClick={activeStep === steps.length ? handleSubmit : handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Send Message </i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }
                </div>
                
                
                
                </div>
               
                </div>
                </div>
                </div> </div>
                </div>
          {/* The Page Body close here */}

            </div>
            </div>
</> 
 );
        
}

export default React.memo(Settings) 