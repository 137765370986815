import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import { ServerUrl,  Token, ImagesUrl, config, schoolName} from '../component/include'
import Select from 'react-select'
import {  getTime, longDate} from '../component/globalFunction'
import {formatGroupLabel} from '../component/globalFunction'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
const ClassTimetable =()=> {

    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });

    const [activities, setActivities]=useState([])
    const [schoolInfo, setSchoolInfo]=useState([])
    
    const [schedule, setSchedule]=useState({
        schoolClass:[],
        weekDate:''
    });

    const [schoolClass, setSchoolClass]=useState([]);
    const [subject, setSubject]= useState([]) 
    const [scheduleList, setScheduleList] = useState([]);
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
   
    
    const [CurrentSession, setCurrentSession] = useState('')
    const  handleSession =()=>{
    
        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config)
        .then(result => {
            if(result.data.length!==0){
            setCurrentSession(result.data[0].startYear)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        } 

    const fetchSchoolInfo =()=>{
        const fd = new FormData();
      fd.append('jwt', Token);
        let url = ServerUrl + '/fetch_controller/tbl_basic_details'
        axios.post(url, fd, config).then(result=>setSchoolInfo(result.data))

    }
    const fetchSchoolClass =()=>{
        const fd = new FormData();
      fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_school_class/schoolClass/ID/1'
        axios.post(url, fd, config)
        .then(result => setSchoolClass(result.data))
        .catch((error)=>Alerts('Error!', 'danger', JSON.stringify(error.message))) 

    }

    const fetchSubject =()=>{
        const fd = new FormData();
      fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_subject'
        axios.post(url, fd, config).then(result=>setSubject(result.data))
    }
    


    const handleSelect = (option, action)=>{
        setSchedule({...schedule, [action.name]: option});
        fetchTableList(option.value)
    }

    const  fetchActivities =()=>{  
        const fd = new FormData();
      fd.append('jwt', Token);
   let sql = "Select ID, activity from tbl_activities order by activity ASC"
       let url = ServerUrl+'/fetchBySql_controller' 
       fd.append("sql", sql)
       axios.post(url, fd, config).then(result =>setActivities(result.data)) 
   
    }  

    const  fetchTableList =(section)=>{  

        const fd = new FormData();
        fd.append('jwt', Token);
            let sql = "Select  periodList, day from tbl_timetable where sectionID = '"+section+"' and sessionYear = '"+CurrentSession+"' and  day IN('Monday', 'Tuesday', 'Wednessday', 'Thursday', 'Friday', 'Saturday', 'Sunday' )"
     
          setNotice({...notice, 
            isLoading: true})
          let url = ServerUrl+'/fetchBySql_controller'
           fd.append("sql", sql)
          
           axios.post(url, fd, config).then(result =>{
           
             if(result.data.length!==0){
                setScheduleList(result.data)
                }else{
                   setScheduleList('')
                   Alerts('Error!', 'danger', 'There is no time table setup yet for this class')
                } 
        
            }).finally(()=>{
                setNotice({...notice, 
                    isLoading: false})
            })  
    }


 const getSubject =(code)=>{
    const result = subject&&subject.filter(list=>list.code===code);
    const answer = result.map(c=>c.subjectName);
    return  answer  
}


const getPeriod =(ID)=>{
    const result = activities&&activities.filter(list=>list.ID===ID);
    const answer = result.map(c=>c.activity);
    return  answer  
}

const getTableDetails=(data)=>{
    const list = JSON.parse(data)
    return list.map((item, id)=>
    <div key={id} className="attachment-block text-center">  
    <b className="text-green">{item.periodName +'. '+ getPeriod(item.periodType)}</b>
<br/>
<strong className="text-green">{getTime(item.startTime)+ ' ~ '+getTime(item.endTime)} </strong>
<br/>
    <b className="text-green">{getSubject(item.subject)}</b><br/>
      
        
        <br/>
    </div>
    )     
}


   

useEffect(()=>{
    //setIsAuthenticated(verifyRouter(location.pathname))
    fetchSubject()
    fetchSchoolClass()
    fetchSchoolInfo()
    fetchActivities()
    handleSession()
},[]);



        return (    <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Class Timetable">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-table"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Timetable</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Class Timetable</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

         
<div  className="row  no-print">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Serach Timetable</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
           
        <div className="card z-depth-0" >
		<div  className="card-block">
            <div className="row  ">
		
            <section className="col-md-12">
      <div className="form-group">
						<label >Select Class<span style={{color:'red'}}>*</span></label>
                        <Select  options={
                             schoolClass.map((list, idx)=> {
                                return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                    return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                                }) }
                              })
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  name="schoolClass" value={schedule.schoolClass}   /> 
											
											</div>	
                                      
</section>

	</div>			
</div>
</div>  



     
     </div>  </div>
							
                            </div>
                            
                            </div>



                            {scheduleList.length!==0?
    <div className="card z-depth-0">
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <div className="row" key={id}>

<table className="table  table-full-width">
       <tbody>
                <tr>
                    <td className="pull-right" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  /></td>
                    <td >
<h4 style={{textAlign:'center'}}><b>{schoolName}</b></h4>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}>  {st.mobile} &nbsp; {st.altMobile} </div>
 <div style={{textAlign:'center'}}>  {st.email} </div></td>
                </tr>
            </tbody>
       </table>

</div> 
                       )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Class Timetable </b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Class: <b>{schedule.schoolClass.label}</b></td>
                    <td>Date Printed: <b>{longDate(new Date())} </b></td>
                </tr>
            </tbody>
       </table>
      
       <table className="table table-bordered">
            <thead>
                <tr>
                    <th>Monday</th>
                    <th>Tuesday</th>
                    <th>Wednessday</th>
                    <th>Thursday</th>
                    <th>Friday</th>
                    <th>Saturday</th>
                    <th>Sunday</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                    {scheduleList&&scheduleList.map((list, index)=>list.day==='Monday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.periodList)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>list.day==='Tuesday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.periodList)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>list.day==='Wednessday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.periodList)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>list.day==='Thursday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.periodList)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>list.day==='Friday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.periodList)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>list.day==='Saturday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.periodList)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>list.day==='Sunday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.periodList)}</span>:' ')}
                        </td>

                </tr>
            </tbody> 
        </table>

 </div>	 </div> 


 </div>
        </div>:''}


   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(ClassTimetable) 