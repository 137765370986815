import React, {useState, useEffect} from 'react'
import PageHeader from '../component/pageheader'
import {LoadEffect } from '../component/loader'
import {ServerUrl, Currency, Token, config} from '../component/include'
import axios from 'axios'
import { convertDate} from '../component/globalFunction'
import {Bar, Line} from 'react-chartjs-2'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
 const AssetChart =()=> {
	let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
    });
    const [assetFees, setAssetFees] = useState([])
    var today = new Date().toISOString().slice(0,10);

    var date = new Date()
    var first = new Date(date.getFullYear(), date.getMonth(), 1, 23, 59, 59)
    var last = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59)

    const [category, setCategory] = useState([])

    const [statistics, setStatistics] = useState({
        asset:0,
        allocated:0,
        missing:0,
        addedToday:0
    });



    const feeChart = {
        labels: assetFees.map((data)=>data.label),
        datasets: [
          {
            label: 'Amount '+Currency,
            fill: false,
            lineTension: 0.5,
            backgroundColor: 'rgba(75,192,192,1)',
            borderColor: 'rgba(0,0,0,1)',
            borderWidth: 2,
            data: assetFees.map((data)=>data.data)
          }
        ]
      }
      



      const fetchAssetFees =()=>{
      
        var sql = "Select purchaseDate as label, sum(purchaseAmount) as data from tbl_assets where purchaseDate >=  '"+convertDate(first)+"' and purchaseDate  <= '"+convertDate(last)+"' GROUP BY purchaseDate order by label  "

         const fd = new FormData();
         fd.append("sql", sql);
         fd.append('jwt', Token)
         let url = ServerUrl+'/fetchBySql_controller';
         axios.post(url, fd, config).then(result=>{ 
            
             setAssetFees(result.data)
         })
     }




     const fetchCategory =  ()=>{

        let sqls = "Select category as label, count(*) as data from tbl_assets GROUP BY category order by label  "

         const fds = new FormData();
         fds.append("sql", sqls);
         fds.append('jwt', Token)
         let urls = ServerUrl+'/fetchBySql_controller';
         axios.post(urls, fds, config).then(result=>{ 
            
             setCategory(result.data)
         })
     }



     const fetchStatistics =()=>{
        var sql ="Select (select count(*) from tbl_assets ) as asset, (select count(*) from tbl_assets where assignTo <> '' ) as allocated, (select count(*) from tbl_assets  where assetType = 'Missing' ) as missing, (select count(*) from tbl_assets  where dateCreate = '"+today+"' ) as addedToday "
          
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
        
        axios.post(url, fd, config).then(result=>{ 
            
            setStatistics({
                asset:result.data[0].asset,
                allocated:result.data[0].allocated,
                missing:result.data[0].missing,
                addedToday:result.data[0].addedToday
            })
        })
    
    }
        
       
        
        const categoryList = {
            labels: category.map((data)=>data.label),
            datasets: [
              {
                         
                label: 'Total Asset',
                backgroundColor: [
                  '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b'],
                hoverBackgroundColor: [
                '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507'],
                data: category.map((data)=>data.data)
              }
            ]
          }


          

            const getFullMonth =(date)=>{
                const ndate  = new Date(date)
                const month = ndate.toLocaleString('default', {month:'long'})
                return month
              }

           
   useEffect(()=>{
    setIsAuthenticated(verifyRouter(location.pathname))
    fetchAssetFees()
    fetchStatistics()
    fetchCategory()
},[]);
 

        return (<>
   <div  className="main-body">
   {isAuthenticated?   <div className="page-wrapper">
       <PageHeader title="Asset Chart"
					   />


{/* The Page Body start here */} 
<div className="page-body">
         


{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }

         
<div className="row">
        <div className="col-lg-12">
		  <div id="contentHere" >

      <div className="row">




<div className="col-xl-3 col-md-6">
    <div className="card bg-c-yellow text-white">
        <div className="card-block">
            <div className="row align-items-center">
                <div className="col">
                    <p className="m-b-5">Total Asset</p>
                    <h4 className="m-b-0">{statistics.asset}</h4>
                </div>
                <div className="col col-auto text-right">
                    <i className="fa fa-building f-50 text-c-yellow"></i>
                </div>
            </div>
        </div>
    </div>
</div>


<div className="col-xl-3 col-md-6">
        <div className="card bg-c-green text-white">
            <div className="card-block">
                <div className="row align-items-center">
                    <div className="col">
                        <p className="m-b-5">Total Allocated</p>
                        <h4 className="m-b-0">{statistics.allocated}</h4>
                    </div>
                    <div className="col col-auto text-right">
                        <i className=" fa fa-user f-50 text-c-green"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>


<div className="col-xl-3 col-md-6">
            <div className="card bg-c-pink text-white">
                <div className="card-block">
                    <div className="row align-items-center">
                        <div className="col">
                            <p className="m-b-5">Total Missing</p>
                            <h4 className="m-b-0">{statistics.missing}</h4>
                        </div>
                        <div className="col col-auto text-right">
                            <i className="fa fa-times f-50 text-c-pink"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>


<div className="col-xl-3 col-md-6">
                <div className="card bg-c-blue text-white">
                    <div className="card-block">
                        <div className="row align-items-center">
                            <div className="col">
                                <p className="m-b-5">Added Today</p>
                                <h4 className="m-b-0">{statistics.addedToday}</h4>
                            </div>
                            <div className="col col-auto text-right">
                                <i className="fa fa-cog f-50 text-c-blue"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




      </div>




      <div className="row">
        <div className="col-12">
          <div className="card">
		   <div className="card-header">
            <strong> ASSET CATEGORIES</strong>
				</div>
             <div className="card-body">

              <Bar
          data={categoryList}
          options={{ legend:{
              display:false,
              position:'right'
            }
          }}
        />  
             </div>
          </div>
        </div></div>




       
  <div className="row">
      <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
		   <div className="card-header"><strong>
      Asset Purchase ( {getFullMonth(new Date())})</strong>
				</div>
             <div className="card-body">

              <Line
          data={feeChart}
          options={{
            legend:{
              display:false,
              position:'right'
            }
          }}
        /> 

             </div>
          </div>
        </div></div>

          </div>
        </div>
      </div>

                </div> 
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 

 );
        
}

export default React.memo(AssetChart) 