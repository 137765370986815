import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config, ParentUrl} from '../component/include'
import Select from 'react-select'
import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { userToken, verifyRouter } from '../component/authentication'
import { useDispatch, useSelector } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';

import Cookies from 'js-cookie'
import {encrypt} from '../component/authentication'
const Family =()=> {
    const [errors, setErrors] = useState({});
    let myRef = useRef()
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [family, setFamily] = useState({
        familyName:'',
        student:'',
        username:'',
        studentList:[]
    })
    const [allFamily, setAllFamily]= useState([])
    const students  = useSelector(state => state.rootReducer.students);
    const handleChange = event =>{
		const {name, value} = event.target
       setFamily({...family, [name] : value });
       setErrors({...errors, [name]:''});
    }



    const LoginAsParent =(row)=>{

        var inSixtyMinutes = Date.now() + 59 * 60 * 1000;
		var inOneMinutes = Date.now() + 58 * 60 * 1000;
		var inOneHours = new Date(new Date().getTime() + 59 * 60 * 1000);
		let domain = '.gaatschool.org'


		Cookies.set('_gaatpexpt', inSixtyMinutes, {expires: inOneHours, domain: domain })
			Cookies.set('_gaatpbexpt', inOneMinutes, {expires: inOneHours, domain: domain })

		Cookies.set('gaatpkauscd', row.code, {expires: inOneHours, domain: domain })
		Cookies.set('gaatpkamtk', Token,  {expires: inOneHours, domain: domain })   
		Cookies.set('gaatpkarfsh', userToken,  {expires: inOneHours, domain: domain })
		Cookies.set('gaatpkstnm', encrypt(row.familyName),  {expires: inOneHours, domain: domain })


        
        window.open(ParentUrl+'/dashboard', '_blank');

    }


    const handleDelete = (ID)=>{  
        setNotice({...notice,  isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+ID)
              close.click();
           const fd = new FormData();
           fd.append('ID', 'ID')
            fd.append('data', ID)
            fd.append("jwt", Token);
            let url = ServerUrl+'/delete_controller/tbl_family';
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
                   }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
              fetchFamily()
               setNotice({...notice, 
                   isLoading: false
               })
           }) 
    }

    const handleStudentName=(cell,row)=>{
        return JSON.parse(cell).map((itm, idx)=>{
            return <div  key={idx}>{getStudent(itm.code)}</div>
        } );
    }
	const getStudent = (code) =>{   
        
         const result = students.filter(list=>list.code===code);
        const answer = result.length!==0?result.map(c=>`(${c.admissionNumber}) ${c.studentName}` ):''

          return  answer;
        }

    const tableHeader = [
        {dataField: 'ID', text: 'Action', formatter: (cell, row)=><div>
            <a href="#!" data-toggle="modal"  className='btn btn-danger btn-sm'  data-target={`#warningdialog-${cell}`} title="Delete"><i className="icofont icofont-delete-alt"></i></a> 
            &nbsp;
            <a href="#!"   className='btn btn-inverse btn-sm' onClick={()=>LoginAsParent(row)}  title="Login as this Parent"><i className="icofont icofont-login"></i></a> 
<WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(cell)} mID={cell} />
        </div>, editable: false},
        {dataField: 'familyName', text: 'Family Name',  sort: true,  validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Family Name is required'
              };
            }
            return true;
          } },
          {dataField: 'username', text: 'Username', editable: false},
        {dataField: 'studentList', text: 'Students', formatter:handleStudentName, editable: false}      
     ];

    const handleSelect = (option, action)=>{
        
        setFamily({...family, [action.name]: option});
        setErrors({...errors, [action.name]:''})
       
         }

         const fetchFamily =()=>{
            const fd = new FormData();
            fd.append("jwt", Token);
            let url = ServerUrl+'/fetch_controller/tbl_family'
            axios.post(url, fd, config).then(result=>setAllFamily(result.data))
        }

       

    

     const handleSubmit = event =>{
        event.preventDefault();

        let errors = {};   
		
    if(!family.familyName){
        errors.familyName ='Please enter family name';
    }
    if(!Array.isArray(family.student)){
        errors.student ='Please select student to make family';
       // window.scrollTo(0,0);
    }

     if(Array.isArray(family.student) && family.student.length <1){
        errors.student ='You need at least one or more students to make a family ';
    }   
   
    if(!family.username){
        errors.username ='Please enter username';
    }


    setErrors(errors);


    if(Object.keys(errors).length === 0){
        submit()
    } 
    
}
   

function submit(){  
    var options = family.student;
    var value = [];
    for (var i = 0, l = (options || []).length; i < l; i++) {
      value.push({code:options[i].value});
    }
       const fd = new FormData(); 
       fd.append("jwt", Token);
      fd.append("studentList", JSON.stringify(value, null, 2))
      fd.append("familyName", family.familyName)
      fd.append("username", family.username)
      fd.append("code", 'fm'+Math.random().toString(36).substr(2,9))
    setNotice({...notice, isLoading: true}) 

       let url = ServerUrl+'/save_controller/tbl_family'
	  axios.post(url, fd, config)
	  .then(response =>{
        if(response.data.type ==='success'){
            Alerts('Saved!', 'success', response.data.message)
                } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
            setFamily({...family, familyName: '', username:'', studentList:[], student:[]}); 
              fetchFamily()
              setNotice({...notice, 
                  isLoading: false
              })
          }) 
}

const ValidateExistence=()=>{
	
	if(family.username!==''){
		const fd = new FormData();
		fd.append("jwt", Token);
		fd.append('data', family.username);
		
		let url = ServerUrl + '/checkExistence/tbl_family/username'
		axios.post(url, fd, config).then(response=>{
			if(response.data===true){
				myRef.current.focus()
				setErrors({...errors, username:family.username+' already choosen'})
				setFamily({...family, username:''});
			}
	  })
	}
}

useEffect(()=>{
    //setIsAuthenticated(verifyRouter(location.pathname))
    fetchFamily();
},[]);


const BasicTable=(props)=>{

    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData(); 
        fd.append('newValue', newValue);
        fd.append('column', column.dataField);
        fd.append('ID', ID);
        fd.append("jwt", Token);
       let url = ServerUrl+'/updateCustom_controller/tbl_family'
      axios.post(url, fd, config)
       .then()
       .catch((error)=>alert(error)) 
       
     } 
  
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } entries</span>
      );
      
const { SearchBar } = Search;
     const options = {
         showTotal: false,
     paginationTotalRenderer: customTotal,
     sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
     { text: 'All', value: props.data.length }]
        
       };
  
     return <React.Fragment>  
    
             <ToolkitProvider search  className="table-responsive"
                 keyField='code' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           
                             <SearchBar
                             { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />
                             
                             <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                   mode: 'dbclick',
                                   blurToSave: true,
                                   autoSelectText: true,
                         beforeSaveCell: (oldValue, newValue, row, column) => {
                           if(oldValue !==newValue){
                           handleUpdate(column, newValue, row.ID);
                           
                          return props.fetchdata;
                         }
                         
                       }
                                 }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  </React.Fragment>
  }

  
    
        return ( <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Setup Family">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-home"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Students</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Define Family</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Create Family</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<fieldset>
            
            <div className="row">
									
										<section className="col-md-6">


										<div className="form-group">
						<label >Family Name<span style={{color:'red'}}>*</span></label><a href="/fees/family_fees" className="success pull-right">View Family Fees</a>
						<input type="text" role={'presentation'} autoComplete='off' name="familyName" value={family.familyName} onChange={handleChange} className={errors.familyName  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.familyName && errors.familyName}</span>	
			
												
											</div>
                                            </section>
                                            <section className="col-md-6">
                                            <div className="form-group">
						<label >Username<span style={{color:'red'}}>*</span></label>
						<input type="text" name="username" value={family.username} role={'presentation'} autoComplete='off'  onBlur={ValidateExistence} ref={myRef} onChange={handleChange} className={errors.username  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.username && errors.username}</span>	
			
												
											</div>

</section>

<section className="col-md-12">
							<div className="form-group">
						<label >Select Student<span style={{color:'red'}}>*</span></label>
						<Select isMulti options={
                            students.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.admissionNumber, studentName:list.studentName, co:list.fatherName, phone:list.father_mobile  }
                             })} 
                        getOptionLabel={option =>`(${option.label}) ${option.studentName} C/O  ${option.co}  ${option.phone}`}
onChange={handleSelect} className={errors.student ? 'form-control form-control-danger' : ''} name="student" value={family.student}  /> <span style={{color:'red'}}>{errors.student && errors.student}</span>
												
											</div>
											
										</section>
										</div>
										
										</fieldset>

	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-primary ">Save</button>
	
								</footer>				
							</div>
							</div>
							</div>
		
	
	

	<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Listing All Family</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12 table-responsive">
        
        {notice.isDataFetching ? <TableLoader />:
   <BasicTable data={allFamily}   columns={tableHeader}  />} 
</div>
	</div>
    </div>
</div>

</div>
	
   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Family) 