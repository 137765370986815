import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config} from '../component/include'
import Select from 'react-select'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import {formatGroupLabel} from '../component/globalFunction'
const TimetableDay =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isError : false,
        isSuccess: false, 
        isWarning: false,
        isInfo: false,
        isDataFetching: false,
        msgResponse:''
        
    }); 
    const [schoolClass, setSchoolClass]=useState([]);
    const [subject, setSubject]= useState([]) 
    const [tableList, setTableList] = useState([]);
    const [periodList, setPeriodList] = useState([]);
    const [staff, setStaff]= useState([])
   
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [CurrentSession, setCurrentSession] = useState('')
    const [timetable, setTimetable]=useState({
    schoolClass:[],
    status:'No',
    notification:'No',
    comment:'',
    sessionId:CurrentSession,
    day:[],
    fromDate:'',
    toDate:'',
    replaceAll:'Yes'
    })
    const [isSubmitting, setIsSubmitting] = useState(false);
   
    const  handleSession =()=>{
    
        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config)
        .then(result => {
            if(result.data.length!==0){
            setCurrentSession(result.data[0].startYear)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        } 

    const fetchSchoolClass =()=>{
        let url = ServerUrl + '/fetchColumnById_controller/tbl_school_class/schoolClass/1'
        axios.get(url).then(result=>setSchoolClass(result.data))

    }
    const fetchSubject =()=>{
        let Url = ServerUrl+'/fetch_controller/tbl_subject/'+Token;
        axios.get(Url).then(result=>setSubject(result.data))
    }
    const handleChangePeriod = (e, index)=>{
        const std =JSON.parse(JSON.stringify(periodList))   
            const value =e.target.value;
           std[index][e.target.name] = value;
        setPeriodList(std);
    }


    const getSubject =(ID)=>{
        const result = subject&&subject.filter((list)=>list.ID===ID);
        const answer = result.map((c)=>c.subjectName);
        return  answer
        
    }
    const getStaff=(ID)=>{
        const result = staff.filter((list)=>list.ID===ID);
       const answer = result.map((c)=>`${c.staffName.slice(0, 20)}` ); 
       
       return answer
    }
       const getDaysBetween =(startDate, endDate, day)=>{

        const start = new Date(startDate)
        const end =  new Date(endDate)
    
        var result = []
        start.setDate(start.getDate()+(Number(day)-start.getDay()+7)%7)
        while (start < end){
            result.push(new Date(+start).toISOString().slice(0,10))
            start.setDate(start.getDate()+7)
        }
        return JSON.stringify(result, null, 2)
    }
    const getDays=()=>{

        var fromDate =  new Date(timetable.fromDate);
        var toDate =  new Date(timetable.toDate);
        var diff_time = toDate.getTime()-fromDate.getTime()
        var differ = diff_time/(1000*3600*24);

        return differ
    }

    const handleSelect = (option, action)=>{
        setTimetable({...timetable, [action.name]: option,  fromDate:''});
        setErrors({...errors, [action.name]:'', periodList:''})            
        setPeriodList([])
    };

    const handleChange=(event)=>{
        let value = event.target.value;
        if(event.target.name==='status'||event.target.name==='replaceAll'){
             value = event.target.value ==='No'? 'Yes':'No';
            
        }
        setTimetable({...timetable, [event.target.name]:value});
        setErrors({...errors, [event.target.name]:''})
        if(event.target.name==='fromDate'){
            fetchTableList(value, 6)
        } 
    }
   
    const  fetchTableList =(tableDate, num)=>{  
        
    setNotice({...notice, 
        isLoading: true,
        isSuccess : false})
        const fd = new FormData();

        var value = [];
        var item;
        for (var i = 1; i <= Number(num); i++) {
          item ={ID:Math.random().toString(36).substr(2, 9), periodName:i, startTime:'', endTime:'', periodType:'', subject:'', staff:'', chapter:'', topic:''}
          value.push(item);
        }
        

   let sql = "Select ID, periodList from tbl_timetable where tableDate = '"+tableDate+"' and   sectionID ='"+timetable.schoolClass.value+"'"

       let url = ServerUrl+'/fetchBySql_controller/'+Token;
       fd.append("sql", sql)
       const config = {
           headers: { 'content-type': 'multipart/form-data'}
       }
       axios.post(url, fd, config).then(result =>{
        if(result.data.length===0){
            setPeriodList(value)
            }else{
                const res = result.data.map((tb)=>tb.periodList)
                setPeriodList(JSON.parse(res))
            }

           
    setNotice({...notice, 
        isLoading: false,
        isSuccess : false})
        }) 

    }  

    const  fetchStaff =()=>{  
        
        const fd = new FormData();
   let sql = "Select s.ID, s.staffName, p.position from tbl_staff s, tbl_staff_position p where s.position =p.ID order by p.position ASC"

       let url = ServerUrl+'/fetchBySql_controller/'+Token;
     
       fd.append("sql", sql)
       const config = {
           headers: { 'content-type': 'multipart/form-data'}
       }
       axios.post(url, fd, config).then(result =>setStaff(result.data)) 
   
    }    

   
    const handleSubmit = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(timetable));
        setIsSubmitting(true);
    }  
    const ValidateErrors=(values)=>{
        let errors = {};  
       
         
        if(Number(getDays()) < 0){
            errors.toDate = 'Invalid date range selected'
        }

        if(Number(getDays())===''){
            errors.toDate = 'One of the date typed is not valid'
        }
        if(timetable.day.length===0){
            errors.day ='Please select day';
        }
        if(values.status==='Yes'){
        if(!values.comment){
            errors.comment ='Please enter holiday reason';
        } 
        }
        if(values.status==='No'){
            if(values.schoolClass.length===0){
                errors.schoolClass ='Please select class';
            } 
            if(periodList.length===0){
                errors.periodList ='There must be at least one period define';
            } 
            }
            if(!values.fromDate){
                errors.fromDate ='Please select start date';
            }
            if(!values.toDate){
                errors.toDate ='Please select end date';
            }
        return errors;
        }


useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])

    const  submit=()=>{  
              
    setNotice({...notice, 
        isLoading: true,
        isSuccess : false})   

        const fd = new FormData(); 
                    let formData = timetable; 
                   for(let k in formData){
                       fd.append(k,formData[k]);
                   };  
              let url = ServerUrl+'/save_controller/tbl_timetable_byDay/'+Token;          
                if(timetable.status==='No'){   
                fd.append('classID',  timetable.schoolClass.classID)
                fd.append('sectionID',  timetable.schoolClass.value)
               
                }else{
         url = ServerUrl+'/save_controller/tbl_events_holidays_specific/'+Token;
                   
                    fd.append('title',  timetable.comment)
                    fd.append('eventType',  'Holidays')                    
        
                }
               
                fd.append('periodList',  JSON.stringify(periodList, null, 2))
                fd.append('dateList',  getDaysBetween(timetable.fromDate, timetable.toDate, timetable.day.value)) 
        const config = {
            headers: { 'content-type': 'multipart/form-data'}
        }
        axios.post(url, fd, config)
        .then(response =>{
            if (response.data.type ==='info'){
                setNotice({...notice, 
                    isLoading: false,
                    isInfo : true,
                    msgResponse: response.data.message
                })
            }else if (response.data.type ==='success'){
            setNotice({...notice, 
                isLoading: false,
                isSuccess : true,
                msgResponse: response.data.message
            })
            } else{
            setNotice({...notice, 
                isLoading: false,
                isError : true,
                msgResponse: response.data.message
            })
        }  
                
        })
        .catch((error)=>{
            setNotice({...notice, 
                isLoading: false,
                isError : true,
                msgResponse: error.message
            })  
        })
        setIsSubmitting(false)
        setTimetable({
            schoolClass:[],
    status:'No',
    notification:'No',
    comment:'',
    day:'',
    fromDate:'',
    toDate:'',
    replaceAll:'Yes'
        })
        setPeriodList([])
     }    

useEffect(()=>{
   // setIsAuthenticated(verifyRouter(location.pathname))
    fetchSchoolClass()
    fetchSubject()
    fetchStaff()    
    handleSession()
},[]);

useEffect(()=>{   
    window.scrollTo(0,0);
    if(notice.msgResponse.length >0){
        setTimeout(()=>{           
            setNotice({
                isLoading : false, 
                isError : false,
                isSuccess: false, 
                isWarning: false,
                isDataFetching: false,
                msgResponse:''
            })
        }, 5000);
    }

    }, [notice.msgResponse]);


        return (    <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Timetable">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-table"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Timetable</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Define Timetable</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

         
<div  className="row  ">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Assign Subject & Teacher</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximize full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            
            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
    
    <div className="row">
        <section className="col-md-6">
        <div className="form-group">
						<label >Select Class<span style={{color:'red'}}>*</span></label>
                        <Select options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID,isSection:'1', value: sec.ID, label: sec.sname}:{classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
                             })
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect} className={errors.schoolClass ? 'form-control form-control-danger' : ''}  name="schoolClass" value={timetable.schoolClass}   /> 
								
<span style={{color:'red'}}>{errors.schoolClass && errors.schoolClass}</span>				
											</div>
        </section>
    </div>
    
        <div className="row">
    
      <section className="col-md-6">	
   
                                            <div className="form-group">
<label >From Date</label>
<input type="date" className={errors.fromDate ? 'form-control form-control-danger' : 'form-control'} name="fromDate"  onChange={handleChange} value={timetable.fromDate} />
<span style={{color:'red'}}>{errors.fromDate && errors.fromDate}</span>
   </div>

</section>

<section className="col-md-6">
      <div className="form-group">
<label >To Date</label>
<input type="date" className={errors.toDate ? 'form-control form-control-danger' : 'form-control'} name="toDate"  onChange={handleChange} value={timetable.toDate} />
<span style={{color:'red'}}>{errors.toDate && errors.toDate}</span>
   </div>
</section>


				</div>
           

 </div> </div> 
							
</div>

</div>   



<div style={{display:timetable.status==='Yes'?'none':'block'}} className="card z-depth-0" >
		<div style={{display:periodList.length===0?'none':'block'}} className="card-block">
        <div className="row">
       
        <div className="table-responsive">
        <table className="table">
            <thead>
                <tr>
                    <th>Period Name</th>
                    <th>Monday</th>
                    <th>Tuesday</th>
                    <th>Wednessday</th>  
                    <th>Thursday</th>
                    <th>Friday</th> 
                    <th>Saturday</th>
                </tr>
            </thead>
            <tbody>
                {periodList&&periodList.map((pl, id)=>
            <tr key={id}>
                
                    <td> <span>{pl.periodName}</span></td>
                    <td>
                    <select onChange={(e)=>handleChangePeriod(e, id)}  name="subject"  value={pl.subject} className="form-control">

                    <option value="">Select</option>
                   { subject&&subject.map((list, idx)=> {
                               return  <option key={idx} value={list.ID} >{list.subjectName} ({list.abbreviation})</option> })}
                        </select>
                    <br/>
                        <select onChange={(e)=>handleChangePeriod(e, id)}  name="staff"  value={pl.staff} className="form-control">

<option value="">Select</option>
{ staff&&staff.map((list, idx)=> {
           return  <option key={idx} value={list.ID} >({list.position}) {list.staffName}</option> })}
    </select>
                    </td>
                    <td>                    </td>
                   
                </tr>)}
               
            </tbody> 
        </table>
<hr/>
        </div>



        <div className="table-responsive">
        <table className="table">
            <thead>
                <tr>
                    <th>Period Name</th>
                    <th>Monday</th>
                    <th>Tuesday</th>
                    <th>Wednessday</th>  
                    <th>Thursday</th>
                    <th>Friday</th> 
                    <th>Saturday</th>
                </tr>
            </thead>
            <tbody>
                {periodList&&periodList.map((pl, id)=>
            <tr key={id}>
                
                    <td> <span>{pl.periodName}</span></td>
                <td><span>{getSubject(pl.subject)}</span><div>{getStaff(pl.staff)}</div></td>
                   
                    
                    <td>        </td>
                   
                </tr>)}
               
            </tbody> 
        </table>

        </div>

        </div>
        </div>
</div>


     
<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button"  className="btn btn-inverse "><i className="fa fa-refresh"></i> Back</button>&nbsp;&nbsp;
	<button type="button" id="submit" onClick={handleSubmit}  className="btn btn-primary "><i className="fa fa-save"></i> Save</button>
								</footer>		</section>		
							</div>
     
     </div>  </div>
							
                            </div>
                            
                            </div>

   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(TimetableDay) 