import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl, Token, config} from '../component/include'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {Banner} from '../component/images'
import Select from 'react-select'

 const Settings =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });

    
const [activities, setActivities]=useState([])
const [isSubmitting, setIsSubmitting] = useState(false);
const [image, setImage] = useState({
    image:''
})
const [master, setMaster] = useState({
    activity:'',
    activ:[],
    image:''
})
const [errors, setErrors] = useState({});

let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
    const fetchActivities =()=>{
        
const fd = new FormData();
fd.append('jwt', Token); 
        let url = ServerUrl+'/fetch_controller/tbl_activities/'+Token;
        axios.post(url, fd, config).then(result=>setActivities(result.data))
    }
    
  
    const handleSelect = (option, action)=>{
        setMaster({...master, [action.name]: option});
        setErrors({...errors, [action.name]:''})            
    };
    const handleSubmit=()=>{

    }

    const  handleFile = function(fieldName){
        return function(newValue){  
          
       readURL(newValue);
       }
       
    } 

    const readURL = (input)=>{
       
        let displayMessage = '';
        const PreviewImage = input.target.name;
          //  let newImage = "viewPassport";
            var fileName = document.getElementById(PreviewImage).value;

        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
            //TO DO    
            
             var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
        if (FileSize > 0.4) {
            displayMessage = input.target.name +': File uploaded is more than maximum size Allow. it exceeds 400 Kb';
           document.getElementById(input.target.name).value = '';
          
           setMaster({...master, image:''})
        } else {
            if (input.target.files && input.target.files[0]) { //Check if input has files.
                setMaster({...master, image:input.target.files[0]})
                
            }
        }
        }else{

            displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 400kb'
            document.getElementById(input.target.name).value = '';
           
            setMaster({...master, image:''})
        }   
        
        if(displayMessage.length > 0){
            Alerts('Error!', 'danger', displayMessage)
          }
        
    } 


    const handleImage = event =>{
		event.preventDefault();
		setErrors(ValidateInput(master));
		setIsSubmitting(true);
    }

    const ValidateInput=(values)=>{
        let errors = {};     
        if(values.activ.length===0){
            errors.activ ='Please select the activity';
        }
        
        if(!values.image){
            errors.image ='Please select image';
        }
           
       return errors; 
       }
       
useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
    },[errors])

    const submit=()=>{
        setNotice({...notice, 
            isLoading: true})         
           
        const fd = new FormData();
        fd.append('jwt', Token); 
            fd.append('activityID', master.activ.value);
            fd.append('image', master.image);
            
             let url = ServerUrl+'/update_controller/tbl_activities'
            axios.post(url, fd, config)
            .then(response =>{
                if(response.data.type ==='info'){
                    Alerts('Info!', 'info', response.data.message)
                        } else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data))
                        }   
                })
                .catch((error)=>{
                  Alerts('Error!', 'danger', error.message)
                }).finally(()=>{
                    fetchActivities()
            setMaster({...master, activ:[], image:''});
            document.getElementById('image').value =''
            setNotice({...notice, 
                isLoading: false})
        })
    }



function submitActivities(event){ 
    event.preventDefault();
    if(!master.activity){
        setErrors({...errors, activity:'Activity field is Required'})
    }else{
    
    setNotice({...notice, 
        isLoading: true})         
        
const fd = new FormData();
fd.append('jwt', Token);  
        fd.append('activity', master.activity);
        
         let url = ServerUrl+'/save_controller/tbl_activities'
        axios.post(url, fd, config)
        .then(response =>{
            if(response.data.type ==='success'){
                Alerts('Saved!', 'success', response.data.message)
                    }else if(response.data.type ==='info'){
                        Alerts('Info!', 'info', response.data.message)
                            } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
                    }   
            })
            .catch((error)=>{
              Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                fetchActivities()
        setMaster({...master, activity : ''});
        setNotice({...notice, 
            isLoading: false})
    }
            )}
}


    const handleChange = event =>{
        const {name, value} = event.target
       setMaster({...master, [name] : value });
       setErrors({...errors, [name]:''})
    }

   


	

    const tableActivitiesHeader = [
        {dataField: 'imageUrl', text: 'Thumbnail', formatter:(cell, row)=><img id={cell} className="image" onError={(e)=>{e.target.onerror = null; e.target.src=Banner}} src={`${ImagesUrl}/activity/${cell}`}  style={{display:'block',height:'80px',width:'80px'}} title="Activity"  alt={row.activity} />, editable: false},
        {dataField: 'activity', text: 'Activity',  sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'activity field is required'
              };
            }
            return true;
          } }     
     ];
      




     
 const BasicTable=(props)=>{

   

    const handleUpdate=(column, newValue, ID)=>{       
     
const fd = new FormData();
fd.append('jwt', Token); 
         fd.append('columnField', newValue);
         fd.append('column', column.dataField);
         fd.append('ID', ID);
       
        let url = ServerUrl+'/updateById_controller/tbl_activities'
       axios.post(url, fd, config)
       //.then(result => console.log(result.data))
       .then()
       .catch((error)=>console.log(error)) 
       
     } 
     const { SearchBar } = Search;
 
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
      );
     const options = {
         showTotal: true,
     paginationTotalRenderer: customTotal,
     sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
     { text: 'All', value: props.data.length }]
        
       };
  
     return <ToolkitProvider search columnToggle 
                 keyField='ID' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                             &nbsp;
                           
                         

                             </div>
                             
                      
             
                             <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                   mode: 'dbclick',
                                   blurToSave: true,
                                   autoSelectText: true,
                         beforeSaveCell: (oldValue, newValue, row, column) => {
                           if(oldValue !==newValue){
                           handleUpdate(column, newValue, row.ID);
                           
                          return '';
                         }
                         
                       }
                                 }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  }

  
  useEffect(()=>{
    //setIsAuthenticated(verifyRouter(location.pathname))
   fetchActivities()
},[]);

        return (    <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 

        <PageHeader title="Timetable">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-table"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Timetable</a> </li>       
                    <li className="breadcrumb-item"><a href="#!">Settings</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Settings</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
                    
	   <div className="row setup-content" id="Activities">
		    
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12">

       <div className="form-group">
           <div className="input-group">
                       <input type="text" placeholder='Enter Activity' name='activity' onChange={handleChange} value={master.activity } className={errors.activity ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
                      <button className="btn btn-inverse" type="button" onClick={submitActivities}>Add</button>
              </div>
            <span style={{color:'red'}}>{errors.activity && errors.activity}</span>
           </div>		
      </section>
          </div>
                               </div>
                               
       </div>	




       <div className="card z-depth-0">

<div className="card-block">
    <div className="row">
                        
    <section className="col-md-6">
    <div className="form-group">
                      <label >Select Type<span style={{color:'red'}}>*</span></label>
                      <Select  options={
                          activities&&activities.map((list, idx)=> {
                             return {key:idx, value: list.ID, label: list.activity}
                           })
                      } 
                     
onChange={handleSelect} className={errors.activ ? 'form-control form-control-danger' : ''}  name="activ" value={master.activ}   /> 
                              
<span style={{color:'red'}}>{errors.activ && errors.activ}</span>				
    
                                          </div>
</section>

<section className="col-md-6">
                     <div className="form-group">
                           <label >Select Image</label><br/>
                  <input id="image"  onChange={handleFile('image')} className={errors.activ ? 'form-control form-control-danger' : 'form-control'} name="image" type="file" accept="image/*" /> <span style={{color:'red'}}>{errors.image && errors.image}</span>
          <div className="note" style={{color:'red'}}>
          <strong>Size 400 kb,</strong>gif|jpg|png|jpeg Only Allow
      </div>	   	
      <button className="btn btn-inverse" type="button" onClick={handleImage}>Save</button>
              </div>
   </section>
       </div>
                            </div>
                            
    </div>	



             <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12 table-responsive">
       {notice.isDataFetching ? <TableLoader />:
   <BasicTable data={activities}   columns={tableActivitiesHeader}  />} 
       </section>
               </div>
               </div>
       
       </div>	</div>
       
       </div>	
			
       </div>
    
</div>

                </div>
                
                
                
                </div>
               
                </div>
                </div> </div>
                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Settings) 