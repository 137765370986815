import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import PageHeader from '../component/pageheader'
import { Alerts } from '../component/notify'
import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import {ServerUrl, Token, config} from '../component/include'
import axios from 'axios'
import {  getClassName, getMonthByDate} from '../component/globalFunction'
import {Bar, Line} from 'react-chartjs-2'


const Chart =()=> {
    
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
const [CurrentSession, setCurrentSession] = useState('')
    const [allSession, setAllSession]= useState([])  
    const [year, setYear]= useState(new Date().getFullYear()) 
    const [studentClass, setStudentClass]= useState([])   

const [application, setApplication] = useState([])
var cName = "Student Statistics -"+CurrentSession
let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
const [statistics, setStatistics] = useState({
    student:0,
    terminated:0,
    graduated:0,
    studying:0
});



    const fetchSession =()=>{
      
        const fd = new FormData();
        var sql = "select regSession as label, count(*) as data, sum(case when termination='Yes'  then 1 else 0 end) AS terminate, sum(case when termination <> 'Yes' and admissionStatus='Graduated'  then 1 else 0 end) AS graduated from tbl_students  GROUP BY regSession order by label "
     
            let url = ServerUrl+'/fetchBySql_controller'
            fd.append("jwt", Token)
            fd.append("sql", sql)
            axios.post(url, fd, config).then(result =>{
                
                setAllSession(result.data)
                
            }) 
    } 

   

      
        


     const fetchRegistration =()=>{
var sql = "select dateRegister as label,  count(*) as data from tbl_students where Year(dateRegister) = '"+year+"'  GROUP BY DATE_FORMAT(dateRegister, '%m.%Y') order by label "

         const fd = new FormData();
         fd.append("sql", sql);
         fd.append('jwt', Token)
         let url = ServerUrl+'/fetchBySql_controller';
         axios.post(url, fd, config).then(result=>{ 
           
             let responseData = []
               for (var i = 0; i< result.data.length;  i++) {
                 responseData.push(
                     {  label:String(getMonthByDate(result.data[i].label)),
                         data:result.data[i].data
                     });
                     } 

            setApplication(responseData)
         })
     } 
    

          const data = {
            labels: application.map((data)=>data.label),
            datasets: [
              {
                label: 'Total Registration',
                data: application.map((data)=>data.data),
                fill: true,
                backgroundColor: 'rgba(75,192,192,1)',
                borderColor: 'rgba(0,0,0,1)',
                yAxisID: 'y-axis-1',
              }
            ],
          }; 
          
    
          const options = {
            scales: {
              yAxes: [
                {
                  type: 'linear',
                  display: true,
                  position: 'left',
                  id: 'y-axis-1',
                },
                {
                  type: 'linear',
                  display: true,
                  position: 'right',
                  id: 'y-axis-2',
                  gridLines: {
                    drawOnArea: false,
                  },
                },
              ],
            },
          } 
          
         

        
          const fetchStatistics = async ()=>{
      
    

            let sqls = "Select  (select count(*) from tbl_students ) as student, (select count(*) from tbl_students where admissionStatus ='Studying' )  as studying, (select count(*) from tbl_students where admissionStatus ='Terminated' )  as terminate, (select count(*) from tbl_students where admissionStatus ='Graduated' )  as graduated  "
            
             const fds = new FormData();
             fds.append("sql", sqls);
             fds.append('jwt', Token)
             let urls = ServerUrl+'/fetchBySql_controller';
             axios.post(urls, fds, config).then(result=>{ 
                
                setStatistics({
                  student:result.data[0].student,
                    studying:result.data[0].studying,
                    terminated:result.data[0].terminate,
                    graduated :result.data[0].graduated
                })
             })
         } 
         


           const  handleSession =()=>{

            var sql ="Select  startYear from tbl_school_session where status ='Active'"
            const fd = new FormData();
            fd.append("sql", sql);
            fd.append("jwt", Token);
            let url = ServerUrl+'/fetchBySql_controller';
            axios.post(url, fd, config)
            .then(result => {
                if(result.data.length!==0){
                setCurrentSession(result.data[0].startYear)
            }
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', JSON.stringify(error.message)) 
            })
            } 

            
            const dataBar = {
                labels: allSession.map((data)=>data.label),
                datasets: [
                  {
                    label: '# of Admission',
                    data: allSession.map((data)=>data.data),
                    backgroundColor: 'rgb(49, 168, 13)',
                    
                  },
                  {
                    label: '# of Graduated',
                    data: allSession.map((data)=>data.graduated),
                    backgroundColor: 'rgb(10, 76, 181)',
                  },
                  {
                    label: '# of Termination',
                    data:allSession.map((data)=>data.terminate),
                    backgroundColor: 'rgb(209, 20, 93)',
                  },
                ],
              }
              
              const optionsBar = {
                scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                        },
                      },
                    ],
                  },
              }; 
              


              const sortResult =(data, section, classes)=>{
                let result = []
                for(var k in section){

                for(var i in data){
                    if(data[i].sectionID===section[k].sectionID){
                    result.push({label:String(getClassName(section[k].classID,section[k].sectionID, classes)), Male:data[i].Male, Female:data[i].Female})
                  }
                }
                  }
      return result;
              }

const getAllSection =(school)=>{
  let section = []
  var newClass = [...school];

   for (var i in newClass){
          if(newClass[i].section[0].sname === ''){   
          section.push({classID:newClass[i].ID, sectionID:newClass[i].ID})
          } else{ 
              for(var k in newClass[i].section){
                  section.push({classID:newClass[i].ID, sectionID:newClass[i].section[k].ID})
                  }
      } 
  } 

  return section
}




const  handleFetchClass =()=>{

  const fd = new FormData();
  fd.append('jwt', Token)
     let url = ServerUrl + '/fetchField/tbl_school_class/schoolClass/ID/1';
     axios.post(url, fd, config)
     .then(result =>{

  let sqls = "SELECT classID, sectionID, sum(case when gender = 'Male'   then 1 else 0 end) AS 'Male', sum(case when gender = 'Female'  then 1 else 0 end) AS 'Female'  from tbl_students  where  admissionStatus='Studying' GROUP by sectionID order by sectionID ASC"
            
             const fds = new FormData();
             fds.append("sql", sqls);
             fds.append('jwt', Token)
             let urls = ServerUrl+'/fetchBySql_controller';
             axios.post(urls, fds, config).then(response=>{
                    let sec = getAllSection(result.data)
                  var final = sortResult(response.data, sec, result.data)
             setStudentClass(final) 
          })
        })
     .catch((error)=>Alerts('Error!', 'danger', JSON.stringify(error.message))) 
         
 }



               const dataStark = {
                labels: studentClass.map((data)=>data.label),
                datasets: [
                  {
                    label: '# of Female',
                    data: studentClass.map((data)=>data.Female),
                    backgroundColor: 'rgb(25, 43, 234)',
                  },
                  
                  {
                    label: '# of Male',
                    data: studentClass.map((data)=>data.Male),
                    backgroundColor: 'rgb(12, 178, 12)',
                  }
                ],
              };
              
              
              const optionsStark = {
                scales: {
                  yAxes: [
                    {
                      stacked: true,
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                  xAxes: [
                    {
                      stacked: true,
                    },
                  ],
                },
              };
              
              const handleChange =(event)=>{
                let {name, value} = event.target;
                setYear(value);
              }

   useEffect(()=>{
    //setIsAuthenticated(verifyRouter(location.pathname))
    handleFetchClass()
     fetchRegistration()
     handleSession()
     fetchStatistics()
     fetchSession()
},[]);

        return (   <>
          <div  className="main-body">
          {isAuthenticated?   <div className="page-wrapper">
                        {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title='Chart'>
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-dashboard"></i> </a>
                            </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">






<div className="row">




<div className="col-xl-3 col-md-6">
    <div className="card bg-c-yellow text-white">
        <div className="card-block">
            <div className="row align-items-center">
                <div className="col">
                    <p className="m-b-5">Total Students</p>
                    <h4 className="m-b-0">{statistics.student}</h4>
                </div>
                <div className="col col-auto text-right">
                    <i className="fa fa-graduation-cap f-50 text-c-yellow"></i>
                </div>
            </div>
        </div>
    </div>
</div>


<div className="col-xl-3 col-md-6">
                <div className="card bg-c-blue text-white">
                    <div className="card-block">
                        <div className="row align-items-center">
                            <div className="col">
                            <p className="m-b-5">Total Graduated</p>
                        <h4 className="m-b-0">{statistics.graduated}</h4>
                            </div>
                            <div className="col col-auto text-right">
                                <i className="fa fa-users f-50 text-c-blue"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


<div className="col-xl-3 col-md-6">
        <div className="card bg-c-green text-white">
            <div className="card-block">
                <div className="row align-items-center">
                    <div className="col">
                        <p className="m-b-5">Current Students</p>
                        <h4 className="m-b-0">{statistics.studying}</h4>
                    </div>
                    <div className="col col-auto text-right">
                        <i className=" fa fa-users f-50 text-c-green"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

<div className="col-xl-3 col-md-6">
            <div className="card bg-c-pink text-white">
                <div className="card-block">
                    <div className="row align-items-center">
                        <div className="col">
                            <p className="m-b-5">Total Terminated</p>
                            <h4 className="m-b-0">{statistics.terminated}</h4>
                        </div>
                        <div className="col col-auto text-right">
                            <i className="fa fa-users f-50 text-c-pink"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>






      </div>

    <br/>


    <div className="row">
        <div className="col-12">
          <div className="card">
		   <div className="card-header">
            <strong> CURRENT  STUDENTS</strong>
				</div>
             <div className="card-body">

              <Bar
          data={dataStark}
          options={optionsStark}
        />  
             </div>
          </div>
        </div></div>





<div className="row">
      <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
             <div className="card-body">
             <div className="col-sm-6 pull-left ">
             <strong>
     Student Registration History </strong>
               </div>
             <div className="col-sm-3 pull-right ">
							<div className="input-group">
						<input type="number" autoComplete="off" onChange={handleChange} placeholder="enter Reg. year "   name="year" value={year} className='form-control ' />
							
							<span className="input-group-addon" onClick={fetchRegistration} id="basic-addon"><i className="fa fa-search"></i></span>
							</div>
							</div>
              <Line
          data={data}
          options={options}
          
        /> 

             </div>
          </div>
        </div></div> 


        <div className="row">
        <div className="col-12">
          <div className="card">
		   <div className="card-header">
            <strong> Admission History</strong>
				</div>
             <div className="card-body">

              <Bar
          data={dataBar}
          options={optionsBar}
        />  
             </div>
          </div>
        </div></div> 



   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Chart) 