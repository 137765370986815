import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts,  WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import {Currency, FormatNumber, ServerUrl,  Token, config} from '../component/include'
import Select from 'react-select'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import BootstrapTable from 'react-bootstrap-table-next';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { userToken } from '../component/authentication'


const BookList =()=> {
    const [errors, setErrors] = useState({});
    
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    })
  
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true); 
    
const [division, setDivision]=useState([])
const [binding, setBinding]=useState([])
const [category, setCategory]=useState([])
const [list, setList] = useState({
        book:[],
        accessNo:[],
    })
    
    const [books, setBooks]= useState([])
    const [bookList, setBookList]= useState([])
    const [bookAccess, setBookAccess]= useState([])
    const [bookDetails, setBookDetails]= useState([])

const fetchBooks =()=>{
    const fd = new FormData();
    fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_books'
        axios.post(url, fd, config).then(result=>setBooks(result.data.sort((a, b) =>(a.ID > b.ID) ? 1 : -1)))
    }
   
    const fetchCategory =()=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_library_settings/categories/ID/1'
        axios.post(url, fd, config)
        .then(result =>setCategory(result.data))
    }

    const fetchBinding =()=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_library_settings/bindingNature/ID/1'
        axios.post(url, fd, config)
        .then(result =>setBinding(result.data))
    }
    const fetchDivision =()=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_library_settings/division/ID/1'
        axios.post(url, fd, config)
        .then(result =>setDivision(result.data))
    }

    const handleSelect = (option, action)=>{
        setList({...list, [action.name]: option, accessNo:[]});
        setErrors({...errors, [action.name]:''})
        const bookDetails = books.filter(item=>item.code===option.value)[0].bookList 
        setBookAccess(JSON.parse(bookDetails))
         }


         const getBookName =(code, field)=>{
            const result = books&&books.filter(lst=>lst.code===code);
            const answer = result.map(c=>c[field]);
            return  answer
        }
        const handleSelectAccess = (option, action)=>{
            setList({...list, [action.name]: option});
             }

             const fetchBookList =()=>{
             
                if(list.book.length===0){
                    setErrors({...errors, book:'Please select book'});
                }else{
                
                if(list.accessNo.length!==0){
                    setBookList(bookAccess.filter(item=>item.code===list.accessNo.code).sort((a, b) =>(a.ID > b.ID) ? 1 : -1)) 
                }else{
                    setBookList(bookAccess.sort((a, b) =>(a.ID > b.ID) ? 1 : -1))
                }
            }
                }

                const handleEditBook=(code)=>{
                    let modal =   document.getElementById('editBookModal');
                    modal.click();
                    setBookDetails(bookList.filter(item=>item.code===code))
                    
                }

                const handleDelete = (code)=>{  
                       let close =   document.getElementById('btnWarningDialog-'+code)
                          close.click();
                          const fd = new FormData();
                          fd.append('jwt', Token);
                
                    const newBook = bookList.filter(item=>item.code!==code)
                
                    fd.append('bookCode', list.book.value);
                    fd.append('bookList', JSON.stringify(newBook, null, 2));
                    fd.append('total', newBook.length);
 
 setNotice({...notice, 
     isLoading: true}) 
      let url = ServerUrl+'/update_controller/tbl_books_list'
     axios.post(url, fd, config)
     .then(response =>{
           if (response.data.type ==='info'){
               Alerts('Update!', 'default', response.data.message)
           } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
       }  
                  
          })
          .catch((error)=>{
           Alerts('Error!', 'danger', JSON.stringify(error.message)) 
       }).finally(()=>{
           fetchBooks()
        setNotice({...notice, 
            isLoading: false}) 
       })
       setBookList(newBook.sort((a, b) =>(a.ID > b.ID) ? 1 : -1))
    }


    const handleChange = (e)=>{
        const bk =JSON.parse(JSON.stringify(bookDetails))   
            const value =e.target.value;
		   bk[0][e.target.name] = value;
           setBookDetails(bk);
           
     setErrors({...errors, [e.target.name]:''});
    }

    const handleSelectChange = (option, action)=>{
        const bk =JSON.parse(JSON.stringify(bookDetails))   
            const value =option.value;
		  bk[0][action.name] = value;
        setBookDetails(bk);
    };

    const getDivision =(code)=>{
        const result = division&&division.filter(lst=>lst.code===code);
        const answer = result.map((c)=>c.divisionName);
        return  answer
    }
    const getCategory =(code)=>{
        const result = category&&category.filter(lst=>lst.code===code);
        const answer = result.map((c)=>c.categoryName);
        return  answer
    }
    const getBinding =(code)=>{
        const result = binding&&binding.filter(lst=>lst.code===code);
        const answer = result.map((c)=>c.bindingName);
        return  answer
    }

   
  
    



    const  submitUpdate=(code)=>{  

        const fd = new FormData();
  fd.append('jwt', Token);

          const listBook = bookList.filter(item=>item.code!==code)   
          const final = listBook.concat(bookDetails)
          

    fd.append('bookCode', list.book.value);
    fd.append('bookList', JSON.stringify(final, null, 2));
    fd.append('total', final.length);
 
 setNotice({...notice, 
     isLoading: true}) 
      let url = ServerUrl+'/update_controller/tbl_books_list'
     axios.post(url, fd, config)
     .then(response =>{
           if (response.data.type ==='info'){
               Alerts('Update!', 'default', response.data.message)
           } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
       }  
                  
          })
          .catch((error)=>{
           Alerts('Error!', 'danger', JSON.stringify(error.message)) 
       }).finally(()=>{
        let close =   document.getElementById('btnCloseModal')
        close.click();
        setNotice({...notice, 
            isLoading: false}) 
            setBookList(final.sort((a, b) =>(a.ID > b.ID) ? 1 : -1))
            fetchBooks()
       })

        } 


    

	
const tableHeader = [
{dataField: 'code', text: 'Action', formatter: (cell, row)=><div>
{/* <button type='button' className='btn-danger' data-toggle="modal"   data-target={`#warningdialog-${row.code}`}     title="Delete"><i className=" fa fa-trash"></i></button>&nbsp; */}
   
<button type='button' className='btn-warning'   onClick={()=>handleEditBook(row.code)}   title="Edit"> <i className="icofont icofont-edit-alt"></i></button>
  
   <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code)} mID={row.code} /> 


</div>},
    {dataField: 'accessionNumber',  text: 'Accession No.',   sort: true},
    {dataField: 'isbn',  text: 'ISBN',  sort: true},
    {dataField: 'bookDate', text: 'Date',  sort: true, editor: {
        type: Type.DATE
      } },
    {dataField: 'placePublication', text: 'Publication Place', sort: true},      
    {dataField: 'datePublication', text: 'Publication Date', sort: true}, 
    {dataField: 'sellerDonor',  text: 'Saler / Doner',  sort: true},
    {dataField: 'publishPrice',  text: 'Publishing Price',  sort: true, formatter:(cell)=>Currency+FormatNumber(cell)}
 ];
 

 const BookTable=(props)=>{
   
  const expandRow = {
    renderer: row => (
       
          <div className="table-responsive">
    <table className="table">
      <tbody >
        <tr><td><b>Purchase Price:</b></td><td>{Currency+FormatNumber(row.purchasePrice)}</td></tr>
        <tr><td><b>Cash Memo / Bill No:</b></td><td>{row.billNo}</td></tr>
        <tr><td><b>No Of Pages:</b></td><td>{row.noPages}</td></tr>
        <tr><td><b>Call No:</b></td><td>{row.callNo}</td></tr>
        <tr><td><b>Volume No:</b></td><td>{row.volumeNo}</td></tr>
        <tr><td><b>Nature of Binding:</b></td><td>{getBinding(row.bindingNature)}</td></tr>
        <tr><td><b>Division:</b></td><td>{getDivision(row.division)}</td></tr>
        <tr><td><b>Book Rack:</b></td><td>{row.bookRack}</td></tr>
        <tr><td><b>Row:</b></td><td>{row.bookRow}</td></tr>
        <tr><td><b>Category:</b></td><td>{getCategory(row.category)}</td></tr>
        <tr><td><b>Type:</b></td><td>{row.bookType}</td></tr>
        <tr><td><b>Remarks:</b></td><td>{row.remarks}</td></tr>
        <tr><td><b>Status:</b></td><td>{row.status}</td></tr>
      </tbody>
      </table> 
    </div>),
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnPosition: 'left',
    onlyOneExpanding: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <button type='button' className='btn-inverse' title="Close All"><i className=" fa fa-minus-square"></i></button>
        }
        return <button type='button' className='btn-primary' title="View All"><i className=" fa fa-plus-square"></i></button>
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return <button type='button' className='btn-inverse' title="Close"><i className=" fa fa-minus-square"></i></button>
        }
        return <button type='button' className='btn-primary'  title="View"><i className=" fa fa-plus-square"></i></button>
      }
    
  } 



    const CustomToggleList = ({
        columns,
        onColumnToggle,
        toggles
      }) => ( 
      <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
          {
            columns
              .map(column => ({
                ...column,
                toggle: toggles[column.dataField]
              }))
              .map(column => (
                
                <button
                  type="button"
                  key={ column.dataField }
                  className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                  data-toggle="button"
                  aria-pressed={ column.toggle ? 'true' : 'false' }
                  onClick={ () => onColumnToggle(column.dataField) }
                >
                  { column.text }
                </button>
              ))
          }
        </div>
        
      );

     const { SearchBar } = Search;
 
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
      );
     const options = {
         showTotal: true,
     paginationTotalRenderer: customTotal,
     sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
     { text: 'All', value: props.data.length }]
        
       };
  
     return <ToolkitProvider search columnToggle 
                 keyField='code' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                             &nbsp;
                           
                             <div className="btn-group dropdown-split-success">
  
  <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  <i className="fa fa-search-plus"></i> Columns
  </button>
  
  <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'250px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
    <CustomToggleList { ...props.columnToggleProps } />
  </div>
  </div>

  <div className="dt-buttons btn-group pull-right"> 
  <a href="/library/manage_books" className="btn btn-outline-success waves-effect waves-light" >
        <i className="fa fa-plus"></i>Add New </a>	

<div className="btn-group dropdown-split-primary ">
    <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i className="fa fa-download"></i>Export
    </button>
    <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <form method='POST' action={ServerUrl+'/printout/book_list.php?token='+userToken} target='_blank'>
            <input type='hidden' name='bookList' value ={JSON.stringify(bookList, null, 2)} />
            <input type='hidden' name='bookTitle' value ={list.book.label} />
            <input type='hidden' name='author' value ={list.book.aut} />
            <input type='hidden' name='jwtToken' value ={Token} />
            <input type='hidden' name='publisher' value ={list.book.publisher} />
            <button type='submit' className="dropdown-item waves-effect waves-light" >Print Preview</button>
            </form>
    </div>
</div> 
</div> 
  
                             </div>
                             
                      
             
                             <BootstrapTable
                              { ...props.baseProps }
                              striped
                          hover
                          condensed
                          noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                      pagination={paginationFactory(options) }
                      expandRow={expandRow }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  }

useEffect(()=>{
    
    setIsAuthenticated(verifyRouter(location.pathname))
    fetchBooks();
    fetchCategory();
    fetchDivision();
    fetchBinding();
    
},[]);


        return (  <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Library Books">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-book"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Library</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Manage Books</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
							

	<div className="col-sm-12">




{/* Modal start here */}
<a href="#!" id="editBookModal"  data-toggle="modal"   data-target={`#book1`} > </a>
{bookDetails&&bookDetails.map((bk, index)=> 
<div key={index} className="modal fade " id={`book1`} tabIndex="-1" role="dialog">
<div className="modal-dialog modal-lg " role="document" >
            <div className="modal-content background-success">
                <div className="modal-header">
                    <h4 className="modal-title">Update  Book Details</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">×</span>
</button>
                </div>
                <div className="modal-body">
                  
               <div className="row" >
									
                        <section className="col-md-4">
                        <div className="form-group">
						<label >Accession Number</label>
		<input type="text" name="accessionNumber" min="1" placeHolder="Accession Number" value={bk.accessionNumber} onChange={handleChange} className='form-control' /> 	
			</div>


            <div className="form-group">
						<label >Place Of Publication</label>
		<input type="text" name="placePublication"  placeHolder="Place Of Publication" value={bk.placePublication} onChange={handleChange} className='form-control' /> 	
			</div>

            <div className="form-group">
						<label >Publishing Price</label>
		<input type="number" name="publishPrice"  placeHolder="Publishing Price" value={bk.publishPrice} onChange={handleChange} className='form-control' /> 	
			</div>
            
            <div className="form-group">
						<label >No Of Pages</label>
		<input type="number" name="noPages"  placeHolder="No Of Pages" value={bk.noPages} onChange={handleChange} className='form-control' /> 	
			</div>

            <div className="form-group">
						<label >Nature Of Binding</label>
                        <Select options={
                            binding&&binding.map((lis, ix)=> {
                               return {key:ix, value: lis.code, label: lis.bindingName }
                             })} 
        onChange={handleSelectChange}  name="bindingNature" value={{value:bk.bindingNature, label:getBinding(bk.bindingNature)}}   />	
			</div>

            <div className="form-group">
						<label >Book Rack</label>
		<input type="text" name="bookRack"  placeHolder="Book Rack" value={bk.bookRack} onChange={handleChange} className='form-control' /> 	

			</div>

            
                            </section>
                            <section className="col-md-4">
                            <div className="form-group">
						<label >ISBN No</label>
		<input type="text" name="isbn" placeHolder="ISBN No" value={bk.isbn} onChange={handleChange} className='form-control' /> 	
			</div>


            <div className="form-group">
						<label >Date Of Publication</label>
		<input type="date" name="datePublication"  placeHolder="Date Of Publication" value={bk.datePublication} onChange={handleChange} className='form-control' /> 	
			</div>

            <div className="form-group">
						<label >Purchase Price</label>
		<input type="nuber" name="purchasePrice"  placeHolder="Purchase Price" value={bk.purchasePrice} onChange={handleChange} className='form-control' /> 	
			</div>
            
            <div className="form-group">
						<label >Volume No</label>
		<input type="text" name="volumeNo"  placeHolder="Volume No" value={bk.volumeNo} onChange={handleChange} className='form-control' /> 	
			</div>

            <div className="form-group">
						<label >Row</label>
		<input type="text" name="bookRow"  placeHolder="Row" value={bk.bookRow} onChange={handleChange} className='form-control' /> 	
			</div>

            <div className="form-group">
						<label >Remarks</label>
		<input type="text" name="remarks"  placeHolder="Remarks" value={bk.remarks} onChange={handleChange} className='form-control' /> 	
			</div>   


                            </section>
                            <section className="col-md-4">
                            <div className="form-group">
						<label >Date</label>
		<input type="date" name="bookDate"  placeHolder="Date" value={bk.bookDate} onChange={handleChange} className='form-control' /> 	
			</div>

            <div className="form-group">
						<label >Name Of Saler / Doner</label>
		<input type="text" name="sellerDonor"  placeHolder="Name Of Saler / Doner" value={bk.sellerDonor} onChange={handleChange} className='form-control' /> 	
			</div>

            <div className="form-group">
						<label >Cash Memo / Bill No</label>
		<input type="text" name="billNo"  placeHolder="Cash Memo / Bill No" value={bk.billNo} onChange={handleChange} className='form-control' /> 	
			</div>
            
            <div className="form-group">
						<label >Call No</label>
		<input type="text" name="callNo"  placeHolder="Call No" value={bk.callNo} onChange={handleChange} className='form-control' /> 	
			</div>


            <div className="form-group">
						<label >Division</label>
                        <Select options={
                            division&&division.map((lis, dx)=> {
                               return {key:dx, value: lis.code, label: lis.divisionName}
                             })} 
onChange={handleSelectChange}  name="division" value={{value:bk.division, label:getDivision(bk.division)}}   />
			</div>


            <div className="form-group">
						<label >Category</label>
                        <Select options={
                            category&&category.map((lis, id)=> {
                               return {key:id, value: lis.code, label: lis.categoryName }
                             })} 
onChange={handleSelectChange}  name="category" value={{value:bk.category, label:getCategory(bk.category)}}  />	
			</div>   
                            </section> 
                                        
                                        
                     </div>
               
                </div>
                <div className="modal-footer">
                    <button type="button" id="btnCloseModal" className="btn btn-warning waves-effect " data-dismiss="modal">Close</button>
                    <button type="button" onClick={()=>submitUpdate(bk.code)} className="btn btn-inverse waves-effect waves-light ">Save changes</button>
                </div>
            </div>
        </div>
        </div>)}
        
{/* Modal end here */}

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Select Book</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		<div className="row">
									
										<section className="col-md-6">
                                    
                                        <div className="form-group">
						<label >Select Book <span style={{color:'red'}}>*</span></label>
                        <Select options={
                            books&&books.map((lst, idx)=> {
                               return {key:idx, value: lst.code, label: lst.bookName, aut:lst.authorName, publisher:lst.publisher }
                             })} 
                             getOptionLabel={option =>`${option.label} (${option.aut})`}
onChange={handleSelect} className={errors.book ? 'form-control form-control-danger' : ''} name="book" value={list.book}  /> <span style={{color:'red'}}>{errors.book && errors.book}</span>	
			</div>
</section>

<section className="col-md-6">

            <div className="form-group">
						<label >Accession Number</label>
                        <Select options={
                            bookAccess&&bookAccess.map((lis, idx)=> {
                               return {key:idx, value: lis.code, label:lis.accessionNumber, code:lis.code,  book:lis.bookCode}
                             })} 

                    getOptionLabel={option =>`${option.label} ${getBookName(option.book, 'bookName')}`}

onChange={handleSelectAccess} name="accessNo" value={list.accessNo}  /> 
			</div>
</section>
				</div>	

	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={fetchBookList} className="btn btn-primary "><i className="fa fa-search"></i> Proceed</button>
								</footer>				
							</div>
                            </div>
							</div></div>
</div>

</div>
	

<div className="row  ">   <div className="col-sm-12">
                               
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Listing All Books</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">          
        <div className="col-md-12 table-responsive">
         {notice.isDataFetching ? <TableLoader />:
        
<BookTable data={bookList}  columns={tableHeader}  /> 
        } 
</div>
</div>

</div>
    
    
    </div>
    </div>




   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(BookList) 