import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, ImagesUrl, Currency, FormatNumber, selectStyles} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import {Type} from 'react-bootstrap-table2-editor';
import Select from 'react-select'
import { userToken } from '../component/authentication'
import { useDispatch, useSelector } from 'react-redux'
import { formatGroupLabel, longDate } from '../component/globalFunction'

const HostelAllocation =()=> {
    
    const [isSubmitting, setIsSubmitting] = useState(false);
const [errors, setErrors] = useState({})
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})

let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
const [allocation, setAllocation] = useState({
    hostelCode:'',
    roomCode:'',
    studentCode:'',
    amountPaid:'',
    allocationDate:'0000-00-00',
    vacateDate:'0000-00-00'

})



const [CurrentSession, setCurrentSession] = useState('')
const students = useSelector(state => state.rootReducer.students);
const hostel = useSelector(state => state.rootReducer.hostel);
const schoolClass = useSelector(state =>state.rootReducer.schoolClass);
const schoolSession = useSelector(state =>state.rootReducer.schoolSession);

const [rooms, setRooms]= useState([]) 
const [isAllocateRoom, setIsAllocateRoom] = useState(false);
const [room, setRoom]= useState([]) 
const [bookings, setBookings]= useState([]) 


const [search, setSearch] = useState({
    gender:'',
    status:'',
    allocationDate:'',
    admissionNumber:'',
    hostelCode:'',
    schoolSession:''

})

const  handleSession =()=>{

    var sql ="Select  startYear from tbl_school_session where status ='Active'"
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append("jwt", Token);
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config)
    .then(result => {
        if(result.data.length!==0){
        setCurrentSession(result.data[0].startYear)
    }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', JSON.stringify(error.message)) 
    })
    }

    const fetchAllocation =()=>{
        setNotice({...notice,   isDataFetching: true})
        let sql = "Select a.ID, a.code, a.amountPaid, a.dateCreate, a.allocationDate, a.vacateDate, a.status, a.currentSession, r.roomName, h.hostelName, s.studentName, s.admissionNumber from  tbl_hostel_allocation a, tbl_hostel_rooms r, tbl_hostel h, tbl_students s where a.hostelCode =  h.code and a.roomCode = r.code and s.code = a.studentCode and a.isApproved ='true'  "
    
        if(search.gender.length!==0 ){
            sql = sql + '  and s.gender = "'+search.gender.value+'" '
        }
        if(search.status.length!==0 ){
            sql = sql + '  and a.status = "'+search.status.value+'" '
        }
    
        if(search.hostelCode.length!==0 ){
            sql = sql + '  and a.hostelCode = "'+search.hostelCode.value+'" '
        }
        
        if(search.schoolSession!==''){
            sql = sql + '  and a.currentSession = "'+search.schoolSession.value+'" '
        }

        if(search.admissionNumber!==''){
            sql = sql + '  and s.admissionNumber Like "%'+search.admissionNumber+'%" '
        }
        if(search.allocationDate!==''){
            sql = sql + '  and a.allocationDate = "'+search.allocationDate+'" '
        }

        sql = sql + 'order by h.hostelName, r.roomName  '


        
        const fd = new FormData();
        fd.append('jwt', Token);
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=>setBookings(result.data))
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice,   isDataFetching: false})
        })
        }


const fetchRooms =(code)=>{
    setNotice({...notice,   isLoading: true})
    let sql = "Select r.ID, r.code, r.roomName, r.roomRate, r.roomStatus, h.hostelName from tbl_hostel_rooms r, tbl_hostel h where r.hostelCode =  h.code  and h.code = '"+code+"' order by r.ID ASC"
    const fd = new FormData();
    fd.append('jwt', Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>{
        setRooms(result.data)
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice,   isLoading: false})
    })
    }

    
const fetchRoom =(code)=>{
    setNotice({...notice,   isLoading: true})
    
    let sql = "Select  r.ID, r.code, r.roomName, r.roomRate,  r.roomStatus, r.noBedSpace, h.hostelName, (select count(*) from tbl_hostel_allocation h where h.roomCode = '"+code+"' and h.currentSession = '"+CurrentSession+"') as totalStudent from tbl_hostel_rooms r, tbl_hostel h where r.hostelCode =  h.code  and r.code = '"+code+"'"

    const fd = new FormData();
    fd.append('jwt', Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>{
        if(Array.isArray(result.data)){
             setRoom(result.data[0])
        }else{
            setRoom([])
        } 
       
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice,   isLoading: false})
    })
    }


    const handleSelectSearch = (option, action)=>{
        setSearch({...search, [action.name]: option});
         } 
     const handleChangesearch = (event)=>{
        const {name, value} = event.target
 setSearch({...search, [name]: value}); 
 }


    const handleSelectHostel = (option, action)=>{
        fetchRooms(option.value)
        setAllocation({...allocation, [action.name]: option, roomCode: ''});
        setRoom([])
        setErrors({...errors, [action.name]:''})
         }

        const handleSelect = (option, action)=>{
            if(action.name==='roomCode'){
                fetchRoom(option.value)
            }
            setAllocation({...allocation, [action.name]: option});
            setErrors({...errors, [action.name]:''})
             }

             const handleChange = (event)=>{
                   const {name, value} = event.target
            setAllocation({...allocation, [name]: value});    
             setErrors({...errors, [name]:''});
            }



    const handleSubmit = event =>{
        event.preventDefault();
    setErrors(ValidateInput(allocation));
    setIsSubmitting(true);
    } 

    const ValidateInput=(values)=>{
        let errors = {};   
       
        if(values.hostelCode.length===0){
            errors.hostelCode ='Please select hostel';
        }
        
if(Number(room.totalStudent) >= Number(room.noBedSpace)){
            errors.roomCode ='The room selected is occupied';
        }

        if(values.roomCode.length===0){
            errors.roomCode ='Please select room';
        }


        if(values.studentCode.length===0){
            errors.studentCode ='Please select student';
        }

        if(Number(values.amountPaid) > Number(room.roomRate)){
            errors.amountPaid ='Amount enter cannot be more than room';
        }

        if(Number(values.amountPaid)<1){
            errors.amountPaid ='Please enter amount paid';
        }


        if(values.allocationDate ==="0000-00-00"){
            errors.allocationDate ='Please select allocation date';
        }

        return errors;
        }
    




const  submit=()=>{  
    setNotice({...notice,   isLoading: true})


       const fd = new FormData(); 
       fd.append("jwt", Token);
       fd.append("hostelName", allocation.hostelCode.label)
             fd.append("roomName", allocation.roomCode.label)
      fd.append("studentCode", allocation.studentCode.value)
      fd.append("hostelCode", allocation.hostelCode.value)
      fd.append("roomCode", allocation.roomCode.value)
      fd.append("amountPaid", allocation.amountPaid)
      fd.append("allocationDate", allocation.allocationDate)
      fd.append("vacateDate", allocation.vacateDate)
      fd.append("currentSession", CurrentSession)
      fd.append("studentName", allocation.studentCode.label)
      fd.append("email", allocation.studentCode.email)
      fd.append("status", 'Occupied')
      fd.append("isApproved", 'true')
      fd.append("code", Math.random().toString(36).substr(2,9))
      
             let url = ServerUrl+'/save_controller/tbl_hostel_allocation'
            axios.post(url, fd, config)
            .then(response =>{
            window.scrollTo(0,0)
            if (response.data.type ==='success'){
                setErrors({...errors, successMessage: response.data.message})
                
            } else{
                setErrors({...errors, errorMessage: JSON.stringify(response.data.message)})
            
                }  
                         
                 })
                 .catch((error)=>{
                    setErrors({...errors, errorMessage: error.message})
              })
              .finally(()=>{
                setIsSubmitting(false);
                setRoom([])
                setRooms([])
                fetchAllocation()
                setNotice({...notice,  isLoading: false}) 
                setAllocation({
                    hostelCode:'',
                roomCode:'',
                studentCode:'',
                amountPaid:'',
                allocationDate:'0000-00-00',
                vacateDate:'0000-00-00'
                })
              })
    } 



    const handleDelete = (code)=>{  
        setNotice({...notice,  isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+code)
              close.click();
           const fd = new FormData();
           fd.append('ID', 'code')
            fd.append('data', code)
            fd.append("jwt", Token);
            let url = ServerUrl+'/delete_controller/tbl_hostel_allocation';
           axios.post(url, fd, config)
           .then(response =>{
            if (response.data.type ==='success'){
                setErrors({...errors, successMessage: response.data.message})
                
            } else{
                setErrors({...errors, errorMessage: JSON.stringify(response.data)})
            
                }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
               setNotice({...notice,  isLoading: false })
           }) 
    }




    const TableRecord=(props)=>{

        const CustomToggleList = ({
            columns,
            onColumnToggle,
            toggles
          }) => ( 
          <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
              {
                columns
                  .map(column => ({
                    ...column,
                    toggle: toggles[column.dataField]
                  }))
                  .map(column => (
                    
                    <button
                      type="button"
                      key={ column.dataField }
                      className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                      data-toggle="button"
                      aria-pressed={ column.toggle ? 'true' : 'false' }
                      onClick={ () => onColumnToggle(column.dataField) }
                    >
                      { column.text }
                    </button>
                  ))
              }
            </div>
            
          );
    
        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData();
            fd.append('jwt', Token);
             fd.append('columnField', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
           
            let url = ServerUrl+'/updateById_controller/tbl_hostel_allocation'
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>console.log(error)) 
           
         } 
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                                
                                 <div className="btn-group dropdown-split-success">
      
      <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'50px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>


      <div className="dt-buttons btn-group pull-right"> 
    
          
  <a href="#!" onClick={()=>setIsAllocateRoom(!isAllocateRoom)} className="btn btn-outline-success waves-effect waves-light" >
        <i className="fa fa-plus"></i>Assign Room </a>
      
    
    
    <div className="btn-group dropdown-split-primary ">
        <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-download"></i>Export
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
    
    
                <form method='POST' action={ServerUrl+'/excel_controller.php?api=hostel_allocation'} target='_blank'>
                    <input type='hidden' name='jwt' value ={Token} />
            <button type="submit" className="btn dropdown-item waves-effect waves-light" > Export To Excel</button>
            
            </form>
    
            <form method='POST' action={ServerUrl+'/printout/hostel.php?token='+userToken} target='_blank'>
    
                    <input type='hidden' name='jwtToken' value ={Token} />
            <button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>
            
            </form>
        </div>
    </div> 
    </div> 
        </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      
    var i =1;
    const tableHeader = [
        {dataField: 'ID', text: 'SN', editable:false, formatter:(cell)=>i++},
                {dataField: 'currentSession', text: 'Session', editable:false},
                {dataField: 'hostelName', text: 'Hostel', editable:false, formatter:(cell, row)=><div style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }} >{row.hostelName + ' => ' +row.roomName}</div>}, 
                {dataField: 'amountPaid', text: 'Amount Paid', formatter:(cell)=>Currency+FormatNumber(cell)}, 
                {dataField: 'studentName', text: 'Student', editable:false, formatter:(cell, row)=><div style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }} >{row.admissionNumber + ' ' +row.studentName}</div>}, 
                
                {dataField: 'allocationDate', text: 'Allocation Date', editor: {
                    type: Type.DATE
                  }, formatter:(cell)=>longDate(cell)},
                {dataField: 'vacateDate', text: 'Vacation Date', editor: {
                    type: Type.DATE
                  }, formatter:(cell)=>longDate(cell)},
                {dataField: 'status', text: 'Status', formatter: (cell, row)=>row.status==='Occupied'? <span className="pcoded-badge label label-success">{cell}</span>: <span className="pcoded-badge label label-danger">{cell}</span>, editor: {
                    type: Type.SELECT, options:[
                        {value:'Occupied', label:'Occupied'},
                    {value:'Not Yet Occupied', label:'Not Yet Occupied'},
                    {value:'Vacated', label:'Vacated'}]
                  }},
            
            
        {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>
        
        <button type="button" className="btn btn-danger btn-sm delete" data-toggle="modal"   data-target={`#warningdialog-${cell}`}     title="Delete"><i className="icofont icofont-delete-alt"></i></button>
        &nbsp;&nbsp;
        
        <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(cell)} mID={cell} /> 
        </div>, editable: false}
         ]




 useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])

    
useEffect(()=>{
    setIsAuthenticated(verifyRouter(location.pathname))
    handleSession()
    fetchAllocation()
},[]);



        return (   <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Hostel Allocation">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-bed"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!" >Hostel</a> </li>
                            <li className="breadcrumb-item"><a href="#!" >Allocation</a> </li>

                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
              

                {errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}



{isAllocateRoom?
    <div  className="row  ">
							
                              <div className="col-sm-12">
					

                            <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Hostel Room Allocation</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
	
         	<div  className="row">

             <section className="col-md-6">
                                    
                                    <div className="form-group">
                                    <label >Select Hostel<span style={{color:'red'}}>*</span></label>
          <Select options={
                               hostel&&hostel.map((list, idx)=> {
                                   return {key:idx, value: list.code, label: list.hostelName, location:list.location  }
                                 })} 
    onChange={handleSelectHostel} className={errors.hostelCode? 'form-control form-control-danger' : ''} name="hostelCode" value={allocation.hostelCode}  /> <span style={{color:'red'}}>{errors.hostelCode && errors.hostelCode}</span>
    
              </div>
    
                </section>


                <section className="col-md-6">
                                    
                                    <div className="form-group">
                                    <label >Select Room<span style={{color:'red'}}>*</span></label>
          <Select options={
                               rooms&&rooms.map((list, idx)=> {
                                   return {key:idx, value: list.code, label: list.hostelName, roomName:list.roomName  }
                                 })} 
                            getOptionLabel={option =>`${option.label} - ${option.roomName}`}
    onChange={handleSelect} className={errors.roomCode? 'form-control form-control-danger' : ''} name="roomCode" value={allocation.roomCode}  /> <span style={{color:'red'}}>{errors.roomCode && errors.roomCode}</span>
    
              </div>
    
                </section>
                </div>
<div className="row">

    
                <div style={{display:room.length===0?'none':'block'}} className="col-md-12">
<div className="alert alert-success alert-block background-success" >
					<h4 className="alert-heading " style={{textAlign:'center'}}> <i className="fa-fw fa fa-info"></i> Room Details</h4>
                   
                    <div className="table-responsive">
            <table className="table">
    <tbody>

        <tr>
        <td>Hostel Name:</td>
        <th>{room&&room.hostelName}</th>
        <td>Room Name:</td>
        <th>{room&&room.roomName}</th>
        </tr>

        <tr>
        <td>Room Rate</td>
        <th>{room&&Currency+FormatNumber(room.roomRate)}</th>
        <td>Room Status</td>
        <th>{room&&room.roomStatus}</th>
        </tr>


        <tr>
        <td>No of Bed Space</td>
        <th>{room&&room.noBedSpace}</th>
        <td>Occupied Space</td>
        <th>{room&&room.totalStudent}</th>
        </tr>
        
    </tbody>
</table>
</div>



										</div>
</div>
</div>


<div  className="row">
                <section className="col-md-6">
                                    
                                    <div className="form-group">
                                    <label >Select Student<span style={{color:'red'}}>*</span></label>
                                    <Select  options={
                                        students&&students.map((list, idx)=> {
                                       return {key:idx, value: list.code, label:list.studentName, admissionNumber:list.admissionNumber, email:list.email }
                                     })
                                }    
                    getOptionLabel={option =>option.admissionNumber +' -'+ option.label}    
        onChange={handleSelect}  name="studentCode" value={allocation.studentCode} className={errors.studentCode? 'form-control form-control-danger' : ''} /> 
        
         <span style={{color:'red'}}>{errors.studentCode && errors.studentCode}</span>
    
              </div>
    
                </section>


            


            <section className="col-md-6">
										<div className="form-group">
						<label >Date of Allocation <span style={{color:'red'}}>*</span></label>
		<input type="date" name="allocationDate"  value={allocation.allocationDate} onChange={handleChange} className={errors.allocationDate ? 'form-control form-control-danger' : 'form-control'} /> 	
        <span style={{color:'red'}}>{errors.allocationDate && errors.allocationDate}</span>
			</div>
            </section>

            <section className="col-md-6">
										<div className="form-group">
						<label >Expected Vacation Date</label>
		<input type="date" name="vacateDate" value={allocation.vacateDate} onChange={handleChange} className='form-control' /> 
			</div>
            </section>

            <section className="col-md-6">
										<div className="form-group">
						<label >Amount Paid <span style={{color:'red'}}>*</span></label>
		<input type="number" name="amountPaid" min="1" value={allocation.amountPaid} pattern="\d*" onChange={handleChange} className={errors.amountPaid ? 'form-control form-control-danger' : 'form-control'}/> 	
        <span style={{color:'red'}}>{errors.amountPaid && errors.amountPaid}</span>
			</div>
            </section>	



                </div>					
                                        	
                <div className="row">
	<footer className="col-sm-12">
	
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-dark"><i className="fa fa-bed"></i> Allocate Room</button>
	&nbsp;&nbsp;
    <button type="button"  onClick={()=>setIsAllocateRoom(!isAllocateRoom)} className="btn btn-default"><i className="fa fa-close"></i> close</button>
		</footer>		
        </div>	


							</div>
							</div>


                            </div>
                            
                            </div>:
                           



                
                            <div className="row  no-print">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Search Hostel Allocation</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
        <div className="row">


        <section className="col-md-4">
                                            <div className="form-group">
						<label > Session</label> 
						<Select  options={schoolSession.map((dat, id)=> {
                                    return {key:id, value:dat.startYear, label:dat.startYear};
                                  })}
                        onChange={handleSelectSearch}  name="schoolSession" value={search.schoolSession}  styles={selectStyles} /> 
											</div>
                       </section>


        <section className="col-md-4">
                                            <div className="form-group">
						<label > Hostel Name</label> 
                        <Select options={
                               hostel&&hostel.map((list, idx)=> {
                                   return {key:idx, value: list.code, label: list.hostelName, location:list.location  }
                                 })} 
    onChange={handleSelectSearch}  name="hostelCode" value={search.hostelCode} styles={selectStyles} /> 
											</div>
                       </section>



                                    <section className="col-md-4">
                                            <div className="form-group">
						<label > Gender</label> 
						<Select  options={[
                    { value: 'Male', label: 'Male' },
                    { value: 'Female', label: 'Female' },
                ]} 
                        onChange={handleSelectSearch}  name="gender" value={search.gender}   /> 
											</div>
                       </section>

									

                   <section className="col-md-4">
                                            <div className="form-group">
						<label > Status</label> 
						<Select  options={[
                    { value: 'Occupied', label: 'Occupied' },
                    { value: 'Not Yet Occupied', label: 'Not Yet Occupied' },
                    { value: 'Vacated', label: 'Vacated' },
                ]} 

                        onChange={handleSelectSearch}  name="status" value={search.status}   /> 
											</div>
                       </section>

               
        <section className="col-md-4">
                            <div className="form-group">
        <label > Admission Number</label> 
        <input type="text" className="form-control" name="admissionNumber" value={search.admissionNumber}  onChange={handleChangesearch} />
                            </div>
        </section>

        <section className="col-md-4">
                            <div className="form-group">
        <label > Allocation Date</label> 
        <input type="date" className="form-control" name="allocationDate" value={search.allocationDate}  onChange={handleChangesearch} />
                            </div>
        </section>
                      
                         </div>


                <div className="row">
                <section className="col-md-6">
<button type="button"  className="btn btn-danger pull-right" onClick={fetchAllocation} ><i className="fa fa-search"></i> Search Student</button>
</section>	                </div>
							</div>
							</div>

							</div>
                </div>}

                            <div className="row">
  <div className="col-sm-12">
                               
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Listing All Rooms</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12 table-responsive">

         {notice.isDataFetching ? <TableLoader />:
         <TableRecord data={bookings}  columns={tableHeader}  /> 
        } 

            </div></div>
    
    </div>
    
    </div>
    </div>



   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(HostelAllocation) 