import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config} from '../component/include'
import {Type} from 'react-bootstrap-table2-editor';

import { useDispatch, useSelector } from 'react-redux'
import {RadioButton} from '../component/formControl'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'

 const ViewUsers =()=>{
    const [userdata, setUserdata] = useState({
        userType:''
    })
    const [studentData, setStudentData] = useState([])
    const [editStudent, setEditStudent] = useState(false)
    const [editStaff, setEditStaff] = useState(false)
const [usertype, setUsertype] = useState([])
    const [users, setUsers] = useState({
        table:'student',
        usertype:[],
        username:'',
        password:''

    });
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const allStaff  = useSelector(state => state.rootReducer.staff);
    const student  = useSelector(state => state.rootReducer.students);
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    const fetchUserType =()=>{
        const fd = new FormData();
    fd.append("jwt", Token);
        let url = ServerUrl+'/fetch_controller/tbl_usertype'
        axios.post(url, fd, config).then(result=>setUsertype(result.data))
    }



    const getUsergroup = (code)=>{
        const result = usertype&&usertype.filter(item=>item.code===code)
        const answer = result.length!==0?result[0].groupName:''
        return answer
    }


            const handleChange = (event)=>{
                const {name, value} = event.target
                setUsers({...users, [name] : value });
            }
        

            const handleChangeData = (event)=>{
                const {name, value} = event.target
                setUserdata({...userdata, [name] : value });
            }


         const handleChangePassword =(row)=>{
             setUserdata(row)
             setEditStaff(true)
             setEditStudent(false);
             window.scrollTo(0,0)
         }

         const handleChangeStudentPassword =(row)=>{
            setStudentData(row)
            setEditStudent(true)
            setEditStaff(false)
            window.scrollTo(0,0)
        }

    const tableStaffHeader = [
            {dataField: 'code', text: 'Action', editable:false, formatter: (cell, row)=><div>
                <button type='button' onClick={()=>handleChangePassword(row)} className='btn btn-sm btn-danger'><i className='fa fa-key'></i> Change Password</button>
            </div>},
            {dataField: 'userType',  text: 'Group',  sort: true, editable:false, formatter:(cell)=>getUsergroup(cell)},
            {dataField: 'staffName', text: 'Name', sort: true, editable:false, formatter: (cell, row)=><div>
            {cell}<br/>{row.employmentStatus==='Working'? <span className="pcoded-badge label label-success">Working</span>: <span className="pcoded-badge label label-danger">{row.employmentStatus}</span>}</div>},
            {dataField: 'username', text: 'Username', sort: true}, 
            {dataField: 'status',  text: 'Active', editor: {
                type: Type.SELECT, options:[{value:true, label:'Active'},
                {value:false, label:'In Active'}]
              }, formatter:(cell, row)=>row.status==='true'? <div style={{cursor:'pointer'}} className="text-center"><i title="Double click to change" className="fa fa-check badge-inverse-success"></i></div>: <div style={{cursor:'pointer'}}className="text-center"><i title="Double click to change" className="fa fa-times badge-inverse-danger"></i></div>}          
         ];


         const tableStudentHeader = [
            {dataField: 'code', text: 'Action', editable:false, formatter: (cell, row)=><div>
                <button type='button' onClick={()=>handleChangeStudentPassword(row)} className='btn btn-sm btn-danger'><i className='fa fa-key'></i> Change Password</button>
            </div>},
            {dataField: 'admissionNumber',  text: 'Admission Number', editable:false,  sort: true},
            {dataField: 'studentName', text: 'Name', sort: true, editable:false, formatter: (cell, row)=><div>
            {cell}<br/> {row.admissionStatus==='Studying'?<span className="pcoded-badge label label-success">{row.admissionStatus}</span>:row.admissionStatus==='Graduated'? <span className="pcoded-badge label label-info">{row.admissionStatus}</span>:<span className="pcoded-badge label label-danger">{row.admissionStatus}</span>}
        </div>},
            {dataField: 'status',  text: 'Active', editor: {
                type: Type.SELECT, options:[{value:'Active', label:'Active'},
                {value:'Inactive', label:'In Active'}]
              }, formatter:(cell, row)=>row.status==='Active'? <div style={{cursor:'pointer'}} className="text-center"><i title="Double click to change" className="fa fa-check badge-inverse-success"></i></div>: <div style={{cursor:'pointer'}}className="text-center"><i title="Double click to change" className="fa fa-times badge-inverse-danger"></i></div>}          
         ];

         
const BasicTable =(props)=>{
    
    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData();
    fd.append("jwt", Token); 
            fd.append('columnField', newValue);
            fd.append('column', column.dataField);
            fd.append('ID', ID);
          
           let url = ServerUrl+'/updateById_controller/'+props.table
          axios.post(url, fd, config)
         // .then(result => console.log(result.data))
          .then()
          .catch((error)=>console.log(error)) 
          
        } 

        const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
        );
    const options = {
        showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [{text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
    { text: 'All', value: props.data.length }]
        
        };
        const { SearchBar } = Search;

    return  <ToolkitProvider search  className="table-responsive"
                keyField='code' data={ props.data } columns={ props.columns } >
                    
                    {
                        props => (
                        <div >
                        <SearchBar
                        { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                            


                            <div className="dt-buttons btn-group pull-right"> 
                        
                    
                    <div className="form-radio">
                        <RadioButton checked={users.table ==="staff"} handleChange={handleChange} value="staff" label='Staff' name="table" />
                    <RadioButton checked={users.table ==="student"} label='Student' handleChange={handleChange} value="student" name="table" />
                            	
                        </div>
      
                                </div>


                            <BootstrapTable
                            { ...props.baseProps }
                                        striped
                                    hover
                                    condensed
                                    noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                pagination={paginationFactory(options) }
                                cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                            />
                        </div>
                        )
                    }
</ToolkitProvider>
}

const  updateStaff =()=>{  
    if(!users.password){
        Alerts('Error!', 'danger', 'Enter new password')
    }else{

    
        const fd = new FormData();
    fd.append("jwt", Token);
       
            fd.append('password', users.password);
            fd.append('userType', userdata.userType);
            fd.append('code', userdata.code);

        setNotice({...notice, 
          isLoading: true}) 
           let url = ServerUrl+'/update_controller/tbl_staff_password'
          axios.post(url, fd, config)
          .then(response =>{
            
            if (response.data.type ==='info'){
                Alerts('Saved!', 'info', response.data.message)
            } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
        }  })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        .finally(()=>{
          setNotice({...notice, isLoading: false}) 
          setEditStaff(false); 
          setUsers({...users, password:''})
    } )

}

}
const  updateStudent =()=>{  
    if(!users.password){
        Alerts('Error!', 'danger', 'Enter new password')
    }else{

    
        const fd = new FormData();
    fd.append("jwt", Token);
       
            fd.append('password', users.password);
            fd.append('code', studentData.code);

        setNotice({...notice, 
          isLoading: true}) 
           let url = ServerUrl+'/update_controller/tbl_student_password'
          axios.post(url, fd, config)
          .then(response =>{
            
            if (response.data.type ==='info'){
                Alerts('Saved!', 'info', response.data.message)
            } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
        }  })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        .finally(()=>{
          setNotice({...notice, isLoading: false}) 
          setEditStudent(false); 
          setUsers({...users, password:''})
    } )

}

}


useEffect(()=>{   
    setIsAuthenticated(verifyRouter(location.pathname))
    fetchUserType();
},[]);


        return (    <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Staff & Student">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-users"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="#!">Staff & Student</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
                <div className="row  ">
	<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-th"></i> Users List</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


        <div className="card z-depth-0">
            <div className="card-block">
        <div className="col-md-12 table-responsive">
		<table id="remindertable" className="table table-bordered table-striped table-hover" >
	<thead>

       {editStaff?    <tr >
<td colSpan="2"><div className="form-group"><label>User Group</label>
           <select onChange={handleChangeData}  name="userType"  className="form-control" value={userdata.userType} >

<option value="">Select</option>
{usertype&&usertype.map((list, idx)=> {
           return  <option key={idx} value={list.code} >{list.groupName} </option> })}
           
    </select></div>
                 </td>
                                   
                                            <td>
           <div className="form-group"><label>Username</label>
						<input type="text" className="form-control" name='username' autoComplete='off' value={userdata.username} onChange={handleChangeData} />
												
                                            </div></td>
                                            <td>
           <div className="form-group"><label>New Password</label>
						<input type="password" name='password' autoComplete='off' className="form-control" value={users.password} onChange={handleChange} />
												
                                            </div></td>
                                            <td>
           <div className="form-group"><br/>
						<button type="button" onClick={()=>updateStaff()}  className="btn btn-success" ><i className="fa fa-save"></i> Update </button>
												
                                            </div></td>
           </tr> :[]}  

         {editStudent?  <tr>
          
                                           
                                            <td colSpan='3'> 
                                 <div className="form-group"><label>Name</label>
                                 <input type="text" className="form-control" name='studentName' disabled={true} autoComplete='off' value={studentData.studentName} onChange={handleChangeData} />
												
                                            </div>
                                            
                                            </td>
                                            <td>
                                            <div className="form-group"><label>New Password</label>
						<input type="password" name='password' autoComplete='off' className="form-control" value={users.password} onChange={handleChange} />
												
                                            </div>
                                            </td>
                                            <td>
           <div className="form-group"><br/>
						<button type="button" onClick={()=>updateStudent()}  className="btn btn-success" ><i className="fa fa-save"></i> Update </button>
												
                                            </div></td>                     
           </tr> :[]}                 
	
	</thead>
	</table>
                                {
  users.table==='staff'? <BasicTable data={allStaff} table='tbl_staff' columns={tableStaffHeader}  />:
  <BasicTable data={student} table='tbl_students' columns={tableStudentHeader}  />
   
   } 

	</div></div>

</div></div></div>


</div>
</div>
                

                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(ViewUsers) 