import React, {useState, useEffect} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl, Token, config} from '../component/include'
import { longDate} from '../component/globalFunction'

import Error403 from '../settings/error403'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { userToken } from '../component/authentication'
import { useParams } from 'react-router'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
const AllocationHistory=()=> {
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    let params = useParams()
  
    const [result, setResult]=useState([])
    
const [profile, setProfile]=useState({
    asset:[]
});

let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
const staff = useSelector(state => state.rootReducer.staff);
const handleSelect = (option, action)=>{
	setProfile({...profile, [action.name]: option});
    fetchResult(option.value)
 }

    const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);


    const [assets, setAssets]= useState([]) 

    const getStaffName =(code)=>{
        const result = staff&&staff.filter(item=>item.code===code);
      const answer = result.length!==0?result[0].staffName:''
      return  answer
      }
      const getSAssetName =(code)=>{
        const result = assets&&assets.filter(item=>item.code===code);
      const answer = result.length!==0?result[0].assetName:''
      return  answer
      }

      
     
    const fetchAsset =()=>{
        setNotice({...notice,   isDataFetching: true})
        let sql = "select ID, code, assetName, category, assetType, usageHistory,  assignTo from tbl_assets order by ID DESC"; 

        const fd = new FormData();
        fd.append('jwt', Token);
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=>setAssets(result.data))
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice,   isDataFetching: false})
        })
        }


    const fetchResult =(code)=>{
        setNotice({...notice, isLoading: true}) 
        const fd = new FormData();
    fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_assets/usageHistory/code/'+code
        
        axios.post(url, fd, config).then(result =>{
            if(result.data.length!==0){
               setResult(result.data)
                    }else{
                        setResult([])
                       // Alerts('Info!', 'info', 'There are no record found')
                    }
                })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
        .finally(()=>{
            setNotice({...notice, isLoading: false})
        })
    }




useEffect(()=>{
    setIsAuthenticated(verifyRouter(location.pathname))
    fetchResult(params.code)
    fetchAsset()
    },[])

        return (  <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
   {isAuthenticated?   <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Allocation History">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-home"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="/assets/view_all">Assets</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Allocation</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  no-print">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i>Select Asset</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		<div className="row">
        
                    
      <section className="col-md-12">
      <div className="form-group">
         <label>Asset<span style={{color:'red'}}>*</span></label>
         <Select options={
                            assets&&assets.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.category + ' - '+list.assetName }
                             })} 
onChange={handleSelect}  name="asset" value={profile.asset}  /> 

            </div>
                    </section>			
    </div>	</div>
                            </div>
							</div></div>
</div>

</div>
	

{result.length!==0?
<div className="card z-depth-0">
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <div className="row" key={id}>

<table className="table  table-full-width">
       <tbody>
       <tr>
       <td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
 </td>
                </tr>
            </tbody>
       </table>

</div> 
                       )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Asset Allocation History</b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

        <table className="table table-striped table-bordered  table-full-width">
    
            <thead>
                <tr>
                <th>SN</th>
                <th>Staff</th>
                <th>Asset</th>
                <th>Date Assigned</th>
                <th>Date Returned</th>
                <th>Action</th>
                </tr>
            </thead>
            <tbody>{result.length!==0?result.map((row, idx)=> 
                <tr key={idx}>
                    <td>{idx+1}</td>
                    <td>{getStaffName(row.staffCode)}</td>
                    <td>{getSAssetName(row.asset)}</td>
                    <td>{longDate(row.dateAssign)}</td>
                    <td>{longDate(row.dateReturned)}</td>
                    <td>{row.dateReturned ===''? <button className="btn btn-sm btn-info"> Return Asset <i className="fa fa-external-link"></i></button>:''}</td>

                </tr>):[]}
            </tbody> 
        </table>
 </div>	 </div> 

 <div className="row">
        <div className="col-md-12">
        <form method='POST' action={ServerUrl+'/printout/asset_history.php?token='+userToken} target='_blank'>
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                
                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>
        </div>:''}
   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(AllocationHistory) 